import * as type from 'modules/types';
import { AxiosError } from 'axios';
import { IReviewList } from 'scheme/reviewList';
import { LanguageType } from 'scheme/theme/themeInfo';

// 이벤트 리뷰 데이터 fetch 시작
export const getEventReviewListStart = (
  eventId: string | undefined,
  languageType: LanguageType,
  start: number,
  count: number,
) => ({
  type: type.GET_EVENT_REVIEW_LIST_START,
  eventId,
  languageType,
  start,
  count,
});

// 이벤트 리뷰 데이터 fetch 성공 시 fetched Data를 payload에 할당
export const getEventReviewListSuccess = (eventReviewList: IReviewList[]) => ({
  type: type.GET_EVENT_REVIEW_LIST_SUCCESS,
  payload: eventReviewList,
});

// 데이터 fetch 실패 시 error 객체를 payload에 할당
export const getEventReviewListError = (error: AxiosError) => ({
  type: type.GET_EVENT_REVIEW_LIST_FAIL,
  payload: error,
});

// 이벤트 리뷰 개수 데이터 fetch 성공 시 fetched Data를 payload에 할당
export const getEventReviewListCountSuccess = (eventReviewListCount: number) => ({
  type: type.GET_EVENT_REVIEW_LIST_COUNT_SUCCESS,
  payload: eventReviewListCount,
});

// 데이터 fetch 실패 시 error 객체를 payload에 할당
export const getEventReviewListCountError = (error: AxiosError) => ({
  type: type.GET_EVENT_REVIEW_LIST_COUNT_FAIL,
  payload: error,
});

// 이벤트 리뷰 데이터 추가 fetch 시작 (infinite scroll)
export const getEventReviewListMoreStart = (
  eventId: string | undefined,
  languageType: LanguageType,
  start: number,
  count: number,
) => ({
  type: type.GET_EVENT_REVIEW_LIST_MORE_START,
  eventId,
  languageType,
  start,
  count,
});

export const getEventReviewListMoreSuccess = (moreEventReviewList: IReviewList[]) => ({
  type: type.GET_EVENT_REVIEW_LIST_MORE_SUCCESS,
  payload: moreEventReviewList,
});

export const getEventReviewListMoreError = (error: AxiosError) => ({
  type: type.GET_EVENT_REVIEW_LIST_MORE_FAIL,
  payload: error,
});

export type GetEventReviewListActionsType =
  | ReturnType<typeof getEventReviewListStart>
  | ReturnType<typeof getEventReviewListSuccess>
  | ReturnType<typeof getEventReviewListError>
  | ReturnType<typeof getEventReviewListCountSuccess>
  | ReturnType<typeof getEventReviewListCountError>
  | ReturnType<typeof getEventReviewListMoreStart>
  | ReturnType<typeof getEventReviewListMoreSuccess>
  | ReturnType<typeof getEventReviewListMoreError>;
