import { combineReducers } from 'redux';
import themeInfoReducer from 'modules/reducers/theme/themeInfo';
import languageTypeReducer from 'modules/reducers/languageType';
import reviewListReducer from 'modules/reducers/theme/reviewList';
import keywordListReducer from 'modules/reducers/theme/keywordList';
import eventInfoReducer from 'modules/reducers/event/eventInfo';
import eventReviewListReducer from 'modules/reducers/event/eventReviewList';
import bannerInfoReducer from './main/bannerInfo';
import todayThemeInfoReducer from './main/todayThemeInfo';
import popularThemeInfoReducer from './main/popularThemeInfo';
import themeListCategoryInfoReducer from './main/themeListCategoryInfo';
import themeListInfoReducer from './main/themeListInfo';
import searchedThemeListInfoReducer from './search/searchedThemeListInfo';
import popularSearchKeywordListInfoReducer from './search/popularSearchKeywordListInfo';

// reducer가 여러 개일 경우를 대비하여 combineReducers를 통해 reducer 통합
const rootReducer = combineReducers({
  languageTypeReducer,
  themeInfoReducer,
  reviewListReducer,
  eventReviewListReducer,
  keywordListReducer,
  eventInfoReducer,
  bannerInfoReducer,
  todayThemeInfoReducer,
  popularThemeInfoReducer,
  themeListCategoryInfoReducer,
  themeListInfoReducer,
  searchedThemeListInfoReducer,
  popularSearchKeywordListInfoReducer,
});

export default rootReducer;

// rootReducer의 반환값을 유추하여 export
export type RootState = ReturnType<typeof rootReducer>;
