import { call, put, takeEvery } from 'redux-saga/effects';
import axios, { AxiosResponse, AxiosError } from 'axios';
import {
  GET_THEME_LIST_CATEGORY_INFO_START,
  GET_THEME_LIST_CATEGORY_INFO_SUCCESS,
  GET_THEME_LIST_CATEGORY_INFO_FAIL,
} from 'modules/types';
import { LanguageType } from 'scheme/theme/themeInfo';

interface IParams {
  languageType: LanguageType;
  type: string;
}

function fetchThemeListCategoryInfo(languageType: LanguageType) {
  const themeListCategoryInfo = axios.get(`/theme/category`, {
    headers: { 'content-language': languageType },
  });
  return themeListCategoryInfo;
}

function* getThemeListCategoryInfo({ languageType }: IParams) {
  try {
    const themeListCategoryInfo: AxiosResponse = yield call(fetchThemeListCategoryInfo, languageType);

    yield put({
      type: GET_THEME_LIST_CATEGORY_INFO_SUCCESS,
      themeListCategoryInfo: themeListCategoryInfo.data.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    yield put({
      type: GET_THEME_LIST_CATEGORY_INFO_FAIL,
      payload: error,
    });
  }
}

export function* themeListCategoryInfoSaga() {
  yield takeEvery(GET_THEME_LIST_CATEGORY_INFO_START, getThemeListCategoryInfo);
}

export default themeListCategoryInfoSaga;
