import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
import { useState } from 'react';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

interface IErrorModal {
  error: AxiosError;
}
export default function ErrorModal({ error }: IErrorModal) {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleErrorModalClick = () => {
    setIsErrorModalOpen(false);
    navigate('/');
  };

  return (
    <>
      {isErrorModalOpen && (
        <ErrorDimmer onClick={handleErrorModalClick}>
          <ErrorModalContainer>
            {(error?.response?.status || 400) >= 500 && (error?.response?.status || 400) < 600 ? (
              <>
                <ErrorTitle>{t('serverErrorTitle')}</ErrorTitle>
                <ErrorBody>{t('serverErrorBody')}</ErrorBody>
              </>
            ) : ((error?.response?.status || 400) == 406) ? (
              <>
                <ErrorTitle>{t('languageNotAvailableTitle')}</ErrorTitle>
                <ErrorBody>{t('languageNotAvailableTitleBody1')}</ErrorBody>
              </>
            ) : (
              <>
                <ErrorTitle>{t('somethingWrongTitle')}</ErrorTitle>
                <ErrorBody>{t('somethingWrongBody1')}</ErrorBody>
                <ErrorBody>{t('somethingWrongBody2')}</ErrorBody>
              </>
            )}
            <ErrorButton onClick={handleErrorModalClick}>{t('confirm')}</ErrorButton>
          </ErrorModalContainer>
        </ErrorDimmer>
      )}
    </>
  );
}

const ErrorDimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: rgba(60, 60, 60, 40%);
`;

const ErrorModalContainer = styled.div`
  display: inline-block;
  padding: 2.8rem 1.6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width: ${theme.layout.mainWidth};
  background-color: #fff;
  border-radius: 20px;
  touch-action: none;
  transition: 0.15s ease-out;
  max-width: 400px;
`;

const ErrorTitle = styled.div`
  color: ${theme.color.allMainColor};
  font-weight: 700;
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 2rem;
`;

const ErrorBody = styled.div`
  font-size: 1.4rem;
  text-align: center;
`;

const ErrorButton = styled.div`
  width: 50%;
  background: ${theme.color.allMainColor};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 2.5rem;
  color: ${theme.color.basicWhite};
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 24px;
  cursor: pointer;
  :hover {
    color: ${theme.color.basicWhite};
  }
  min-width: 90px;
  max-width: 200px;
  margin: 3rem auto 0;
`;
