const color = {
  basicBlack: '#000',
  basicWhite: '#FFF',
  backgroundWhite: '#FFFFFF',
  allTitleGray: '#42444C',
  allBodyGray: '#4B4E57',
  allSubDarkGray: '#919299',
  allMainColor: '#FF417D',
  allMainPaleColor: '#ffa1bf',
  allBtnGray: '#EBEDF5',
  allSubLightGray: '#AAABB3',
  gemTxtColor: '#7DC9FC',
  candyTxtColor: '#FFDB22',
  eventTxtColor: '#EB8099',
  allDividerGray: '#EAEAEA',
};

const layout = {
  mainWidth: '650px',
  fixedBottomBarHeight: '72px',
  fixedTopBarHeight: '64px',
};

const breakpoint = {
  desktop: 1440,
  tablet: 768,
  mobile: 425,
};

const device = {
  desktop: `(max-width: ${breakpoint.desktop}px)`,
  tablet: `(max-width: ${breakpoint.tablet}px)`,
  mobile: `(max-width: ${breakpoint.mobile}px)`,
};

export const theme = {
  device,
  color,
  layout,
};
