import { LanguageType } from 'scheme/theme/themeInfo';
import * as type from 'modules/types';
import { AxiosError } from 'axios';
import { IEventInfo } from 'scheme/event/eventInfo';

// 이벤트 정보 데이터 fetch 시작
export const getEventInfoStart = (eventId: string | undefined, languageType: LanguageType | undefined) => ({
  type: type.GET_EVENT_INFO_START,
  eventId,
  languageType,
});

// 이벤트 정보 데이터 fetch 성공 시 fetched Data를 eventInfo에 할당
export const getEventInfoSuccess = (eventInfo: IEventInfo, webStoreShareLink: string, appStoreShareLink: string) => ({
  type: type.GET_EVENT_INFO_SUCCESS,
  eventInfo: eventInfo,
  webStoreShareLink,
  appStoreShareLink,
});

// 데이터 fetch 실패 시 error 객체를 payload에 할당
export const getEventInfoError = (error: AxiosError) => ({
  type: type.GET_EVENT_INFO_FAIL,
  payload: error,
});

export type GetEventInfoActionsType =
  | ReturnType<typeof getEventInfoStart>
  | ReturnType<typeof getEventInfoSuccess>
  | ReturnType<typeof getEventInfoError>;
