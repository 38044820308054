import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { useState } from 'react';
import { event } from 'react-ga';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { IEventImages } from 'scheme/event/eventInfo';
import styled from 'styled-components/macro';
import { theme } from 'styles/theme';

interface IImageSliderProps {
  images: IEventImages[];
  sliderSettings: ISliderSettings;
  clickable?: boolean;
  type?: string;
  handleModalOpen?: () => void;
  isModalOpen?: boolean;
}
interface IImage {
  clickable?: boolean;
}

interface ISliderSettings {
  customPaging: () => JSX.Element;
  dots: boolean;
  dotsClass: string;
  infinite: boolean;
  speed: number;
  slidesToShow: number;
  slidesToScroll: number;
  arrows: boolean;
  draggable?: boolean;
}

export default function ImageSlider({
  type,
  images,
  sliderSettings,
  clickable,
  handleModalOpen,
  isModalOpen,
}: IImageSliderProps) {
  const navigate = useNavigate();
  const [swipeStatus, setSwipeStatus] = useState<string>('');

  const handleMouseDown = () => {
    // mouseDown 이벤트 발생 시 swipeStatus를 'mouseDown'으로 설정
    setSwipeStatus('mouseDown');
  };

  const handleMouseMove = () => {
    // mouseMove 이벤트 발생 시 swipeStatus가 'mouseDown'이 아닐 경우 return
    if (swipeStatus !== 'mouseDown') return;
    // mouseDown 이벤트 발생 시 swipeStatus를 'mouseMove'으로 설정
    setSwipeStatus('mouseMove');
  };

  const handleMouseLeave = (e: React.SyntheticEvent, urlEvent: string | undefined, id: string | undefined) => {
    // imageSlider가 테마 정보 페이지에 있을 경우
    // 체험용 키보드 modal이 열려있을 경우 return
    if (isModalOpen) return;

    // swipeStatus가 'mouseMove'일 경우
    // 스와이프 중인 상태이므로 클릭한 효과가 나면 안되므로 e.preventDefault(); 후 return;
    if (swipeStatus === 'mouseMove') {
      e.preventDefault();
      return;
    }

    // swipeStatus가 'mouseDown'일 경우
    // 스와이프가 발생하지 않은 상태에서 mouseLeave 이벤트가 발생했으므로
    if (swipeStatus === 'mouseDown') {
      // 만약 이미지 슬라이더가 themeInfoPage에 위치한 경우
      if (type === 'themeInfoPage') {
        event({
          category: 'click_preview',
          action: 'Click preview',
          label: id,
        });

        // 약간의 시간 지연을 둔 후 체험용 키보드 모달 open
        // 체험용 키보드 모달이 열리면서 mouseLeave 이벤트가 체험용 키보드 모달에도 중복 적용되어
        // modal이 open되자마자 modal의 dimmed 영역에도 클릭된 효과가 나서 바로 키보드 모달이 꺼지는 현상이 발생
        // 이를 방지하기 위함
        setTimeout(() => {
          handleModalOpen && handleModalOpen();
        }, 100);
      } else if (e.type === 'mouseup') {
        // 만약 이미지 슬라이더가 themeInfoPage에 위치하지 않은 모든 경우
        // moveToUrl() 함수 실행
        moveToUrl(urlEvent, id);
      }
    }
  };

  const moveToUrl = (url: string | undefined, id: string | undefined) => {
    if (!url) return;
    if (type === 'storeBanner') {
      event({
        category: 'user_store_banner_click',
        action: 'Click user_store_banner',
        label: id,
      });
    }

    const [action, ...contents] = url.split('/');
    const content = contents.join('/');

    if (['web', 'inapp'].includes(action)) {
      window.open(content);
      return;
    }

    if (action === 'event') {
      window.open(`https://webview.plkey.app/event/${content}`);
      return;
    }

    if (action === 'theme') {
      navigate(`/theme/${content}`);
      return;
    }

    window.open('https://share.plkey.app/start');
  };

  return (
    <ImageSliderContainer>
      <StyledSlider {...sliderSettings}>
        {images.map((image, index) => {
          return (
            <Image
              clickable={clickable}
              src={image.imageUrl}
              key={index}
              onMouseLeave={(e) => handleMouseLeave(e, image.event, image.id)}
              onMouseUp={(e) => handleMouseLeave(e, image.event, image.id)}
              onTouchEnd={(e) => handleMouseLeave(e, image.event, image.id)}
              onMouseMove={handleMouseMove}
              onTouchMove={handleMouseMove}
              onMouseDown={handleMouseDown}
              onTouchStart={handleMouseDown}
            />
          );
        })}
      </StyledSlider>
    </ImageSliderContainer>
  );
}
const ImageSliderContainer = styled.div`
  overflow: hidden;
`;

const StyledSlider = styled(Slider)`
  height: 100%;
  margin-bottom: 30px;
  .slick-slide div {
    outline: none;
    margin: 5px;
  }
  .slick-dots .slick-active li {
    background-color: ${theme.color.allMainColor};
  }

  .slick-dots li {
    width: 0.6rem;
    height: 0.6rem;
  }

  .slick-track div {
    display: flex;
    padding: 0 8px;
  }
`;

const Image = styled.img<IImage>`
  ${({ clickable }) =>
    clickable &&
    `
  cursor:pointer;
  `}

  border-radius: 15px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
`;
