import { css } from 'styled-components';
import { theme } from 'styles/theme';

const h1 = () =>
  css`
    font-size: 2rem;
    font-weight: 700;
    line-height: 28px;
    color: ${theme.color.allTitleGray};
  `;
const h2 = () =>
  css`
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 20px;
    color: ${theme.color.allSubDarkGray};
  `;
const h2_bold = () =>
  css`
    font-size: 24px;
    font-weight: 900;
    line-height: 35px;
    letter-spacing: -0.02em;
  `;
const h3 = () =>
  css`
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 24px;
    color: ${theme.color.allTitleGray};
  `;
const h4 = () =>
  css`
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
  `;
const h4_bold = () =>
  css`
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: -0.02em;
  `;
const s1 = () =>
  css`
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.02em;
  `;
const s1_bold = () =>
  css`
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: -0.02em;
  `;

const b1 = () =>
  css`
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.02em;
  `;
const b2 = () =>
  css`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
  `;
const b2_regular = () =>
  css`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.02em;
  `;

const b3 = () =>
  css`
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.02em;
  `;
const b4_bold = () =>
  css`
    font-size: 11px;
    font-weight: 700;
    line-height: 15.9px;
    letter-spacing: -0.02em;
  `;
const b5 = () =>
  css`
    font-size: 10px;
    font-weight: 400;
    line-height: 14.5px;
    letter-spacing: -0.02em;
  `;

const Mixin = { h1, h2, h2_bold, h3, h4, h4_bold, s1, s1_bold, b1, b2, b2_regular, b3, b4_bold, b5 };
export default Mixin;
