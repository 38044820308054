import { call, put, takeEvery } from 'redux-saga/effects';
import axios, { AxiosResponse, AxiosError } from 'axios';
import {
  GET_THEME_LIST_INFO_START,
  GET_THEME_LIST_INFO_SUCCESS,
  GET_THEME_LIST_INFO_FAIL,
  GET_THEME_LIST_INFO_MORE_START,
  GET_THEME_LIST_INFO_MORE_SUCCESS,
  GET_THEME_LIST_INFO_MORE_FAIL,
} from 'modules/types';
import { LanguageType } from 'scheme/theme/themeInfo';

interface IParams {
  languageType: LanguageType;
  category: string;
  start: number;
  count: number;
  type: string;
}

function fetchThemeListInfo(languageType: LanguageType, category: string, start: number, count: number) {
  const themeListInfo = axios.get(`/theme?category=${category}&start=${start}&count=${count}`, {
    headers: { 'content-language': languageType },
  });
  return themeListInfo;
}

function* getThemeListInfo({ languageType, category, start, count }: IParams) {
  try {
    const themeListInfo: AxiosResponse = yield call(fetchThemeListInfo, languageType, category, start, count);

    yield put({
      type: GET_THEME_LIST_INFO_SUCCESS,
      themeListInfo: themeListInfo.data.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    yield put({
      type: GET_THEME_LIST_INFO_FAIL,
      payload: error,
    });
  }
}

function* getThemeListInfoMore({ languageType, category, start, count }: IParams) {
  try {
    const themeListInfo: AxiosResponse = yield call(fetchThemeListInfo, languageType, category, start, count);

    yield put({
      type: GET_THEME_LIST_INFO_MORE_SUCCESS,
      payload: themeListInfo.data.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    yield put({
      type: GET_THEME_LIST_INFO_MORE_FAIL,
      payload: error,
    });
  }
}

export function* themeListInfoSaga() {
  yield takeEvery(GET_THEME_LIST_INFO_START, getThemeListInfo);
  yield takeEvery(GET_THEME_LIST_INFO_MORE_START, getThemeListInfoMore);
}

export default themeListInfoSaga;
