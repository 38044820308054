import * as type from 'modules/types';
import { AxiosError } from 'axios';
import { LanguageType } from 'scheme/theme/themeInfo';
import { IPopularSearchKeywordListInfo } from 'scheme/search/popularSearchKeywordListInfo';

export const getPopularSearchKeywordListInfoStart = (languageType: LanguageType | undefined) => ({
  type: type.GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_START,
  languageType,
});

export const getPopularSearchKeywordListInfoSuccess = (
  popularSearchKeywordListInfo: IPopularSearchKeywordListInfo[],
) => ({
  type: type.GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_SUCCESS,
  popularSearchKeywordListInfo,
});

export const getPopularSearchKeywordListInfoError = (error: AxiosError) => ({
  type: type.GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_FAIL,
  payload: error,
});

export type GetPopularSearchKeywordListInfoActionsType =
  | ReturnType<typeof getPopularSearchKeywordListInfoStart>
  | ReturnType<typeof getPopularSearchKeywordListInfoSuccess>
  | ReturnType<typeof getPopularSearchKeywordListInfoError>;
