import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import rootSaga from 'modules/sagas';
import createSagaMiddleware from 'redux-saga';

export default function configureStore() {
  // middleware 할당
  const sagaMiddleware = createSagaMiddleware();

  // middleware가 적용된 store
  const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(rootSaga);

  return store;
}
