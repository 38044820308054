import {
  GET_REVIEW_LIST_START,
  GET_REVIEW_LIST_SUCCESS,
  GET_REVIEW_LIST_FAIL,
  GET_REVIEW_LIST_COUNT_SUCCESS,
  GET_REVIEW_LIST_COUNT_FAIL,
  GET_REVIEW_LIST_MORE_START,
  GET_REVIEW_LIST_MORE_SUCCESS,
  GET_REVIEW_LIST_MORE_FAIL,
} from 'modules/types';
import { AxiosError } from 'axios';
import { IReviewList, IReviewUserInfo } from 'scheme/reviewList';
import { GetReviewListActionsType } from 'modules/actions/theme/reviewList';

export type ReviewListState = {
  loading: boolean;
  reviewList: IReviewList[];
  reviewListCount: number;
  error: AxiosError | null;
};

const initialState: ReviewListState = {
  loading: false,
  error: null,
  reviewListCount: 0,
  reviewList: [
    {
      _id: '',
      isBuy: false,
      isTop: false,
      isCreator: false,
      hide: false,
      user: {} as IReviewUserInfo,
      content: '',
      createdAt: '',
      updatedAt: '',
    },
  ],
};

export default function reviewListReducer(
  state: ReviewListState = initialState,
  action: GetReviewListActionsType,
): ReviewListState {
  switch (action.type) {
    case GET_REVIEW_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case GET_REVIEW_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewList: action.payload,
      };
    case GET_REVIEW_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_REVIEW_LIST_COUNT_SUCCESS:
      return {
        ...state,
        reviewListCount: action.payload,
      };
    case GET_REVIEW_LIST_COUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_REVIEW_LIST_MORE_START:
      return {
        ...state,
        loading: true,
      };
    // 해당 액션 dispatch 시 reviewList에 fetch해온 리뷰 목록을 이어 붙임
    case GET_REVIEW_LIST_MORE_SUCCESS:
      return {
        ...state,
        reviewList: state.reviewList.concat(action.payload),
      };
    case GET_REVIEW_LIST_MORE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
