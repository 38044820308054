import {
  GET_POPULAR_THEME_INFO_START,
  GET_POPULAR_THEME_INFO_SUCCESS,
  GET_POPULAR_THEME_INFO_FAIL,
} from 'modules/types';
import { AxiosError } from 'axios';
import { ISuggestThemeInfo } from 'scheme/main/suggestThemeInfo';
import { GetPopularThemeInfoActionsType } from 'modules/actions/main/popularThemeInfo';

export type PopularThemeInfoState = {
  loading: boolean;
  popularThemeInfo: ISuggestThemeInfo[];
  error: AxiosError | null;
};

const initialState: PopularThemeInfoState = {
  loading: false,
  error: null,
  popularThemeInfo: [
    {
      category: [''],
      createdAt: '',
      downloads: 0,
      hashtag: [''],
      imageUrl: '',
      isBuy: false,
      isLiveTheme: false,
      isNewTheme: false,
      maximumIOSVersion: 0,
      maximumVersion: 0,
      minimulIOSVersion: 0,
      minimulVersion: 0,
      name: '',
      paymentType: '',
      price: 0,
      priority: 0,
      recommendPriority: 0,
      requireVersion: 0,
      sale: 0,
      testcase: [''],
      themeId: '',
      updatedAt: '',
      versionBranches: [''],
      _id: '',
    },
  ],
};

export default function popularThemeInfoReducer(
  state: PopularThemeInfoState = initialState,
  action: GetPopularThemeInfoActionsType,
): PopularThemeInfoState {
  switch (action.type) {
    case GET_POPULAR_THEME_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case GET_POPULAR_THEME_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        popularThemeInfo: action.popularThemeInfo,
      };
    case GET_POPULAR_THEME_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
