import ReactDOM from 'react-dom';
import axios from 'axios';
import GlobalStyle from 'styles/GlobalStyle';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from 'modules/configureStore';
import './i18n';
import { initialize, pageview } from 'react-ga';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

initialize('G-M0PJYJF8LN');

pageview(window.location.pathname + window.location.search);

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <App />
  </Provider>,
  document.getElementById('root'),
);
