import * as type from 'modules/types';
import { AxiosError } from 'axios';
import { IReviewList } from 'scheme/reviewList';
import { LanguageType } from 'scheme/theme/themeInfo';

export const getReviewListStart = (
  themeId: string | undefined,
  languageType: LanguageType,
  start: number,
  count: number,
) => ({
  type: type.GET_REVIEW_LIST_START,
  themeId,
  languageType,
  start,
  count,
});

export const getReviewListSuccess = (reviewList: IReviewList[]) => ({
  type: type.GET_REVIEW_LIST_SUCCESS,
  payload: reviewList,
});

export const getReviewListError = (error: AxiosError) => ({
  type: type.GET_REVIEW_LIST_FAIL,
  payload: error,
});

export const getReviewListCountSuccess = (reviewListCount: number) => ({
  type: type.GET_REVIEW_LIST_COUNT_SUCCESS,
  payload: reviewListCount,
});

export const getReviewListCountError = (error: AxiosError) => ({
  type: type.GET_REVIEW_LIST_COUNT_FAIL,
  payload: error,
});

export const getReviewListMoreStart = (
  themeId: string | undefined,
  languageType: LanguageType,
  start: number,
  count: number,
) => ({
  type: type.GET_REVIEW_LIST_MORE_START,
  themeId,
  languageType,
  start,
  count,
});

export const getReviewListMoreSuccess = (moreReviewList: IReviewList[]) => ({
  type: type.GET_REVIEW_LIST_MORE_SUCCESS,
  payload: moreReviewList,
});

export const getReviewListMoreError = (error: AxiosError) => ({
  type: type.GET_REVIEW_LIST_MORE_FAIL,
  payload: error,
});

export type GetReviewListActionsType =
  | ReturnType<typeof getReviewListStart>
  | ReturnType<typeof getReviewListSuccess>
  | ReturnType<typeof getReviewListError>
  | ReturnType<typeof getReviewListCountSuccess>
  | ReturnType<typeof getReviewListCountError>
  | ReturnType<typeof getReviewListMoreStart>
  | ReturnType<typeof getReviewListMoreSuccess>
  | ReturnType<typeof getReviewListMoreError>;
