import * as type from 'modules/types';
import { AxiosError } from 'axios';
import { LanguageType } from 'scheme/theme/themeInfo';
import { ISuggestThemeInfo } from 'scheme/main/suggestThemeInfo';

export const getPopularThemeInfoStart = (languageType: LanguageType | undefined) => ({
  type: type.GET_POPULAR_THEME_INFO_START,
  languageType,
});

export const getPopularThemeInfoSuccess = (popularThemeInfo: ISuggestThemeInfo[]) => ({
  type: type.GET_POPULAR_THEME_INFO_SUCCESS,
  popularThemeInfo,
});

export const getPopularThemeInfoError = (error: AxiosError) => ({
  type: type.GET_POPULAR_THEME_INFO_FAIL,
  payload: error,
});

export type GetPopularThemeInfoActionsType =
  | ReturnType<typeof getPopularThemeInfoStart>
  | ReturnType<typeof getPopularThemeInfoSuccess>
  | ReturnType<typeof getPopularThemeInfoError>;
