import { call, put, takeEvery } from 'redux-saga/effects';
import axios, { AxiosResponse, AxiosError } from 'axios';
import {
  GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_START,
  GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_SUCCESS,
  GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_FAIL,
} from 'modules/types';
import { LanguageType } from 'scheme/theme/themeInfo';

interface IParams {
  languageType: LanguageType;
  type: string;
}

function fetchPopularSearchKeywordListInfo(languageType: LanguageType) {
  const popularSearchKeywordListInfo = axios.get(`/theme/search-keyword`, {
    headers: { 'content-language': languageType },
  });
  return popularSearchKeywordListInfo;
}

function* getPopularSearchKeywordListInfo({ languageType }: IParams) {
  try {
    const popularSearchKeywordListInfo: AxiosResponse = yield call(fetchPopularSearchKeywordListInfo, languageType);

    yield put({
      type: GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_SUCCESS,
      popularSearchKeywordListInfo: popularSearchKeywordListInfo.data.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    yield put({
      type: GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_FAIL,
      payload: error,
    });
  }
}

export function* popularSearchKeywordListInfoSaga() {
  yield takeEvery(GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_START, getPopularSearchKeywordListInfo);
}

export default popularSearchKeywordListInfoSaga;
