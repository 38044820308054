import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ISuggestThemeInfo } from 'scheme/main/suggestThemeInfo';
import { IThemeInfo } from 'scheme/theme/themeInfo';
import { theme } from 'styles/theme';

interface ITrendingThemeRankProps {
  isPopular: boolean | undefined;
  popularThemeInfo: ISuggestThemeInfo[];
  themeInfo: IThemeInfo;
}

export default function TrendingThemeRank({ isPopular, popularThemeInfo, themeInfo }: ITrendingThemeRankProps) {
  const { t } = useTranslation();

  return (
    <TrendingThemeRankContainer>
      {isPopular && (
        <div>
          {t('trendingTheme1')}
          {popularThemeInfo.map((theme: ISuggestThemeInfo) => {
            if (theme._id === themeInfo._id) return popularThemeInfo.indexOf(theme) + 1;
          })}
          {t('trendingTheme2')}
        </div>
      )}
    </TrendingThemeRankContainer>
  );
}

const TrendingThemeRankContainer = styled.div`
  margin: 2rem 0 0.2rem;
  color: ${theme.color.allMainColor};
  padding: 0 1px;
  font-weight: 500;
`;
