import { call, put, takeEvery } from 'redux-saga/effects';
import axios, { AxiosResponse, AxiosError } from 'axios';
import {
  GET_REVIEW_LIST_START,
  GET_REVIEW_LIST_SUCCESS,
  GET_REVIEW_LIST_FAIL,
  GET_REVIEW_LIST_COUNT_SUCCESS,
  GET_REVIEW_LIST_COUNT_FAIL,
  GET_REVIEW_LIST_MORE_START,
  GET_REVIEW_LIST_MORE_SUCCESS,
  GET_REVIEW_LIST_MORE_FAIL,
} from 'modules/types';
import { LanguageType } from 'scheme/theme/themeInfo';

// themeId, languageType, start, count를 인자로 받아 review list fetch 후 return
function fetchReviewList(themeId: string | undefined, languageType: LanguageType, start: number, count: number) {
  const reviewList = axios.get(`/v2/theme/review?themeId=${themeId}&start=${start}&count=${count}`, {
    headers: { 'content-language': languageType },
  });

  return reviewList;
}

// themeId, languageType을 인자로 받아 reviewList의 review 개수 fetch 후 return
function fetchReviewListCount(themeId: string | undefined, languageType: LanguageType) {
  const reviewListCount = axios.get(`/v2/theme/review?themeId=${themeId}&getCount=true`, {
    headers: { 'content-language': languageType },
  });

  return reviewListCount;
}

// action을 인자로 받아 비동기 data fetch 처리하는 generator function
// 액션이 지닌 값을 가져와서 사용하기 위해 액션을 인자로 받아옴
function* getReviewList(action: {
  type: string;
  themeId: string | undefined;
  languageType: LanguageType;
  start: number;
  count: number;
}) {
  try {
    // call을 통해 인자로 전달한 함수 호출 후 결과 반환될 때까지 대기
    const reviewList: AxiosResponse = yield call(
      fetchReviewList,
      action.themeId,
      action.languageType,
      action.start,
      action.count,
    );

    const reviewListCount: AxiosResponse = yield call(fetchReviewListCount, action.themeId, action.languageType);

    // 성공 액션 디스패치
    yield put({
      type: GET_REVIEW_LIST_SUCCESS,
      payload: reviewList.data.data,
    });

    yield put({
      type: GET_REVIEW_LIST_COUNT_SUCCESS,
      payload: reviewListCount.data.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    // 실패 액션 디스패치
    yield put({
      type: GET_REVIEW_LIST_FAIL,
      payload: error,
    });
    yield put({
      type: GET_REVIEW_LIST_COUNT_FAIL,
      payload: error,
    });
  }
}

function* getReviewListMore(action: {
  type: string;
  themeId: string | undefined;
  languageType: LanguageType;
  start: number;
  count: number;
}) {
  try {
    const reviewList: AxiosResponse = yield call(
      fetchReviewList,
      action.themeId,
      action.languageType,
      action.start,
      action.count,
    );

    yield put({
      type: GET_REVIEW_LIST_MORE_SUCCESS,
      payload: reviewList.data.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    yield put({
      type: GET_REVIEW_LIST_MORE_FAIL,
      payload: error,
    });
  }
}

// 이 함수 내부에 모든 saga들을 합칠 수 있음
export function* reviewListSaga() {
  yield takeEvery(GET_REVIEW_LIST_START, getReviewList);
  yield takeEvery(GET_REVIEW_LIST_MORE_START, getReviewListMore);
}

export default reviewListSaga;
