import styled from 'styled-components/macro';
import { useEffect, useRef } from 'react';
import { theme } from 'styles/theme';
import { IReviewList } from 'scheme/reviewList';
import timeForNow from 'utils/timeForNow';
import mixin from 'styles/mixin';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import { useTranslation } from 'react-i18next';

interface IReviewListProps {
  paymentType: string;
  reviewList: IReviewList[];
  reviewListCount: number;
  setReviewStart: React.Dispatch<React.SetStateAction<number>>;
}

interface IReviewContainer {
  isCreator: boolean;
}

export default function ReviewList({ paymentType, reviewList, reviewListCount, setReviewStart }: IReviewListProps) {
  // 교차 지점을 확인할 요소를 가리킴
  const intersectRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  // custom hook으로부터 intersectRef의 교차 여부를 판단하는 isVisible state를 받아옴
  // IntersectionObserver() 생성자에 전달될 옵션도 함께 hook의 인자로 삽입
  const { isVisible } = useIntersectionObserver(intersectRef, {
    root: null,
    rootMargin: '50px',
    threshold: 0.01,
  });

  useEffect(() => {
    // 지정된 요소가 root(null이면 기본값인 브라우저 뷰포트)와 교차하며
    // reviewList 내 review 개수가 0개 이상이면 reviewStart에 20 더하기
    if (isVisible && reviewListCount > 0) {
      setReviewStart((prev) => {
        return prev + 20;
      });
    }
  }, [isVisible]);

  const reviewListTitle = () => {
    switch (paymentType) {
      case 'brand':
        return t('participantReviewTitle');
      case 'event':
        return t('eventReviewTitle');
      default:
        return t('buyerReviewTitle');
    }
  };

  // 프로필 이미지 로딩에 에러 발생 시 지정된 기본 이미지를 대신 보여주기 위함
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.onerror = null;
    event.currentTarget.src = require('assets/images/img_dummy_profile.png');
  };

  return (
    <ReviewListContainer>
      <ReviewListTitle>
        {reviewListTitle()}
        <ReviewListCount>{reviewListCount.toLocaleString()}</ReviewListCount>
      </ReviewListTitle>
      {reviewList.map((review) => (
        <ReviewListWrapper key={review._id}>
          <ReviewContainer isCreator={review.isCreator}>
            <ReviewProfileImageContainer>
              <ReviewProfileImage src={review.user.profileImage} alt="" onError={handleImageError} />
              {review.isCreator && <CreatorBadge>{t('creator')}</CreatorBadge>}
            </ReviewProfileImageContainer>
            <ReviewContentContainer>
              {!review.isCreator &&
                (review.hide ? (
                  <ReviewerName>{t('anonymous')}</ReviewerName>
                ) : (
                  <ReviewerName>{review.user.userName}</ReviewerName>
                ))}
              <ReviewContent>{review.content}</ReviewContent>
              <ReviewCreatedAt>{timeForNow(review.createdAt)}</ReviewCreatedAt>
            </ReviewContentContainer>
          </ReviewContainer>
          {!review.isCreator && <ReviewDivider></ReviewDivider>}
        </ReviewListWrapper>
      ))}
      <div ref={intersectRef}></div>
    </ReviewListContainer>
  );
}

const ReviewListContainer = styled.div`
  margin-top: 3rem;
`;

const ReviewListTitle = styled.div`
  ${mixin.h3}
  padding: 0 16px 16px;
  display: flex;
`;

const ReviewListCount = styled.div`
  ${mixin.h3}
  color: ${theme.color.allMainColor};
  margin-left: 8px;
`;

const ReviewListWrapper = styled.div``;

const ReviewContainer = styled.div<IReviewContainer>`
  ${({ isCreator }) =>
    isCreator &&
    `
  background-color: rgba(255, 65, 125, .1);
  `}
  display: flex;
  white-space: pre-wrap;
  padding: 16px;
  line-height: 24px;
`;

const ReviewProfileImageContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-right: 17px;
`;

const ReviewProfileImage = styled.img`
  border-radius: 50%;
  width: 48px;
  height: 48px;
`;

const CreatorBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.96rem;
  border-radius: 24px;
  position: absolute;
  top: 40px;
  margin-left: 2px;
  width: 5.5rem;
  background-color: ${theme.color.allMainColor};
  color: ${theme.color.basicWhite};
`;

const ReviewContentContainer = styled.div``;

const ReviewerName = styled.div`
  color: ${theme.color.allTitleGray};
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 4px;
`;

const ReviewContent = styled.div`
  word-break: break-all;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
`;

const ReviewCreatedAt = styled.div`
  color: ${theme.color.allSubDarkGray};
  font-size: 1.2rem;
`;

const ReviewDivider = styled.div`
  border-bottom: 1px solid ${theme.color.allDividerGray};
  margin: 0 16px;
`;
