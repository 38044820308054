import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';
import { theme } from 'styles/theme';

const GlobalStyle = createGlobalStyle`
  ${reset}

  html {
    font-size: 77%;
    @media screen and ${theme.device.desktop} {
      font-size: 73%;
    }
    @media screen and ${theme.device.tablet} {
      font-size: 68%;
    }
    @media screen and ${theme.device.mobile} {
      font-size: 62.5%;
    }

  }

  @keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
  @keyframes MainToPale {
    from {background: ${theme.color.allMainPaleColor};}
    to {background: ${theme.color.allMainColor};}
  }

  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 100;
    src: url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2') format('woff2'),
    url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff') format('woff'),
    url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf') format('opentype');
  }

  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 300;
    src: url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2') format('woff2'),
    url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff') format('woff'),
    url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf') format('opentype');
  }

  @font-face {font-family: 'Noto Sans KR';
    font-style: normal;font-weight: 400;
    src: url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2') format('woff2'),
    url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff') format('woff'),
    url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf') format('opentype');
  } 

  @font-face {font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2') format('woff2'),
    url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff') format('woff'),
    url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf') format('opentype');
  } 

  @font-face {font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2') format('woff2'),
    url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff') format('woff'),
    url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf') format('opentype');
  } 

  @font-face {font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 900;
    src: url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2') format('woff2'),
    url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff') format('woff'),
    url('//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf') format('opentype');
  }

 /* 사파리 대응
  사파리는 user-select: none 적용 시 input에 입력 자체가 불가능하므로
  input을 제외한 전부에 다음 css 속성 적용
 */
*:not(input) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline-style:none;
}

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;  
    -webkit-user-drag:none; 
    -moz-user-drag:none; 
    -ms-user-drag:none; 
    -khtml-user-drag: none;
    -webkit-touch-callout: none;
    -webkit-touch-callout: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  body {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 14px;
    line-height: 1.43;
    background-color: #fff;
  }
  
  a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  input, button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  ol, ul, li {
    /* list-style: none; */
  }

  img {
    width: 100%;
  }
`;

export default GlobalStyle;
