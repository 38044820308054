import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
import { useDragScroll } from 'hooks/useDragScroll';
import { event } from 'react-ga';

interface IThemeListCategoryTabProps {
  themeListCategoryInfo: string[];
  selectedCategory: string;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
}
interface ITab {
  active: boolean;
}

export default function ThemeListCategoryTab({
  themeListCategoryInfo,
  selectedCategory,
  setSelectedCategory,
}: IThemeListCategoryTabProps) {
  const { handleMouseDown, handleMouseLeave, handleMouseMove, wasDragged, isDragging, scrolledRef } = useDragScroll();

  const handleTabClick = (category: string) => {
    if (wasDragged) return;
    event({
      category: 'user_store_category_click',
      action: 'Click user_store_category',
      label: category,
    });

    setSelectedCategory(category);
  };

  return (
    <ThemeListCategoryTabContainer>
      <ThemeListCategoryTabWrapper
        ref={scrolledRef}
        onMouseDown={handleMouseDown}
        onMouseMove={isDragging ? handleMouseMove : undefined}
        onMouseUp={handleMouseLeave}
        onMouseLeave={handleMouseLeave}
      >
        {themeListCategoryInfo.map((category: string, index: number) => (
          <Tab key={index} active={selectedCategory === category} onClick={() => handleTabClick(category)}>
            {category}
          </Tab>
        ))}
      </ThemeListCategoryTabWrapper>
    </ThemeListCategoryTabContainer>
  );
}
const ThemeListCategoryTabContainer = styled.div`
  z-index: 200;
  position: -webkit-sticky;
  position: sticky;
  top: 62.5px;
  /* padding: 62.5px 0 0; */
`;

const ThemeListCategoryTabWrapper = styled.div`
  background-color: ${theme.color.basicWhite};
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  justify-content: space-between;
`;

const Tab = styled.button<ITab>`
  ${({ active }) =>
    active &&
    `
    font-weight: 700;
    color: ${theme.color.allMainColor} !important;
    border-bottom: 2px solid ${theme.color.allMainColor};
  `}
  margin: 10px 16px 0;
  white-space: nowrap;
  font-size: 1.4rem;
  padding-bottom: 0.7rem;
  cursor: pointer;
  line-height: 2.4rem;
  color: ${theme.color.allSubLightGray};
`;
