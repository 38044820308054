// banner url을 parse하는 함수
export const parseBannerUrl = (bannerUrl: string) => {
  const urlType = bannerUrl.split('/')[0];
  switch (urlType) {
    case 'web':
      return bannerUrl.replace('web/', '');
    default:
      return;
  }
};
