import styled from 'styled-components/macro';
import { event } from 'react-ga';

interface IPreviewImageProps {
  imageUrl: string;
  handleModalOpen: () => void;
  themeId: string;
}

export default function PreviewImage({ imageUrl, handleModalOpen, themeId }: IPreviewImageProps) {
  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handlePreviewImageClick = () => {
    event({
      category: 'click_preview',
      action: 'Click preview',
      label: themeId,
    });
    handleModalOpen();
  };

  return <Preview src={imageUrl} alt="" onContextMenu={handleContextMenu} onClick={handlePreviewImageClick} />;
}

const Preview = styled.img`
  border-radius: 15px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
`;
