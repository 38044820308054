import styled from 'styled-components/macro';
import ImageSlider from 'components/EventInfoPage/ImageSlider';
import FixedTopBar from 'components/ThemeInfoPage/FixedTopBar';
import Layout from 'layout/Layout';
import { RootState } from 'modules/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { getBannerInfoStart } from 'modules/actions/main/bannerInfo';
import { IEventImages } from 'scheme/event/eventInfo';
import { theme } from 'styles/theme';
import { useNavigate } from 'react-router-dom';
import { getTodayThemeInfoStart } from 'modules/actions/main/todayThemeInfo';
import TodayTheme from 'components/MainPage/TodayTheme';
import { getPopularThemeInfoStart } from 'modules/actions/main/popularThemeInfo';
import TrendingTheme from 'components/MainPage/TrendingTheme';
import MainPageHelmet from 'components/CustomPageHelmet';
import { getThemeListCategoryInfoStart } from 'modules/actions/main/themeListCategoryInfo';
import { getThemeListInfoMoreStart, getThemeListInfoStart } from 'modules/actions/main/themeListInfo';
import ThemeList from 'components/MainPage/ThemeList';
import i18n from 'i18n';
import { parseLanguageType } from 'utils/parseLanguageType';
import CustomPageHelmet from 'components/CustomPageHelmet';
import Loading from 'components/Loading';
import ErrorModal from 'components/ErrorModal';
import { CustomDot } from './EventInfoPage';

export default function MainPage() {
  const dispatch = useDispatch();
  const languageType = useSelector((state: RootState) => state.languageTypeReducer.languageType);
  const bannerInfoData = useSelector((state: RootState) => state.bannerInfoReducer.bannerInfo);
  const todayThemeInfoData = useSelector((state: RootState) => state.todayThemeInfoReducer.todayThemeInfo);
  const todayThemeInfoLoading = useSelector((state: RootState) => state.todayThemeInfoReducer.loading);

  const popularThemeInfoData = useSelector((state: RootState) => state.popularThemeInfoReducer.popularThemeInfo);
  const popularThemeInfoLoading = useSelector((state: RootState) => state.popularThemeInfoReducer.loading);

  const themeListCategoryInfoData = useSelector(
    (state: RootState) => state.themeListCategoryInfoReducer.themeListCategoryInfo,
  );

  const themeListInfoData = useSelector((state: RootState) => state.themeListInfoReducer.themeListInfo);
  const themeListError = useSelector((state: RootState) => state.themeListInfoReducer.error);
  const themeListInfoLoading = useSelector((state: RootState) => state.themeListInfoReducer.loading);
  const [bannerImage, setBannerImage] = useState<IEventImages[]>([{ imageUrl: '' }]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [themeListInfoStart, setThemeListInfoStart] = useState<number>(0);
  const [themeListInfoFetchMore, setThemeListInfoFetchMore] = useState<boolean>(true);
  const [previousThemeListInfoLength, setPreviousThemeListInfoLength] = useState<number>(0);
  const themeListScrollTop = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // themeListCategoryInfoData를 fetch해온 뒤 바로 NEW를 기본값으로 설정하기 위함
    setSelectedCategory(themeListCategoryInfoData[0]);
  }, [themeListCategoryInfoData]);

  const sliderSettings = {
    customPaging: function () {
      return <CustomDot></CustomDot>;
    },
    dots: true,
    dotsClass: 'slick-dots custom-dots',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: true,
    // 자동 스와이프 옵션
    autoplay: true,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    // data fetch
    dispatch(getBannerInfoStart(languageType));
    dispatch(getTodayThemeInfoStart(languageType));
    dispatch(getPopularThemeInfoStart(languageType));
    dispatch(getThemeListCategoryInfoStart(languageType));
  }, [navigate, languageType]);

  useEffect(() => {
    if (!selectedCategory) return;
    // 선택한 카테고리에 따라 보여줄 themeList가 다르므로
    // selectedCategory 값이 변경될 때마다 getThemeListInfoStart dispatch
    dispatch(getThemeListInfoStart(languageType, selectedCategory, 0, 20));
  }, [selectedCategory]);

  useEffect(() => {
    if (themeListInfoStart <= 0 || !selectedCategory || !themeListInfoFetchMore) return;
    // themeList의 가장 하단까지 스크롤을 하여 themeListInfoStart의 값에 20이 더해지면
    // 변경된 themeListInfoStart를 start 값으로 하여 20개의 테마를 더 불러와서 themeListInfoData에 덧붙이는(concat) getThemeListInfoMoreStart dispatch
    dispatch(getThemeListInfoMoreStart(languageType, selectedCategory, themeListInfoStart, 20));
  }, [themeListInfoStart]);

  useEffect(() => {
    if (themeListInfoData.length <= 0) return;
    if (previousThemeListInfoLength === themeListInfoData.length) {
      // fetch 이전 테마 리스트 length가 fetch 이후 테마 리스트 length와 같으면
      // themeListInfoFetchMore를 false로 변경하여 더 이상 fetch하지 않도록 설정
      setThemeListInfoFetchMore(false);
      return;
    }
    setPreviousThemeListInfoLength(themeListInfoData.length);
  }, [themeListInfoData]);

  useEffect(() => {
    if (themeListInfoStart <= 0 || !selectedCategory || !themeListScrollTop.current) return;
    // selectedCategory가 달라지면 themeList 및 themeList와 연관된 값을 모두 초기화
    window.scrollTo(0, themeListScrollTop.current.offsetTop - 70);
    setPreviousThemeListInfoLength(0);
    setThemeListInfoStart(0);
    setThemeListInfoFetchMore(true);
  }, [selectedCategory]);

  useEffect(() => {
    // bannerInfoData에서 imageUrl, event, id만 추출하여 bannerImage에 할당 후 ImageSlider 컴포넌트에 props로 전달하기 위함
    if (bannerImage.length > 1 || bannerInfoData.filter((bannerInfo) => bannerInfo.lang !== languageType).length)
      return;
    bannerInfoData.map((data) => {
      setBannerImage((prev) =>
        prev
          .filter((data) => data.imageUrl !== '')
          .concat({ imageUrl: data.imageUrl, event: data.event, id: data._id }),
      );
    });
  }, [bannerInfoData]);

  return (
    <Layout>
      {todayThemeInfoLoading || popularThemeInfoLoading ? (
        <Loading color={theme.color.allMainColor} height="10%" width="10%" />
      ) : (
        <>
          <CustomPageHelmet
            languageType={languageType}
            title="플레이키보드 - 수백가지 취향저격 키보드 테마"
            englishTitle="PlayKeyboard"
          />
          <MainPageContainer>
            <FixedTopBar languageType={languageType} type="main" />
            {themeListError ? (
              <ErrorModal error={themeListError} />
            ) : (
              <>
                <ImageSlider images={bannerImage} sliderSettings={sliderSettings} clickable={true} type="storeBanner" />
                <TodayTheme todayThemeInfo={todayThemeInfoData} />
                <TrendingTheme popularThemeInfo={popularThemeInfoData} />
                <ThemeList
                  themeListInfo={themeListInfoData}
                  themeListCategoryInfo={themeListCategoryInfoData}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  setThemeListInfoStart={setThemeListInfoStart}
                  themeListInfoCount={themeListInfoData.length}
                  themeListInfoFetchMore={themeListInfoFetchMore}
                  themeListScrollTop={themeListScrollTop}
                />
              </>
            )}
          </MainPageContainer>
        </>
      )}
    </Layout>
  );
}

const MainPageContainer = styled.div`
  padding: ${theme.layout.fixedTopBarHeight} 0 0;
  height: auto;
`;
