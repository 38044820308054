import * as type from 'modules/types';
import { AxiosError } from 'axios';
import { LanguageType } from 'scheme/theme/themeInfo';
import { IThemeListInfo } from 'scheme/main/themeListInfo';

export const getSearchedThemeListInfoStart = (
  languageType: LanguageType | undefined,
  searchKeyword: string,
  start: number,
  count: number,
) => ({
  type: type.GET_SEARCHED_THEME_LIST_INFO_START,
  languageType,
  searchKeyword,
  start,
  count,
});

export const getSearchedThemeListInfoSuccess = (searchedThemeListInfo: IThemeListInfo[]) => ({
  type: type.GET_SEARCHED_THEME_LIST_INFO_SUCCESS,
  searchedThemeListInfo,
});

export const getSearchedThemeListInfoError = (error: AxiosError) => ({
  type: type.GET_SEARCHED_THEME_LIST_INFO_FAIL,
  payload: error,
});

export const getBlankSearchedThemeListInfoSuccess = (blank: string[]) => ({
  type: type.GET_BLANK_SEARCHED_THEME_LIST_INFO_SUCCESS,
  payload: blank,
});

export const getSearchedThemeListInfoMoreStart = (
  languageType: LanguageType,
  searchKeyword: string,
  start: number,
  count: number,
) => ({
  type: type.GET_SEARCHED_THEME_LIST_INFO_MORE_START,
  searchKeyword,
  languageType,
  start,
  count,
});

export const getSearchedThemeListInfoMoreSuccess = (moreSearchedThemeListInfo: IThemeListInfo[]) => ({
  type: type.GET_SEARCHED_THEME_LIST_INFO_MORE_SUCCESS,
  payload: moreSearchedThemeListInfo,
});

export const getSearchedThemeListInfoMoreError = (error: AxiosError) => ({
  type: type.GET_SEARCHED_THEME_LIST_INFO_MORE_FAIL,
  payload: error,
});

export type GetSearchedThemeListInfoActionsType =
  | ReturnType<typeof getSearchedThemeListInfoStart>
  | ReturnType<typeof getSearchedThemeListInfoSuccess>
  | ReturnType<typeof getSearchedThemeListInfoError>
  | ReturnType<typeof getBlankSearchedThemeListInfoSuccess>
  | ReturnType<typeof getSearchedThemeListInfoMoreStart>
  | ReturnType<typeof getSearchedThemeListInfoMoreSuccess>
  | ReturnType<typeof getSearchedThemeListInfoMoreError>;
