import styled from 'styled-components/macro';
import { theme } from 'styles/theme';

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout(props: LayoutProps) {
  return (
    <Container>
      <Main>{props.children}</Main>
    </Container>
  );
}

const Container = styled.div`
  min-height: 100vh;
  /* overflow: auto; */
`;

const Main = styled.main`
  max-width: ${theme.layout.mainWidth};
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  background-color: ${theme.color.backgroundWhite};
`;
