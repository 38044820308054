import { IFigure, IImages, IRelativeThemes, IThemeInfo } from 'scheme/theme/themeInfo';
import { GetThemeInfoActionsType } from 'modules/actions/theme/themeInfo';
import { GET_THEME_INFO_START, GET_THEME_INFO_SUCCESS, GET_THEME_INFO_FAIL } from 'modules/types';
import { AxiosError } from 'axios';

export type ThemeInfoState = {
  loading: boolean;
  themeInfo: IThemeInfo;
  error: AxiosError | null;
  webStoreShareLink: string;
  appStoreShareLink: string;
};

const initialState: ThemeInfoState = {
  loading: false,
  error: null,
  webStoreShareLink: '',
  appStoreShareLink: '',
  themeInfo: {
    bannerEvent: '',
    bannerImageUrl: '',
    brandAction: '',
    brandDescription: '',
    brandImageUrl: '',
    brandType: '',
    category: [] as string[],
    createdAt: '',
    creator: '',
    designStoryContent: '',
    designStoryTitle: '',
    downloadKey: '',
    downloads: 0,
    figure: [] as IFigure[],
    hashtag: [] as string[],
    hide: false,
    imageUrl: '',
    images: [] as IImages[],
    isBuy: false,
    isLiveTheme: false,
    isOnlyStore: false,
    lang: '',
    localDownloadBuffer: 0,
    localDownloads: 0,
    maximumIOSVersion: 0,
    maximumVersion: 0,
    minimulIOSVersion: 0,
    minimulVersion: 0,
    name: '',
    officialRecommendPriorities: null,
    officialRecommendPriority: 0,
    paymentType: '',
    platform: [] as string[],
    price: 0,
    priority: 0,
    randomPriority: 0,
    recommend: false,
    recommendPriority: 0,
    relatedKeyword: [] as string[],
    relativeThemes: [] as IRelativeThemes[],
    requireVersion: 0,
    reviewNoticeContent: '',
    reviewNoticeTitle: '',
    sale: 0,
    testImageUrls: [] as string[],
    testcase: [] as string[],
    themeId: '',
    updatedAt: '',
    usedPaymentBuffer: 0,
    //   versionBranches: [];
    __v: 0,
    _id: '',
  },
};

export default function themeInfoReducer(
  state: ThemeInfoState = initialState,
  action: GetThemeInfoActionsType,
): ThemeInfoState {
  switch (action.type) {
    case GET_THEME_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case GET_THEME_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        themeInfo: action.themeInfo,
        webStoreShareLink: action.webStoreShareLink,
        appStoreShareLink: action.appStoreShareLink,
      };
    case GET_THEME_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
