import {
  GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_START,
  GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_SUCCESS,
  GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_FAIL,
} from 'modules/types';
import { AxiosError } from 'axios';
import { IPopularSearchKeywordListInfo } from 'scheme/search/popularSearchKeywordListInfo';
import { GetPopularSearchKeywordListInfoActionsType } from 'modules/actions/search/popularSearchKeywordListInfo';

export type PopularSearchKeywordListInfoState = {
  loading: boolean;
  popularSearchKeywordListInfo: IPopularSearchKeywordListInfo[];
  error: AxiosError | null;
  payload: string[];
};

const initialState: PopularSearchKeywordListInfoState = {
  loading: false,
  error: null,
  payload: [],
  popularSearchKeywordListInfo: [
    {
      count: 0,
      keyword: '',
    },
  ],
};

export default function popularSearchKeywordListInfoReducer(
  state: PopularSearchKeywordListInfoState = initialState,
  action: GetPopularSearchKeywordListInfoActionsType,
): PopularSearchKeywordListInfoState {
  switch (action.type) {
    case GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        popularSearchKeywordListInfo: action.popularSearchKeywordListInfo,
      };
    case GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
