import styled from 'styled-components/macro';
import mixin from 'styles/mixin';
import ShareIconImage from 'assets/icons/ic_all_share.png';
import Snackbar from 'components/ThemeInfoPage/Snackbar';
import { useSnackbar } from 'hooks/useSnackBar';
import { useClipboardCopy } from 'hooks/useClipboardCopy';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

interface IThemeNameProps {
  themeName: string;
  shareLink: string;
}

export default function ThemeName({ themeName, shareLink }: IThemeNameProps) {
  const { isShowedUp, showSnackbar, closeSnackbar } = useSnackbar();
  const { handleClipboardCopy, isCopied, setIsCopied } = useClipboardCopy();
  const { t } = useTranslation();

  const handleShareClick = async () => {
    if (window.navigator.share) {
      return window.navigator.share({
        title: `${themeName} - PlayKeyboard`,
        text: '',
        url: shareLink,
      });
    } else {
      handleClipboardCopy(shareLink);
    }
  };

  useEffect(() => {
    if (!isCopied) return;
    showSnackbar();
    setIsCopied(false);
  }, [isCopied]);

  return (
    <ThemeNameContainer>
      <ThemeNameWrapper>{themeName}</ThemeNameWrapper>
      <ShareIcon src={ShareIconImage} alt="" onClick={handleShareClick} />
      {isShowedUp && <Snackbar content={t('linkCopied')} closeSnackbar={closeSnackbar} />}
    </ThemeNameContainer>
  );
}

const ThemeNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1px;
`;

const ThemeNameWrapper = styled.h1`
  ${mixin.h1}
`;

const ShareIcon = styled.img`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;
