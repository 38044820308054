import { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// 횡스크롤 제어를 위한 hook
export const useDragScroll = () => {
  const scrolledRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [wasDragged, setWasDragged] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);

  // 페이지 이동 시 스크롤 원래대로 초기화
  useEffect(() => {
    if (scrolledRef.current) scrolledRef.current.scrollLeft = 0;
  }, [pathname]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setWasDragged(false);

    // 이미지 드래그 시 이미지만 분리되어 이동되는 현상 방지
    e.preventDefault();
    setIsDragging(true);
    if (scrolledRef.current) {
      // 클릭 이벤트가 발생한 곳의 페이지 기준 x좌표 + figureScrollRed div의 스크롤 정도
      setStartX(e.pageX + scrolledRef.current.scrollLeft);
    }
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    setWasDragged(true);
    if (isDragging && scrolledRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = scrolledRef.current;

      // 스크롤한 정도를 startX - e.pageX로 설정
      scrolledRef.current.scrollLeft = startX - e.pageX;

      // 만약 스크롤을 아예 안한 상태가 된다면
      if (scrollLeft === 0) {
        // startX를 페이지 기준 현재 이벤트 발생 위치의 x좌표로 설정
        setStartX(e.pageX);

        // 만약 스크롤을 끝까지 한 상태가 된다면
      } else if (scrollWidth <= clientWidth + scrollLeft) {
        // startX를 페이지 기준 현재 이벤트 발생 위치의 x좌표와 스크롤한 정도의 합으로 설정
        setStartX(e.pageX + scrollLeft);
      }
    }
  };

  return { handleMouseDown, handleMouseLeave, handleMouseMove, scrolledRef, isDragging, wasDragged };
};
