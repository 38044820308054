import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { theme } from 'styles/theme';

interface ICtaButtonProps {
  handleCtaButtonClick: (e: React.SyntheticEvent) => void;
  paymentType: string;
  shareLink: string;
}

interface ICtaButtonContainer {
  paymentType: string;
}

export default function CtaButton({ handleCtaButtonClick, paymentType, shareLink }: ICtaButtonProps) {
  const { t } = useTranslation();

  const ctaButtonText = () => {
    switch (paymentType) {
      case 'brand':
        return t('participateInApp');
      case 'event':
        return t('viewInApp');
      default:
        return t('purchaseInApp');
    }
  };

  return (
    <CtaButtonContainer paymentType={paymentType}>
      <CtaButtonWrapper onClick={handleCtaButtonClick}>{ctaButtonText()}</CtaButtonWrapper>
    </CtaButtonContainer>
  );
}

const CtaButtonContainer = styled.div<ICtaButtonContainer>`
  ${({ paymentType }) =>
    paymentType === 'event' &&
    `
    width: 100%;
  `}
`;

const CtaButtonWrapper = styled.div`
  transition: all 0.3s ease 0s;
  animation-name: MainToPale;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 2.5rem;
  color: ${theme.color.basicWhite};
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 24px;
  cursor: pointer;
  :hover {
    color: ${theme.color.basicWhite};
  }
  min-width: 190px;
`;
