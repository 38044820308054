import { RootState } from 'modules/reducers';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { theme } from 'styles/theme';

interface IDownloadCountProps {
  downloadCount: number;
  paymentType: string;
}

export default function DownloadCount({ downloadCount, paymentType }: IDownloadCountProps) {
  const { t } = useTranslation();
  const languageType = useSelector((state: RootState) => state.languageTypeReducer.languageType);

  const downloadCountText = () => {
    switch (paymentType) {
      case 'brand':
        return t('peopleHaveParticipatedThis');
      default:
        return t('peopleHaveSelectedThis');
    }
  };

  return (
    <DownloadCountContainer>
      <DownloadCountWrapper>{downloadCount.toLocaleString()}</DownloadCountWrapper>
      {languageType === 'en' && <Space></Space>}
      {downloadCountText()}
    </DownloadCountContainer>
  );
}

const DownloadCountContainer = styled.div`
  display: flex;
  color: #aaabb3;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 20px;
  margin-top: 0.5rem;
  width: 100%;
`;

const DownloadCountWrapper = styled.div`
  color: ${theme.color.allMainColor};
`;

const Space = styled.div`
  margin-left: 5px;
`;
