import { Helmet } from 'react-helmet';
import { LanguageType } from 'scheme/theme/themeInfo';

interface IHelmet {
  title: string;
  description: string;
  image: string;
  languageType: LanguageType;
}
export default function PageHelmet({ title, description, image, languageType }: IHelmet) {
  const titleText = () => {
    switch (languageType) {
      case 'en':
        return `${title} - PlayKeyboard`;
      default:
        return `${title} - 플레이키보드`;
    }
  };

  return (
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-M0PJYJF8LN" />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-M0PJYJF8LN');
        `}
      </script>
      <title>{titleText()}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      <meta name="image" content={image} />
      <meta name="og:image" content={image} />
    </Helmet>
  );
}
