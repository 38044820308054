import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
import SearchInput from 'components/SearchPage/SearchInput';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IThemeSearch {
  placeholder?: string;
  inputValue?: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEnterPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleResetInput: () => void;
}

export default function ThemeSearch({
  placeholder,
  inputValue,
  handleInputChange,
  handleEnterPress,
  handleResetInput,
}: IThemeSearch) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCancelSearch = () => {
    // navigate(-1);
    navigate('/');
  };

  return (
    <ThemeSearchContainer>
      <SearchInput
        handleInputChange={handleInputChange}
        placeholder={placeholder}
        handleEnterPress={handleEnterPress}
        value={inputValue}
        handleResetInput={handleResetInput}
      />
      <CloseButton onClick={handleCancelSearch}>{t('close').toUpperCase()}</CloseButton>
    </ThemeSearchContainer>
  );
}

const ThemeSearchContainer = styled.div`
  padding: 16px 0;
  background: ${theme.color.basicWhite};
  display: flex;
  justify-content: space-between;
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 10%;
  min-width: 40px;
  padding-bottom: 4px;
  margin-left: 2rem;
  font-size: 1.6rem;
  color: ${theme.color.allSubDarkGray};
  cursor: pointer;
  font-weight: 500;
`;
