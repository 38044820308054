import { GET_TODAY_THEME_INFO_START, GET_TODAY_THEME_INFO_SUCCESS, GET_TODAY_THEME_INFO_FAIL } from 'modules/types';
import { AxiosError } from 'axios';
import { ISuggestThemeInfo } from 'scheme/main/suggestThemeInfo';
import { GetTodayThemeInfoActionsType } from 'modules/actions/main/todayThemeInfo';

export type TodayThemeInfoState = {
  loading: boolean;
  todayThemeInfo: ISuggestThemeInfo[];
  error: AxiosError | null;
};

const initialState: TodayThemeInfoState = {
  loading: false,
  error: null,
  todayThemeInfo: [
    {
      category: [''],
      createdAt: '',
      downloads: 0,
      hashtag: [''],
      imageUrl: '',
      isBuy: false,
      isLiveTheme: false,
      isNewTheme: false,
      maximumIOSVersion: 0,
      maximumVersion: 0,
      minimulIOSVersion: 0,
      minimulVersion: 0,
      name: '',
      paymentType: '',
      price: 0,
      priority: 0,
      recommendPriority: 0,
      requireVersion: 0,
      sale: 0,
      testcase: [''],
      themeId: '',
      updatedAt: '',
      versionBranches: [''],
      _id: '',
    },
  ],
};

export default function todayThemeInfoReducer(
  state: TodayThemeInfoState = initialState,
  action: GetTodayThemeInfoActionsType,
): TodayThemeInfoState {
  switch (action.type) {
    case GET_TODAY_THEME_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case GET_TODAY_THEME_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        todayThemeInfo: action.todayThemeInfo,
      };
    case GET_TODAY_THEME_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
