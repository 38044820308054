import styled from 'styled-components/macro';
import Layout from 'layout/Layout';
import { theme } from 'styles/theme';
import React, { useEffect, useRef, useState } from 'react';
import { useInput } from 'hooks/useInput';
import ThemeSearch from 'components/SearchPage/ThemeSearch';
import Themes from 'components/Themes';
import CustomPageHelmet from 'components/CustomPageHelmet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'modules/reducers';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import { parseLanguageType } from 'utils/parseLanguageType';
import i18n from 'i18n';
import { useSearchParams } from 'react-router-dom';
import {
  getBlankSearchedThemeListInfoSuccess,
  getSearchedThemeListInfoMoreStart,
  getSearchedThemeListInfoStart,
} from 'modules/actions/search/searchedThemeListInfo';
import { useTranslation } from 'react-i18next';
import saveDataToLocalStorage from 'utils/saveDataFromLocalStorage';
import getDataFromLocalStorage from 'utils/getDataFromLocalStorage';
import RecentSearchKeywordList from 'components/SearchPage/RecentSearchKeywordList';
import { useLocation } from 'react-router';
import { getPopularSearchKeywordListInfoStart } from 'modules/actions/search/popularSearchKeywordListInfo';
import PopularSearchKeywordList from 'components/SearchPage/PopularSearchKeywordList';
import ErrorModal from 'components/ErrorModal';
import { event } from 'react-ga';

export default function SearchPage() {
  const dispatch = useDispatch();

  const languageType = useSelector((state: RootState) => state.languageTypeReducer.languageType);
  const searchedThemeListInfoData = useSelector(
    (state: RootState) => state.searchedThemeListInfoReducer.searchedThemeListInfo,
  );
  const searchedThemeListError = useSelector((state: RootState) => state.searchedThemeListInfoReducer.error);
  const popularSearchKeywordListInfoData = useSelector(
    (state: RootState) => state.popularSearchKeywordListInfoReducer.popularSearchKeywordListInfo,
  );
  const popularSearchKeywordListError = useSelector(
    (state: RootState) => state.popularSearchKeywordListInfoReducer.error,
  );
  const { inputValue, setInputValue, handleResetInput, handleInputChange } = useInput();
  const [searchedThemeListInfoStart, setSearchedThemeListInfoStart] = useState<number>(0);
  const [searchedThemeListInfoFetchMore, setSearchedThemeListInfoFetchMore] = useState<boolean>(true);
  const [previousSearchedThemeListInfoLength, setPreviousSearchedThemeListInfoLength] = useState<number>(0);
  const intersectRef = useRef<HTMLDivElement>(null);
  const { isVisible } = useIntersectionObserver(intersectRef, {
    root: null,
    rootMargin: '50px',
    threshold: 0.01,
  });
  const [searchCount, setSearchCount] = useState<number>(0);
  const [noSearchedTheme, setNoSearchedTheme] = useState<boolean>(false);
  const [recentSearchKeywordListInfo, setRecentSearchKeywordListInfo] = useState<string[]>();
  const { state } = useLocation();

  const searchedThemeListScrollTop = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const [query] = useSearchParams();

  useEffect(() => {
    // 만약 테마 정보 페이지의 해시태그를 클릭해 SearchPage로 이동해온 경우를 대응하기 위해 searchHashtag() 함수 실행
    // 인기 검색어 data fetch
    // 최근 검색어를 Local storage에서 불러와 recentSearchKeywordListInfo 할당
    searchHashtag();
    searchQueryString();
    dispatch(getPopularSearchKeywordListInfoStart(languageType));
    setRecentSearchKeywordListInfo(getDataFromLocalStorage('recentSearchKeyword') || []);
  }, []);

  const searchHashtag = () => {
    // navigate()의 인자로 {state: hashtag}가 할당되었을 경우 (테마 정보 페이지의 해시태그를 클릭해 SearchPage로 이동해온 경우)
    // Hashtags.tsx 참조
    if (typeof state === 'string') {
      searchThemes(state);
      setInputValue(state);
      saveRecentSearchKeyword(state);
    }
  };
  const searchQueryString = () => {
    const str = query.get('search');
    if (str) {
      searchThemes(str);
      setInputValue(str);
      saveRecentSearchKeyword(str);
    }
  };

  useEffect(() => {
    // inputValue를 입력하다가 다 지웠을 경우
    // searchedThemeListInfo를 빈 배열로 만들기 위한 액션 dispatch
    if (!inputValue) {
      dispatch(getBlankSearchedThemeListInfoSuccess([]));
    }
  }, [inputValue]);

  useEffect(() => {
    if (searchedThemeListInfoStart <= 0 || !searchedThemeListInfoFetchMore) return;
    // searchedThemeListInfoStart의 값이 변할 때(infinite scroll)
    // searchedThemeListInfo를 더 fetch해오기 위한 액션 dispatch
    dispatch(getSearchedThemeListInfoMoreStart(languageType, inputValue, searchedThemeListInfoStart, 20));
  }, [searchedThemeListInfoStart]);

  useEffect(() => {
    if (searchedThemeListInfoData.length <= 0) return;
    if (previousSearchedThemeListInfoLength === searchedThemeListInfoData.length) {
      // fetch 이전 테마 리스트 length가 fetch 이후 테마 리스트 length와 같으면
      // themeListInfoFetchMore를 false로 변경하여 더 이상 fetch하지 않도록 설정
      setSearchedThemeListInfoFetchMore(false);
      return;
    }
    setPreviousSearchedThemeListInfoLength(searchedThemeListInfoData.length);
  }, [searchedThemeListInfoData]);

  useEffect(() => {
    // searchedThemeListInfoData가 변하면 noSearchedTheme flag를 변경하기 위해 실행할 코드들
    if (!searchCount) return;
    if (!inputValue) {
      setNoSearchedTheme(false);
      return;
    }
    if (searchedThemeListInfoData.length <= 0) {
      setNoSearchedTheme(true);
    } else {
      setNoSearchedTheme(false);
    }
  }, [searchedThemeListInfoData]);

  useEffect(() => {
    // 지정된 요소가 root(null이면 기본값인 브라우저 뷰포트)와 교차하며
    // themeList 내 theme 개수가 0개 이상이면 searchedThemeListInfoStart에 20 더하기
    if (isVisible && searchedThemeListInfoData.length && inputValue) {
      setSearchedThemeListInfoStart((prev) => {
        return prev + 20;
      });
    }
  }, [isVisible]);

  const searchThemes = (inputValue: string) => {
    // 테마 검색 함수
    // inputValue로 검색한 테마 리스트 fetch
    dispatch(getSearchedThemeListInfoStart(languageType, inputValue, 0, 20));
    // searchedThemeList와 연관된 값들 초기화
    setSearchedThemeListInfoStart(0);
    setSearchedThemeListInfoFetchMore(true);
    setPreviousSearchedThemeListInfoLength(0);
    // 검색 횟수 1 증가
    setSearchCount((prev) => prev + 1);
  };

  const saveRecentSearchKeyword = (inputValue: string) => {
    // 최근 검색어 Local storage에 저장하는 함수
    const recentSearchKeyword = getDataFromLocalStorage('recentSearchKeyword') || [];
    if (recentSearchKeyword.includes(inputValue)) return;
    recentSearchKeyword.unshift(inputValue);
    if (recentSearchKeyword.length > 20) {
      recentSearchKeyword.pop();
    }
    saveDataToLocalStorage('recentSearchKeyword', recentSearchKeyword);
    setRecentSearchKeywordListInfo(recentSearchKeyword);
  };

  const deleteRecentSearchKeywordList = () => {
    // 최근 검색어 전체 삭제하는 함수
    saveDataToLocalStorage('recentSearchKeyword', []);
    setRecentSearchKeywordListInfo([]);
  };

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // enter key 눌렀을 때 실행할 함수
    if (!inputValue) return;
    if (e.key === 'Enter') {
      event({
        category: 'user_search_try',
        action: 'Try user_search',
        label: inputValue,
      });

      searchedThemeListScrollTop.current && window.scrollTo(0, 0);
      searchThemes(inputValue);
      saveRecentSearchKeyword(inputValue);
    }
  };

  const handleSearchKeywordClick = (keyword: string, type: string) => {
    // 인기 검색어, 최근 검색어와 같은 검색 키워드를 클릭했을 때 실행할 함수
    if (type === 'trending') {
      event({
        category: 'user_search_popular_keyword_click',
        action: 'Click user_search_popular_keyword',
        label: keyword,
      });
    } else if (type === 'recent') {
      event({
        category: 'user_search_recently_keyword_click',
        action: 'Click user_search_recently_keyword',
        label: keyword,
      });
    }
    searchThemes(keyword);
    setInputValue(keyword);
  };

  return (
    <Layout>
      <CustomPageHelmet
        languageType={languageType}
        title="플레이키보드 - 수백가지 취향저격 키보드 테마"
        englishTitle="PlayKeyboard"
      />
      <ThemeSearchContainer ref={searchedThemeListScrollTop}>
        <ThemeSearchWrapper>
          <ThemeSearch
            placeholder={t('themeSearch')}
            inputValue={inputValue}
            handleInputChange={handleInputChange}
            handleEnterPress={handleEnterPress}
            handleResetInput={handleResetInput}
          />
        </ThemeSearchWrapper>
        {searchedThemeListError && <ErrorModal error={searchedThemeListError} />}
        {popularSearchKeywordListError && <ErrorModal error={popularSearchKeywordListError} />}
        {!inputValue && recentSearchKeywordListInfo && recentSearchKeywordListInfo.length > 0 && (
          <RecentSearchKeywordList
            deleteRecentSearchKeywordList={deleteRecentSearchKeywordList}
            recentSearchKeywordListInfo={recentSearchKeywordListInfo}
            handleRecentSearchKeywordClick={handleSearchKeywordClick}
          />
        )}
        {!inputValue && (
          <PopularSearchKeywordList
            popularSearchKeywordListInfo={popularSearchKeywordListInfoData}
            handleSearchKeywordClick={handleSearchKeywordClick}
            saveRecentSearchKeyword={saveRecentSearchKeyword}
          />
        )}

        <Themes themesInfoData={searchedThemeListInfoData} type="searchPageThemeList" />

        {noSearchedTheme && (
          <NoSearchThemeContainer>
            <NoSearchThemeText1>{t('noSearchedTheme1')}</NoSearchThemeText1>
            <NoSearchThemeText2>{t('noSearchedTheme2')}</NoSearchThemeText2>
          </NoSearchThemeContainer>
        )}
        <div ref={intersectRef}></div>
      </ThemeSearchContainer>
    </Layout>
  );
}

const ThemeSearchContainer = styled.div`
  width: 100%;
  padding: 0px 16px;
`;

const ThemeSearchWrapper = styled.div`
  z-index: 200;
  position: sticky;
  top: 0%;
  width: 100%;
  background: ${theme.color.basicWhite};
`;

const NoSearchThemeContainer = styled.div`
  margin-top: 8%;
  text-align: center;
`;

const NoSearchThemeText1 = styled.div`
  font-size: 1.55rem;
  font-weight: 700;
`;

const NoSearchThemeText2 = styled.div`
  font-size: 1.4rem;
  color: ${theme.color.allSubLightGray};
  font-weight: 700;
`;
