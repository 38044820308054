import { useState, useEffect } from 'react';
// 요소의 교차 상태를 확인하여 isVisible로 true/false 반환하는 hook
export const useIntersectionObserver = (
  intersectRef: React.RefObject<HTMLDivElement>,
  optionsObject: {
    root: null;
    rootMargin: string;
    threshold: number;
  },
) => {
  const { root = null, rootMargin = '0px', threshold } = optionsObject;

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleIntersect: IntersectionObserverCallback = (entries) => {
    // 변수 target에 IntersectionObserverEntry 객체 할당
    const target = entries[0];

    // 교차 여부에 따라 isVisible 상태값 변경
    if (target.isIntersecting) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersect, {
      root: root,
      rootMargin: rootMargin,
      threshold: threshold,
    });

    // intersectRef.current가 존재하면 해당 요소를 IntersectionObserver가 observe하도록 함
    if (intersectRef.current) observer.observe(intersectRef.current);

    // unmount될 때 observer의 가시성 변화 주시 대상 해제
    return () => observer.disconnect();
  }, [intersectRef, root, rootMargin, threshold]);

  return {
    isVisible,
  };
};
