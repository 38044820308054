import i18n from 'i18n';
import { RootState } from 'modules/reducers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { parseLanguageType } from 'utils/parseLanguageType';

interface IAdsense {
  currentPath: string;
  client: string;
  slot: string;
  layoutKey: string;
}

export default function Ad({ currentPath, client, slot, layoutKey }: IAdsense) {
  const languageType = useSelector((state: RootState) => state.languageTypeReducer.languageType);

  useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || [];
    window.adsbygoogle.push({});
  }, [currentPath, languageType]);

  return (
    <div key={currentPath}>
      <ins
        className="adsbygoogle"
        style={{ display: 'block', marginTop: '20px' }}
        data-ad-format="fluid"
        data-ad-layout-key={layoutKey}
        data-ad-client={client}
        data-ad-slot={slot}
      />
    </div>
  );
}
