import { useState } from 'react';
// clipboard에 해당 페이지의 url을 복사하기 위한 hook
export const useClipboardCopy = () => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleClipboardCopy = async (copiedText: string) => {
    try {
      await navigator.clipboard.writeText(copiedText);
      setIsCopied(true);
      return;
    } catch (err) {
      setIsCopied(false);
    }

    try {
      const t = document.createElement('textarea');
      document.body.appendChild(t);
      t.value = copiedText;
      t.select();
      document.execCommand('copy');
      document.body.removeChild(t);
      setIsCopied(true);
      return;
    } catch (err) {
      setIsCopied(false);
    }
  };

  return { handleClipboardCopy, isCopied, setIsCopied };
};
