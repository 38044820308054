import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
interface INewMark {
  isNewTheme: boolean;
}
export default function NewMark({ isNewTheme }: INewMark) {
  return (
    <>
      {isNewTheme && (
        <NewMarkContainer>
          <NewMarkText>N</NewMarkText>
        </NewMarkContainer>
      )}
    </>
  );
}

const NewMarkContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.color.allMainColor};
  width: 11%;
  height: 10%;
  max-width: 30px;
  max-height: 30px;
  min-width: 15px;
  min-height: 15px;
  border-radius: 50%;
  top: 4%;
  right: 5.5%;
  z-index: 100;
`;

const NewMarkText = styled.div`
  color: ${theme.color.basicWhite};
  font-size: 1.2rem;
`;
