import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
import { useDragScroll } from 'hooks/useDragScroll';
import { useNavigate } from 'react-router-dom';
import { ISuggestThemeInfo } from 'scheme/main/suggestThemeInfo';
import NewMark from 'components/MainPage/NewMark';
import { event } from 'react-ga';

interface ISuggestThemeProps {
  suggestThemeInfo: ISuggestThemeInfo[];
  type: string;
}

export default function SuggestTheme({ suggestThemeInfo, type }: ISuggestThemeProps) {
  const navigate = useNavigate();
  const { handleMouseDown, handleMouseLeave, handleMouseMove, isDragging, wasDragged, scrolledRef } = useDragScroll();

  const handleMoveToThemeInfoPage = (themeId: string) => {
    if (wasDragged) return;
    if (type === 'today') {
      event({
        category: 'user_store_today_theme_click',
        action: 'Click user_store_today_theme',
        label: themeId,
      });
    } else if (type === 'trending') {
      event({
        category: 'user_store_top_theme_click',
        action: 'Click user_store_top_theme',
        label: themeId,
      });
    }

    navigate(`/theme/${themeId}`);
  };

  return (
    <SuggestThemeWrapper
      ref={scrolledRef}
      onMouseDown={handleMouseDown}
      onMouseMove={isDragging ? handleMouseMove : undefined}
      onMouseUp={handleMouseLeave}
      onMouseLeave={handleMouseLeave}
    >
      <SuggestThemeBox>
        {suggestThemeInfo.map((suggestTheme: ISuggestThemeInfo, index: number) => (
          <SuggestThemeItem key={suggestTheme._id} onClick={() => handleMoveToThemeInfoPage(suggestTheme.themeId)}>
            {type === 'trending' ? (
              <RankContainer>
                <Rank>{index + 1}</Rank>
              </RankContainer>
            ) : (
              <NewMark isNewTheme={suggestTheme.isNewTheme} />
            )}
            <SuggestThemeImage alt="" src={suggestTheme.imageUrl} />
            <SuggestThemeName>{suggestTheme.name}</SuggestThemeName>
            <SuggestThemeHashtagContainer>
              <SuggestThemeHashtag>#{suggestTheme.hashtag.join(' #')}</SuggestThemeHashtag>
            </SuggestThemeHashtagContainer>
            <SuggestThemeInformation></SuggestThemeInformation>
          </SuggestThemeItem>
        ))}
      </SuggestThemeBox>
    </SuggestThemeWrapper>
  );
}

const SuggestThemeWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SuggestThemeBox = styled.div`
  margin: 16px 8px;
  display: flex;
`;

const SuggestThemeItem = styled.div`
  margin: 0px 8px;
  border-radius: 20px;
  background-color: ${theme.color.basicWhite};
  min-width: 125px;
  width: 8.5vw;
  max-width: 160px;
  position: relative;
  cursor: pointer;
`;

const RankContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.color.allMainColor};
  width: 18%;
  height: 15%;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 100;
`;

const Rank = styled.div`
  color: ${theme.color.basicWhite};
  font-size: 1.2rem;
`;

const SuggestThemeImage = styled.img`
  border-radius: 10px;
`;

const SuggestThemeName = styled.div`
  font-size: 1.4rem;
  margin-top: 4px;
  margin-right: 3px;
  padding-bottom: 2px;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SuggestThemeHashtagContainer = styled.div`
  display: flex;
`;

const SuggestThemeHashtag = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 18px;
  color: ${theme.color.allSubLightGray};
  margin-right: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SuggestThemeInformation = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
