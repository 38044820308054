import { useState } from 'react';
// input값을 state화하는 hookß
export const useInput = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const handleResetInput = () => {
    setInputValue('');
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return { inputValue, setInputValue, handleResetInput, handleInputChange };
};
