import { all } from 'redux-saga/effects';
import keywordListSaga from 'modules/sagas/theme/keywordListSaga';
import reviewListSaga from 'modules/sagas/theme/reviewListSaga';
import themeInfoSaga from 'modules/sagas/theme/themeInfoSaga';
import eventInfoSaga from './event/eventInfoSaga';
import eventReviewListSaga from './event/eventReviewListSaga';
import bannerInfoSaga from './main/bannerInfoSaga';
import todayThemeInfoSaga from './main/todayThemeInfoSaga';
import popularThemeInfoSaga from './main/popularThemeInfoSaga';
import themeListCategoryInfoSaga from './main/themeListCategoryInfoSaga';
import themeListInfoSaga from './main/themeListInfoSaga';
import searchedThemeListInfoSaga from './search/searchedThemeListInfoSaga';
import popularSearchKeywordListInfoSaga from './search/popularSearchKeywordListInfoSaga';

export default function* rootSaga() {
  // all을 통해 배열 안 여러 saga들을 하나로 묶어서 사용
  yield all([
    themeInfoSaga(),
    reviewListSaga(),
    keywordListSaga(),
    eventInfoSaga(),
    eventReviewListSaga(),
    bannerInfoSaga(),
    todayThemeInfoSaga(),
    popularThemeInfoSaga(),
    themeListCategoryInfoSaga(),
    themeListInfoSaga(),
    searchedThemeListInfoSaga(),
    popularSearchKeywordListInfoSaga(),
  ]);
}
