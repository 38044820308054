import { useState } from 'react';
// snackbar를 활성화/비활성화 하기 위한 hook
export const useSnackbar = () => {
  const [isShowedUp, setIsShowedUp] = useState<boolean>(false);
  const showSnackbar = () => {
    if (isShowedUp) return;
    setIsShowedUp(true);
    setTimeout(() => setIsShowedUp(false), 3000);
  };

  const closeSnackbar = () => {
    setIsShowedUp(false);
  };

  return { isShowedUp, showSnackbar, closeSnackbar };
};
