import {
  GET_EVENT_REVIEW_LIST_START,
  GET_EVENT_REVIEW_LIST_SUCCESS,
  GET_EVENT_REVIEW_LIST_FAIL,
  GET_EVENT_REVIEW_LIST_COUNT_SUCCESS,
  GET_EVENT_REVIEW_LIST_COUNT_FAIL,
  GET_EVENT_REVIEW_LIST_MORE_START,
  GET_EVENT_REVIEW_LIST_MORE_SUCCESS,
  GET_EVENT_REVIEW_LIST_MORE_FAIL,
} from 'modules/types';
import { AxiosError } from 'axios';
import { IReviewList, IReviewUserInfo } from 'scheme/reviewList';
import { GetEventReviewListActionsType } from 'modules/actions/event/eventReviewList';

export type EventReviewListState = {
  loading: boolean;
  eventReviewList: IReviewList[];
  eventReviewListCount: number;
  error: AxiosError | null;
};

const initialState: EventReviewListState = {
  loading: false,
  error: null,
  eventReviewListCount: 0,
  eventReviewList: [
    {
      _id: '',
      isBuy: false,
      isTop: false,
      isCreator: false,
      hide: false,
      user: {} as IReviewUserInfo,
      content: '',
      createdAt: '',
      updatedAt: '',
    },
  ],
};

export default function reviewListReducer(
  state: EventReviewListState = initialState,
  action: GetEventReviewListActionsType,
): EventReviewListState {
  switch (action.type) {
    case GET_EVENT_REVIEW_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case GET_EVENT_REVIEW_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        eventReviewList: action.payload,
      };
    case GET_EVENT_REVIEW_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_EVENT_REVIEW_LIST_COUNT_SUCCESS:
      return {
        ...state,
        eventReviewListCount: action.payload,
      };
    case GET_EVENT_REVIEW_LIST_COUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_EVENT_REVIEW_LIST_MORE_START:
      return {
        ...state,
        loading: true,
      };
    // 해당 액션 dispatch 시 reviewList에 fetch해온 리뷰 목록을 이어 붙임
    case GET_EVENT_REVIEW_LIST_MORE_SUCCESS:
      return {
        ...state,
        eventReviewList: state.eventReviewList.concat(action.payload),
      };
    case GET_EVENT_REVIEW_LIST_MORE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
