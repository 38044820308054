import {
  GET_THEME_LIST_CATEGORY_INFO_START,
  GET_THEME_LIST_CATEGORY_INFO_SUCCESS,
  GET_THEME_LIST_CATEGORY_INFO_FAIL,
} from 'modules/types';
import { AxiosError } from 'axios';
import { GetThemeListCategoryInfoActionsType } from 'modules/actions/main/themeListCategoryInfo';

export type ThemeListCategoryInfoState = {
  loading: boolean;
  themeListCategoryInfo: string[];
  error: AxiosError | null;
};

const initialState: ThemeListCategoryInfoState = {
  loading: false,
  error: null,
  themeListCategoryInfo: [''],
};

export default function themeListCategoryInfoReducer(
  state: ThemeListCategoryInfoState = initialState,
  action: GetThemeListCategoryInfoActionsType,
): ThemeListCategoryInfoState {
  switch (action.type) {
    case GET_THEME_LIST_CATEGORY_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case GET_THEME_LIST_CATEGORY_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        themeListCategoryInfo: action.themeListCategoryInfo,
      };
    case GET_THEME_LIST_CATEGORY_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
