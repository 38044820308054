import { call, put, takeEvery } from 'redux-saga/effects';
import axios, { AxiosResponse, AxiosError } from 'axios';
import {
  GET_EVENT_REVIEW_LIST_START,
  GET_EVENT_REVIEW_LIST_SUCCESS,
  GET_EVENT_REVIEW_LIST_FAIL,
  GET_EVENT_REVIEW_LIST_COUNT_SUCCESS,
  GET_EVENT_REVIEW_LIST_COUNT_FAIL,
  GET_EVENT_REVIEW_LIST_MORE_START,
  GET_EVENT_REVIEW_LIST_MORE_SUCCESS,
  GET_EVENT_REVIEW_LIST_MORE_FAIL,
} from 'modules/types';
import { LanguageType } from 'scheme/theme/themeInfo';

// eventId, languageType, start, count를 인자로 받아 review list fetch 후 return
function fetchEventReviewList(eventId: string | undefined, languageType: LanguageType, start: number, count: number) {
  const eventReviewList = axios.get(`/event/review?eventId=${eventId}&start=${start}&count=${count}`, {
    headers: { 'content-language': languageType },
  });

  return eventReviewList;
}

// eventId, languageType을 인자로 받아 reviewList의 review 개수 fetch 후 return
function fetchEventReviewListCount(eventId: string | undefined, languageType: LanguageType) {
  const eventReviewListCount = axios.get(`/event/review?eventId=${eventId}&getCount=true`, {
    headers: { 'content-language': languageType },
  });

  return eventReviewListCount;
}

// action을 인자로 받아 비동기 data fetch 처리하는 generator function
// 액션이 지닌 값을 가져와서 사용하기 위해 액션을 인자로 받아옴
function* getEventReviewList(action: {
  type: string;
  eventId: string | undefined;
  languageType: LanguageType;
  start: number;
  count: number;
}) {
  try {
    // call을 통해 인자로 전달한 함수 호출 후 결과 반환될 때까지 대기
    const eventReviewList: AxiosResponse = yield call(
      fetchEventReviewList,
      action.eventId,
      action.languageType,
      action.start,
      action.count,
    );

    const eventReviewListCount: AxiosResponse = yield call(
      fetchEventReviewListCount,
      action.eventId,
      action.languageType,
    );

    // 성공 액션 디스패치
    yield put({
      type: GET_EVENT_REVIEW_LIST_SUCCESS,
      payload: eventReviewList.data.data,
    });

    yield put({
      type: GET_EVENT_REVIEW_LIST_COUNT_SUCCESS,
      payload: eventReviewListCount.data.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    // 실패 액션 디스패치
    yield put({
      type: GET_EVENT_REVIEW_LIST_FAIL,
      payload: error,
    });
    yield put({
      type: GET_EVENT_REVIEW_LIST_COUNT_FAIL,
      payload: error,
    });
  }
}

function* getEventReviewListMore(action: {
  type: string;
  eventId: string | undefined;
  languageType: LanguageType;
  start: number;
  count: number;
}) {
  try {
    const eventReviewList: AxiosResponse = yield call(
      fetchEventReviewList,
      action.eventId,
      action.languageType,
      action.start,
      action.count,
    );

    yield put({
      type: GET_EVENT_REVIEW_LIST_MORE_SUCCESS,
      payload: eventReviewList.data.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    yield put({
      type: GET_EVENT_REVIEW_LIST_MORE_FAIL,
      payload: error,
    });
  }
}

// 이 함수 내부에 모든 saga들을 합칠 수 있음
export function* eventReviewListSaga() {
  yield takeEvery(GET_EVENT_REVIEW_LIST_START, getEventReviewList);
  yield takeEvery(GET_EVENT_REVIEW_LIST_MORE_START, getEventReviewListMore);
}

export default eventReviewListSaga;
