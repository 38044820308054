import * as type from 'modules/types';
import { AxiosError } from 'axios';
import { IBannerInfo } from 'scheme/main/bannerInfo';
import { LanguageType } from 'scheme/theme/themeInfo';

export const getBannerInfoStart = (languageType: LanguageType | undefined) => ({
  type: type.GET_BANNER_INFO_START,
  languageType,
});

export const getBannerInfoSuccess = (bannerInfo: IBannerInfo[]) => ({
  type: type.GET_BANNER_INFO_SUCCESS,
  bannerInfo,
});

export const getBannerInfoError = (error: AxiosError) => ({
  type: type.GET_BANNER_INFO_FAIL,
  payload: error,
});

export type GetBannerInfoActionsType =
  | ReturnType<typeof getBannerInfoStart>
  | ReturnType<typeof getBannerInfoSuccess>
  | ReturnType<typeof getBannerInfoError>;
