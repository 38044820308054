import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
import { useNavigate } from 'react-router-dom';
import DownloadIconImage from 'assets/icons/ic_all_download.png';
import GemIconImage from 'assets/icons/ic_all_zem.png';
import { IThemeListInfo } from 'scheme/main/themeListInfo';
import { parseNumber } from 'utils/parseNumber';
import NewMark from 'components/MainPage/NewMark';
import { useTranslation } from 'react-i18next';
import { event } from 'react-ga';

interface IThemesProps {
  themesInfoData: IThemeListInfo[];
  type: string;
  searchTerm?: string;
}

export default function Themes({ themesInfoData, type, searchTerm }: IThemesProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleMoveToThemeInfoPage = (themeId: string) => {
    if (type === 'mainPageThemeList') {
      event({
        category: 'user_store_theme_click',
        action: 'Click user_store_theme',
        label: themeId,
      });
    } else if (type === 'searchPageThemeList') {
      event({
        category: 'user_search_theme_click',
        action: 'Click user_search_theme',
        label: themeId,
        dimension1: searchTerm,
      });
    }
    navigate(`/theme/${themeId}`);
  };

  return (
    <ThemeListWrapper>
      {themesInfoData
        .filter((theme: IThemeListInfo) => theme.imageUrl !== '')
        .map((theme: IThemeListInfo) => (
          <ThemeContainer key={theme._id} onClick={() => handleMoveToThemeInfoPage(theme.themeId)}>
            {type === 'search' ? null : theme.isNewTheme ? (
              <NewMark isNewTheme={theme.isNewTheme} />
            ) : (
              theme.paymentType === 'brand' && (
                <EventMarkContainer>
                  <EventMark>{t('event')}</EventMark>
                </EventMarkContainer>
              )
            )}
            <ThemeImage alt="" src={theme.imageUrl} />
            <ThemeName>{theme.name}</ThemeName>
            <ThemeHashtagContainer>
              <ThemeHashtag>#{theme.hashtag.join(' #')}</ThemeHashtag>
            </ThemeHashtagContainer>
            <ThemeInformation>
              {/* <DownloadCountContainer>
                <DownloadCountIcon src={DownloadIconImage} alt="" />
                <DownloadCountNumber>{parseNumber(theme.downloads)}</DownloadCountNumber>
              </DownloadCountContainer> */}
              {/* <PriceContainer>
                <GemIcon src={GemIconImage} alt="" />
                <RelativeThemesPrice>{theme.price}</RelativeThemesPrice>
              </PriceContainer> */}
            </ThemeInformation>
          </ThemeContainer>
        ))}
    </ThemeListWrapper>
  );
}

const EventMarkContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.4);
  width: 24%;
  height: 12%;

  @media screen and ${theme.device.mobile} {
    width: 30%;
    height: 15%;
  }
  max-width: 60px;
  max-height: 30px;
  border-radius: 20px;
  top: 5%;
  left: 5%;
  z-index: 100;
`;

const EventMark = styled.div`
  color: ${theme.color.basicWhite};
  font-size: 1.2rem;
  @media screen and ${theme.device.mobile} {
    font-size: 1rem;
  }

  font-weight: 600;
`;

const ThemeListWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ThemeContainer = styled.div`
  position: relative;
  margin: 0 0 1.6rem;
  border-radius: 20px;
  background-color: ${theme.color.basicWhite};
  width: 48.5%;
  cursor: pointer;
`;

const ThemeImage = styled.img`
  border-radius: 10px;
`;

const ThemeName = styled.div`
  font-size: 1.4rem;
  margin: 4px 0 0px;
  padding-bottom: 2px;
  margin-right: 3px;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ThemeHashtagContainer = styled.div`
  display: flex;
`;

const ThemeHashtag = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 18px;
  color: ${theme.color.allSubLightGray};
  margin-right: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ThemeInformation = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DownloadCountContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DownloadCountIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 3px;
`;

const DownloadCountNumber = styled.div`
  color: ${theme.color.allSubLightGray};
  font-size: 1.2rem;
`;

const PriceContainer = styled(DownloadCountContainer)``;

const GemIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 3px;
`;

const RelativeThemesPrice = styled.div`
  color: ${theme.color.gemTxtColor};
  font-size: 1.2rem;
`;
