import { call, put, takeEvery } from 'redux-saga/effects';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { GET_EVENT_INFO_START, GET_EVENT_INFO_SUCCESS, GET_EVENT_INFO_FAIL } from 'modules/types';
import { LanguageType } from 'scheme/theme/themeInfo';

// eventId, languageType을 인자로 받아 data fetch 후 fetched data return
function fetchEventInfo(eventId: string, languageType: LanguageType) {
  const eventInfo = axios.get(`/event/${eventId}`, {
    headers: { 'content-language': languageType },
  });
  return eventInfo;
}

function fetchWebStoreShareLink(eventId: string, languageType: LanguageType) {
  const webStoreShareLink = axios.get(`/event/${eventId}/share?isWebStore=true`, {
    headers: { 'content-language': languageType },
  });
  return webStoreShareLink;
}

function fetchAppStoreShareLink(eventId: string, languageType: LanguageType) {
  const appStoreShareLink = axios.get(`/event/${eventId}/share`, {
    headers: { 'content-language': languageType },
  });
  return appStoreShareLink;
}

// action을 인자로 받아 비동기 data fetch 처리하는 generator function
// 액션이 지닌 값을 가져와서 사용하기 위해 액션을 인자로 받아옴
function* getEventInfo(action: { type: string; eventId: string; languageType: LanguageType }) {
  try {
    // call의 인자로 함수와 해당 함수에서 인자로 사용할 값을 삽입
    // call을 통해 인자로 전달한 함수 호출 후 결과 반환될 때까지 대기
    // eventInfo, webStoreShareLink, appStoreShareLink에는 각각 인자로 전달된 함수에서 fetch된 data가 return되어 할당됨
    const eventInfo: AxiosResponse = yield call(fetchEventInfo, action.eventId, action.languageType);
    const webStoreShareLink: AxiosResponse = yield call(fetchWebStoreShareLink, action.eventId, action.languageType);
    const appStoreShareLink: AxiosResponse = yield call(fetchAppStoreShareLink, action.eventId, action.languageType);

    // 성공 액션 디스패치
    yield put({
      type: GET_EVENT_INFO_SUCCESS,
      eventInfo: eventInfo.data.data,
      webStoreShareLink: webStoreShareLink.data.data,
      appStoreShareLink: appStoreShareLink.data.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    // 실패 액션 디스패치
    yield put({
      type: GET_EVENT_INFO_FAIL,
      payload: error,
    });
  }
}

// 이 함수 내부에 모든 saga들을 합칠 수 있음
export function* eventInfoSaga() {
  yield takeEvery(GET_EVENT_INFO_START, getEventInfo);
}

export default eventInfoSaga;
