import styled from 'styled-components/macro';
import mixin from 'styles/mixin';
import { useTranslation } from 'react-i18next';
import { ISuggestThemeInfo } from 'scheme/main/suggestThemeInfo';
import SuggestTheme from './SuggestTheme';

interface IPopularThemeProps {
  popularThemeInfo: ISuggestThemeInfo[];
}

export default function TrendingTheme({ popularThemeInfo }: IPopularThemeProps) {
  const { t } = useTranslation();

  return (
    <PopularThemesContainer>
      <PopularThemesTitle>{t('trendingThemeTitle')}</PopularThemesTitle>
      <SuggestTheme suggestThemeInfo={popularThemeInfo} type="trending" />
    </PopularThemesContainer>
  );
}
const PopularThemesContainer = styled.div`
  margin-top: 44px;
`;

const PopularThemesTitle = styled.div`
  ${mixin.h3}
  padding: 0 16px;
`;
