import styled from 'styled-components/macro';
import { theme } from 'styles/theme';

interface ISnackbarProps {
  content: string;
  closeSnackbar: () => void;
}

export default function Snackbar({ content, closeSnackbar }: ISnackbarProps) {
  return (
    <SnackbarContainer>
      <SnackbarWrapper>
        <Content>{content}</Content>
        <ConfirmButton onClick={closeSnackbar}>확인</ConfirmButton>
      </SnackbarWrapper>
    </SnackbarContainer>
  );
}

const SnackbarContainer = styled.div`
  display: inline-block;
  position: fixed;
  bottom: 30px;
  left: 50%;
  width: 90%;
  max-width: 380px;
  padding: 10px 16px;
  transform: translate(-50%, -50%);
  z-index: 200;
  background: rgba(46, 47, 51, 0.9);
  color: #fff;
  border-radius: 8px;
  animation: fade-in 0.2s;
`;

const SnackbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
`;

const Content = styled.div``;

const ConfirmButton = styled.div`
  color: ${theme.color.allMainColor};
  cursor: pointer;
`;
