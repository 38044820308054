import { call, put, takeEvery } from 'redux-saga/effects';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { GET_THEME_INFO_START, GET_THEME_INFO_SUCCESS, GET_THEME_INFO_FAIL } from 'modules/types';
import { LanguageType } from 'scheme/theme/themeInfo';

// themeId, languageType을 인자로 받아 data fetch 후 return
function fetchThemeInfo(themeId: string, languageType: LanguageType) {
  const themeInfo = axios.get(`/theme/${themeId}`, {
    headers: { 'content-language': languageType },
  });
  return themeInfo;
}

function fetchWebStoreShareLink(themeId: string, languageType: LanguageType) {
  const webStoreShareLink = axios.get(`/theme/${themeId}/share?isWebStore=true`, {
    headers: { 'content-language': languageType },
  });
  return webStoreShareLink;
}

function fetchAppStoreShareLink(themeId: string, languageType: LanguageType) {
  const appStoreShareLink = axios.get(`/theme/${themeId}/share`, {
    headers: { 'content-language': languageType },
  });
  return appStoreShareLink;
}

// action을 인자로 받아 비동기 data fetch 처리하는 generator function
// 액션이 지닌 값을 가져와서 사용하기 위해 액션을 인자로 받아옴
function* getThemeInfo(action: { type: string; themeId: string; languageType: LanguageType }) {
  try {
    // call을 통해 인자로 전달한 함수 호출 후 결과 반환될 때까지 대기
    const themeInfo: AxiosResponse = yield call(fetchThemeInfo, action.themeId, action.languageType);
    const webStoreShareLink: AxiosResponse = yield call(fetchWebStoreShareLink, action.themeId, action.languageType);
    const appStoreShareLink: AxiosResponse = yield call(fetchAppStoreShareLink, action.themeId, action.languageType);

    // 성공 액션 디스패치
    yield put({
      type: GET_THEME_INFO_SUCCESS,
      themeInfo: themeInfo.data.data,
      webStoreShareLink: webStoreShareLink.data.data,
      appStoreShareLink: appStoreShareLink.data.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    // 실패 액션 디스패치
    yield put({
      type: GET_THEME_INFO_FAIL,
      payload: error,
    });
  }
}

// 이 함수 내부에 모든 saga들을 합칠 수 있음
export function* themeInfoSaga() {
  yield takeEvery(GET_THEME_INFO_START, getThemeInfo);
}

export default themeInfoSaga;
