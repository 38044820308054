import { call, put, takeEvery } from 'redux-saga/effects';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { GET_BANNER_INFO_START, GET_BANNER_INFO_SUCCESS, GET_BANNER_INFO_FAIL } from 'modules/types';
import { LanguageType } from 'scheme/theme/themeInfo';

interface IParams {
  languageType: LanguageType;
  type: string;
}

function fetchBannerInfo(languageType: LanguageType) {
  const bannerInfo = axios.get(`/store/banner`, {
    headers: { 'content-language': languageType },
  });
  return bannerInfo;
}

function* getBannerInfo({ languageType }: IParams) {
  try {
    const bannerInfo: AxiosResponse = yield call(fetchBannerInfo, languageType);

    yield put({
      type: GET_BANNER_INFO_SUCCESS,
      bannerInfo: bannerInfo.data.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    yield put({
      type: GET_BANNER_INFO_FAIL,
      payload: error,
    });
  }
}

export function* bannerInfoSaga() {
  yield takeEvery(GET_BANNER_INFO_START, getBannerInfo);
}

export default bannerInfoSaga;
