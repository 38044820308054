import styled from 'styled-components/macro';
import { theme } from 'styles/theme';

interface IPreUseBubbleProps {
  content: string;
}

export default function PreUseBubble({ content }: IPreUseBubbleProps) {
  return (
    <BubbleContainer>
      <Bubble>{content}</Bubble>
    </BubbleContainer>
  );
}

const BubbleContainer = styled.div`
  margin-top: 0.9rem;
`;

const Bubble = styled.div`
  display: inline-block;
  border-radius: 10px;
  margin-top: 8px;
  background: ${theme.color.allMainColor};
  padding: 5px 10px;
  font-size: 1.3rem;
  color: ${theme.color.basicWhite};
  position: relative;
  text-align: center;
  :before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-top: 12px solid transparent;
    border-bottom: 8px solid ${theme.color.allMainColor};
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    left: 15px;
    top: -20px;
  }
`;
