// get themeInfo action types
export const GET_THEME_INFO_START = 'themeInfo/GET_THEME_INFO_START' as const;
export const GET_THEME_INFO_SUCCESS = 'themeInfo/GET_THEME_INFO_SUCCESS' as const;
export const GET_THEME_INFO_FAIL = 'themeInfo/GET_THEME_INFO_FAIL' as const;

// get themeInfo review list action types
export const GET_REVIEW_LIST_START = 'themeInfo/GET_REVIEW_LIST_START' as const;
export const GET_REVIEW_LIST_SUCCESS = 'themeInfo/GET_REVIEW_LIST_SUCCESS' as const;
export const GET_REVIEW_LIST_FAIL = 'themeInfo/GET_REVIEW_LIST_FAIL' as const;
export const GET_REVIEW_LIST_COUNT_SUCCESS = 'themeInfo/GET_REVIEW_LIST_COUNT_SUCCESS' as const;
export const GET_REVIEW_LIST_COUNT_FAIL = 'themeInfo/GET_REVIEW_LIST_COUNT_FAIL' as const;

// get more review list action types
export const GET_REVIEW_LIST_MORE_START = 'themeInfo/GET_REVIEW_LIST_MORE_START' as const;
export const GET_REVIEW_LIST_MORE_SUCCESS = 'themeInfo/GET_REVIEW_LIST_MORE_SUCCESS' as const;
export const GET_REVIEW_LIST_MORE_FAIL = 'themeInfo/GET_REVIEW_LIST_MORE_FAIL' as const;

// get keyword list action types
export const GET_KEYWORD_LIST_START = 'themeInfo/GET_KEYWORD_LIST_START' as const;
export const GET_KEYWORD_LIST_SUCCESS = 'themeInfo/GET_KEYWORD_LIST_SUCCESS' as const;
export const GET_KEYWORD_LIST_FAIL = 'themeInfo/GET_KEYWORD_LIST_FAIL' as const;

// get languageType action type
export const GET_LANGUAGE_TYPE = 'languageType/GET_LANGUAGE_TYPE' as const;

// get eventInfo action types
export const GET_EVENT_INFO_START = 'eventInfo/GET_EVENT_INFO_START' as const;
export const GET_EVENT_INFO_SUCCESS = 'eventInfo/GET_EVENT_INFO_SUCCESS' as const;
export const GET_EVENT_INFO_FAIL = 'eventInfo/GET_EVENT_INFO_FAIL' as const;

// get eventInfo review list action types
export const GET_EVENT_REVIEW_LIST_START = 'eventInfo/GET_EVENT_REVIEW_LIST_START' as const;
export const GET_EVENT_REVIEW_LIST_SUCCESS = 'eventInfo/GET_EVENT_REVIEW_LIST_SUCCESS' as const;
export const GET_EVENT_REVIEW_LIST_FAIL = 'eventInfo/GET_EVENT_REVIEW_LIST_FAIL' as const;
export const GET_EVENT_REVIEW_LIST_COUNT_SUCCESS = 'eventInfo/GET_EVENT_REVIEW_LIST_COUNT_SUCCESS' as const;
export const GET_EVENT_REVIEW_LIST_COUNT_FAIL = 'eventInfo/GET_EVENT_REVIEW_LIST_COUNT_FAIL' as const;

// get more event review list action types
export const GET_EVENT_REVIEW_LIST_MORE_START = 'eventInfo/GET_EVENT_REVIEW_LIST_MORE_START' as const;
export const GET_EVENT_REVIEW_LIST_MORE_SUCCESS = 'eventInfo/GET_EVENT_REVIEW_LIST_MORE_SUCCESS' as const;
export const GET_EVENT_REVIEW_LIST_MORE_FAIL = 'eventInfo/GET_EVENT_REVIEW_LIST_MORE_FAIL' as const;

// get store banner action types
export const GET_BANNER_INFO_START = 'bannerInfo/GET_BANNER_INFO_START' as const;
export const GET_BANNER_INFO_SUCCESS = 'bannerInfo/GET_BANNER_INFO_SUCCESS' as const;
export const GET_BANNER_INFO_FAIL = 'bannerInfo/GET_BANNER_INFO_FAIL' as const;

// get today themeInfo action types
export const GET_TODAY_THEME_INFO_START = 'todayThemeInfo/GET_TODAY_THEME_INFO_START' as const;
export const GET_TODAY_THEME_INFO_SUCCESS = 'todayThemeInfo/GET_TODAY_THEME_INFO_SUCCESS' as const;
export const GET_TODAY_THEME_INFO_FAIL = 'todayThemeInfo/GET_TODAY_THEME_INFO_FAIL' as const;

// get popular themeInfo action types
export const GET_POPULAR_THEME_INFO_START = 'popularThemeInfo/GET_POPULAR_THEME_INFO_START' as const;
export const GET_POPULAR_THEME_INFO_SUCCESS = 'popularThemeInfo/GET_POPULAR_THEME_INFO_SUCCESS' as const;
export const GET_POPULAR_THEME_INFO_FAIL = 'popularThemeInfo/GET_POPULAR_THEME_INFO_FAIL' as const;

// get themeList categoryInfo action types
export const GET_THEME_LIST_CATEGORY_INFO_START = 'themeListInfo/GET_THEME_LIST_CATEGORY_INFO_START' as const;
export const GET_THEME_LIST_CATEGORY_INFO_SUCCESS = 'themeListInfo/GET_THEME_LIST_CATEGORY_INFO_SUCCESS' as const;
export const GET_THEME_LIST_CATEGORY_INFO_FAIL = 'themeListInfo/GET_THEME_LIST_CATEGORY_INFO_FAIL' as const;

// get themeListInfo action types
export const GET_THEME_LIST_INFO_START = 'themeListInfo/GET_THEME_LIST_INFO_START' as const;
export const GET_THEME_LIST_INFO_SUCCESS = 'themeListInfo/GET_THEME_LIST_INFO_SUCCESS' as const;
export const GET_THEME_LIST_INFO_FAIL = 'themeListInfo/GET_THEME_LIST_INFO_FAIL' as const;

// get more themeListInfo action types
export const GET_THEME_LIST_INFO_MORE_START = 'themeListInfo/GET_THEME_LIST_INFO_MORE_START' as const;
export const GET_THEME_LIST_INFO_MORE_SUCCESS = 'themeListInfo/GET_THEME_LIST_INFO_MORE_SUCCESS' as const;
export const GET_THEME_LIST_INFO_MORE_FAIL = 'themeListInfo/GET_THEME_LIST_INFO_MORE_FAIL' as const;

// get searchedThemeListInfo action types
export const GET_SEARCHED_THEME_LIST_INFO_START = 'searchedThemeListInfo/GET_SEARCHED_THEME_LIST_INFO_START' as const;
export const GET_SEARCHED_THEME_LIST_INFO_SUCCESS =
  'searchedThemeListInfo/GET_SEARCHED_THEME_LIST_INFO_SUCCESS' as const;
export const GET_SEARCHED_THEME_LIST_INFO_FAIL = 'searchedThemeListInfo/GET_SEARCHED_THEME_LIST_INFO_FAIL' as const;
export const GET_BLANK_SEARCHED_THEME_LIST_INFO_SUCCESS =
  'searchedThemeListInfo/GET_BLANK_SEARCHED_THEME_LIST_INFO_SUCCESS' as const;

// get more searchedThemeListInfo action types
export const GET_SEARCHED_THEME_LIST_INFO_MORE_START =
  'searchedThemeListInfo/GET_SEARCHED_THEME_LIST_INFO_MORE_START' as const;
export const GET_SEARCHED_THEME_LIST_INFO_MORE_SUCCESS =
  'searchedThemeListInfo/GET_SEARCHED_THEME_LIST_INFO_MORE_SUCCESS' as const;
export const GET_SEARCHED_THEME_LIST_INFO_MORE_FAIL =
  'searchedThemeListInfo/GET_SEARCHED_THEME_LIST_INFO_MORE_FAIL' as const;

// get popularSearchKeywordListInfo action types
export const GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_START =
  'popularSearchKeywordListInfo/GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_START' as const;
export const GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_SUCCESS =
  'popularSearchKeywordListInfo/GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_SUCCESS' as const;
export const GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_FAIL =
  'popularSearchKeywordListInfo/GET_POPULAR_SEARCH_KEYWORD_LIST_INFO_FAIL' as const;
