import { GetLanguageTypeActionsType } from 'modules/actions/langaugeType';
import { LanguageType } from 'scheme/theme/themeInfo';
import { GET_LANGUAGE_TYPE } from 'modules/types';
import { parseLanguageType } from 'utils/parseLanguageType';

const languageTypeSet = (browserLanguageType: string) => {
  const parsedLanguageType = parseLanguageType(browserLanguageType);
  if (parsedLanguageType === 'ko') {
    return LanguageType.KO;
  } else {
    return LanguageType.EN;
  }
};

export type LanguageTypeState = {
  languageType: LanguageType;
};

const initialState: LanguageTypeState = {
  languageType: languageTypeSet(navigator.language),
};

// 변화를 일으키는 함수(reducer)
// 상태와 액션을 받아서 액션의 타입마다 각기 다른 상태 변화를 일으킴
export default function languageTypeReducer(
  state: LanguageTypeState = initialState,
  action: GetLanguageTypeActionsType,
): LanguageTypeState {
  switch (action.type) {
    case GET_LANGUAGE_TYPE:
      return { ...state, languageType: action.payload };
    default:
      return state;
  }
}
