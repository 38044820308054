import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'modules/reducers';
import Layout from 'layout/Layout';
import ThemeName from 'components/ThemeInfoPage/ThemeName';
import { useState, useEffect } from 'react';
import ReviewList from 'components/ThemeInfoPage/ReviewList';
import FixedBottomBar from 'components/ThemeInfoPage/FixedBottomBar';
import FixedTopBar from 'components/ThemeInfoPage/FixedTopBar';
import { getEventReviewListMoreStart, getEventReviewListStart } from 'modules/actions/event/eventReviewList';
import { getEventInfoStart } from 'modules/actions/event/eventInfo';
import ImageSlider from 'components/EventInfoPage/ImageSlider';
import PageHelmet from 'components/PageHelmet';
import { parseBannerUrl } from 'utils/parseBannerUrl';
import Loading from 'components/Loading';
import IntroduceVideo from 'components/IntroduceVideo';

export default function EventInfoPageTest() {
  const dispatch = useDispatch();
  const eventId = useParams<{ themeId?: string }>();
  const [eventReviewStart, setEventReviewStart] = useState<number>(0);
  const navigate = useNavigate();
  // rootReducer의 state들 중 특정 reducer에 속하는 특정 state를 가져와서 변수에 할당
  const languageType = useSelector((state: RootState) => state.languageTypeReducer.languageType);
  const eventInfoData = useSelector((state: RootState) => state.eventInfoReducer.eventInfo);
  const eventInfoLoading = useSelector((state: RootState) => state.eventInfoReducer.loading);
  const webStoreShareLinkData = useSelector((state: RootState) => state.eventInfoReducer.webStoreShareLink);
  const appStoreShareLinkData = useSelector((state: RootState) => state.eventInfoReducer.appStoreShareLink);
  const eventReviewListData = useSelector((state: RootState) => state.eventReviewListReducer.eventReviewList);
  const eventReviewListCountData = useSelector((state: RootState) => state.eventReviewListReducer.eventReviewListCount);

  useEffect(() => {
    // url에서 themeId를 추출해와서 getEventInfoStart(), getEventReviewListStart() 실행
    // 이벤트 info data 및 이벤트 review list data fetch
    dispatch(getEventInfoStart(eventId.themeId, languageType));
    dispatch(getEventReviewListStart(eventId.themeId, languageType, 0, 20));
  }, [navigate, languageType]);

  // eventReviewStart(리뷰 목록 get api 호출 시 start parameter에 해당하는 수)가 바뀔 때마다 getReviewListMoreStart 액션 dispatch
  useEffect(() => {
    if (eventReviewStart > 0) {
      dispatch(getEventReviewListMoreStart(eventId.themeId, languageType, eventReviewStart, 20));
    }
  }, [eventReviewStart]);

  const sliderSettings = {
    customPaging: function () {
      return <CustomDot></CustomDot>;
    },
    dots: true,
    dotsClass: 'slick-dots custom-dots',
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <Layout>
      {eventInfoLoading ? (
        <Loading color={theme.color.allMainColor} height="10%" width="10%" />
      ) : (
        <>
          <PageHelmet
            title={eventInfoData.name}
            description={eventInfoData.title}
            image={eventInfoData.images?.[0]?.imageUrl}
            languageType={languageType}
          />
          <EventInfoPageContainer>
            <FixedTopBar languageType={languageType} />
            <ImageSlider images={eventInfoData.images} sliderSettings={sliderSettings} />
            <UpperContainer>
              <ThemeName themeName={eventInfoData.name} shareLink={webStoreShareLinkData} />
              <EventTitle>{eventInfoData.title}</EventTitle>
              <EventContent>{eventInfoData.content}</EventContent>
            </UpperContainer>
            {/* 배너 이미지 삭제 <a href={parseBannerUrl(eventInfoData.bannerEvent)}>
              <BannerImage src={eventInfoData.bannerImageUrl} />
            </a> */}
            <ins
              className="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-format="fluid"
              data-ad-layout-key="-fb+5w+4e-db+86"
              data-ad-client="ca-pub-3725138711460599"
              data-ad-slot="2875485323"
            ></ins>

            {/* <IntroduceVideo /> */}
            <FixedBottomBar
              paymentType={'event'}
              price={0}
              shareLink={appStoreShareLinkData}
              themeId={eventInfoData.eventId}
            />
            <ReviewList
              paymentType={'event'}
              reviewList={eventReviewListData}
              reviewListCount={eventReviewListCountData}
              setReviewStart={setEventReviewStart}
            />
          </EventInfoPageContainer>
        </>
      )}
    </Layout>
  );
}

const EventInfoPageContainer = styled.div`
  padding: ${theme.layout.fixedBottomBarHeight} 0;
`;

const UpperContainer = styled.div`
  padding: 0 16px;
  padding-top: 3px;
`;

const EventTitle = styled.div`
  font-size: 1.4rem;
  margin-top: 40px;
  font-weight: 700;
  line-height: 24px;
  color: ${theme.color.allTitleGray};
`;

const EventContent = styled.div`
  font-size: 1.4rem;
  margin-top: 8px;
  font-weight: 400;
  line-height: 24px;
  color: ${theme.color.allBodyGray};
  white-space: pre-wrap;
`;

const BannerImage = styled.img`
  margin-top: 50px;
  width: 100%;
`;

export const CustomDot = styled.li`
  background-color: ${theme.color.allDividerGray};
  border-radius: 50%;
`;
