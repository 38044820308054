import * as type from 'modules/types';
import { AxiosError } from 'axios';
import { LanguageType } from 'scheme/theme/themeInfo';
import { ISuggestThemeInfo } from 'scheme/main/suggestThemeInfo';

export const getTodayThemeInfoStart = (languageType: LanguageType | undefined) => ({
  type: type.GET_TODAY_THEME_INFO_START,
  languageType,
});

export const getTodayThemeInfoSuccess = (todayThemeInfo: ISuggestThemeInfo[]) => ({
  type: type.GET_TODAY_THEME_INFO_SUCCESS,
  todayThemeInfo,
});

export const getTodayThemeInfoError = (error: AxiosError) => ({
  type: type.GET_TODAY_THEME_INFO_FAIL,
  payload: error,
});

export type GetTodayThemeInfoActionsType =
  | ReturnType<typeof getTodayThemeInfoStart>
  | ReturnType<typeof getTodayThemeInfoSuccess>
  | ReturnType<typeof getTodayThemeInfoError>;
