import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
import mixin from 'styles/mixin';
import { useNavigate } from 'react-router-dom';
import ThemeListCategoryTab from './ThemeListCategoryTab';
import { useTranslation } from 'react-i18next';
import { IThemeListInfo } from 'scheme/main/themeListInfo';
import { useEffect, useRef } from 'react';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import Themes from 'components/Themes';

interface IThemeListProps {
  themeListInfo: IThemeListInfo[];
  themeListCategoryInfo: string[];
  selectedCategory: string;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
  setThemeListInfoStart: React.Dispatch<React.SetStateAction<number>>;
  themeListInfoCount: number;
  themeListInfoFetchMore: boolean;
  themeListScrollTop: React.RefObject<HTMLDivElement>;
}

export default function ThemeList({
  themeListInfo,
  themeListCategoryInfo,
  selectedCategory,
  setSelectedCategory,
  setThemeListInfoStart,
  themeListInfoCount,
  themeListScrollTop,
}: IThemeListProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const intersectRef = useRef<HTMLDivElement>(null);
  const { isVisible } = useIntersectionObserver(intersectRef, {
    root: null,
    rootMargin: '50px',
    threshold: 0.01,
  });

  useEffect(() => {
    // 지정된 요소가 root(null이면 기본값인 브라우저 뷰포트)와 교차하며
    // themeList 내 theme 개수가 0개 이상이면 themeListInfoStart에 20 더하기
    // 20을 더함으로써 data fetch 실행
    if (isVisible && themeListInfoCount > 0 && selectedCategory) {
      setThemeListInfoStart((prev) => {
        return prev + 20;
      });
    }
  }, [isVisible]);

  return (
    <ThemeListContainer>
      <div ref={themeListScrollTop}></div>

      <ThemeListTitle>{t('themeListTitle')}</ThemeListTitle>
      <ThemeListCategoryTab
        themeListCategoryInfo={themeListCategoryInfo}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <ThemesContainer>
        <Themes themesInfoData={themeListInfo} type="mainPageThemeList" />
      </ThemesContainer>
      {themeListInfoCount > 0 && <div ref={intersectRef}></div>}
    </ThemeListContainer>
  );
}

const ThemeListContainer = styled.div`
  margin-top: 44px;
`;

const ThemeListTitle = styled.div`
  ${mixin.h3} padding: 0 16px;
`;

const ThemesContainer = styled.div`
  padding: 0 16px;
`;
