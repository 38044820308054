import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import LiveThemeServiceInfoImage from 'assets/images/img_liveTheme_service_introduce.gif';
import LiveThemeServiceInfoEnImage from 'assets/images/img_liveTheme_service_introduce_en.gif';
import DesignThemeServiceInfoImage from 'assets/images/img_designTheme_service_introduce.gif';
import DesignThemeServiceInfoEnImage from 'assets/images/img_designTheme_service_introduce_en.gif';
import ServiceIntroduceImage from 'assets/images/img_service_introduce.png';
import ServiceIntroduceEnImage from 'assets/images/img_service_introduce_eng.png';
import i18n from 'i18n';
import { parseLanguageType } from 'utils/parseLanguageType';

interface IIntroduceImageProps {
  isLiveTheme: boolean;
  languageType: string;
}

export default function IntroduceImage({ isLiveTheme, languageType }: IIntroduceImageProps) {
  const { t } = useTranslation();

  const liveThemeImageLocale = () => {
    switch (languageType) {
      case 'en':
        return <Image src={LiveThemeServiceInfoEnImage} alt="" />;
      default:
        return <Image src={LiveThemeServiceInfoImage} alt="" />;
    }
  };

  const designThemeImageLocale = () => {
    switch (languageType) {
      case 'en':
        return <Image src={DesignThemeServiceInfoEnImage} alt="" />;
      default:
        return <Image src={DesignThemeServiceInfoImage} alt="" />;
    }
  };

  const serviceIntroduceImageLocale = () => {
    switch (languageType) {
      case 'en':
        return ServiceIntroduceEnImage;
      default:
        return ServiceIntroduceImage;
    }
  };

  return (
    <ImageContainer>
      <ServiceInfoTextContainer>
        <img src={serviceIntroduceImageLocale()} alt="" />
        {/* <ServiceInfoText>{t('serviceIntroduce1')}</ServiceInfoText>
        <ServiceInfoText>{t('serviceIntroduce2')}</ServiceInfoText> */}
      </ServiceInfoTextContainer>
      {isLiveTheme ? liveThemeImageLocale() : designThemeImageLocale()}
    </ImageContainer>
  );
}

const ImageContainer = styled.div`
  margin-top: 4rem;
`;

const ServiceInfoTextContainer = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  padding: 0 1.6rem;
`;

const Image = styled.img``;

const ServiceInfoText = styled.div``;
