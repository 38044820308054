import { call, put, takeEvery } from 'redux-saga/effects';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { GET_KEYWORD_LIST_START, GET_KEYWORD_LIST_SUCCESS, GET_KEYWORD_LIST_FAIL } from 'modules/types';

// 테마의 키워드 리스트를 fetch하기 위한 saga
// 현재 미사용 중
function fetchKeywordList() {
  const keywordList = axios.get(`https://s3.plkey.app/app/keyword-map.json`);
  return keywordList;
}

// action을 인자로 받아 비동기 data fetch 처리하는 generator function
// 액션이 지닌 값을 가져와서 사용하기 위해 액션을 인자로 받아옴
function* getKeywordList() {
  try {
    // call을 통해 인자로 전달한 함수 호출 후 결과 반환될 때까지 대기
    const keywordList: AxiosResponse = yield call(fetchKeywordList);
    // 성공 액션 디스패치
    yield put({
      type: GET_KEYWORD_LIST_SUCCESS,
      payload: keywordList.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    // 실패 액션 디스패치
    yield put({
      type: GET_KEYWORD_LIST_FAIL,
      payload: error,
    });
  }
}

// 이 함수 내부에 모든 saga들을 합칠 수 있음
export function* keywordListSaga() {
  yield takeEvery(GET_KEYWORD_LIST_START, getKeywordList);
}

export default keywordListSaga;
