import { LanguageType } from 'scheme/theme/themeInfo';
import * as type from '../types';

// 액션을 만드는 함수(action creator)
// 파라미터 받아와서 액션 객체 형태로 반환
export const getLanguageType = (browserLanguageType: LanguageType) => ({
  type: type.GET_LANGUAGE_TYPE,
  payload: browserLanguageType,
});

export type GetLanguageTypeActionsType = ReturnType<typeof getLanguageType>;
