import { Helmet } from 'react-helmet';
import { LanguageType } from 'scheme/theme/themeInfo';
import OGImage from 'assets/icons/ic_all_playKeyboard.png';

interface ICustomPageHelmet {
  languageType: LanguageType;
  title: string;
  englishTitle: string;
}
export default function CustomPageHelmet({ languageType, title, englishTitle }: ICustomPageHelmet) {
  const titleText = () => {
    switch (languageType) {
      case 'en':
        return englishTitle;
      default:
        return title;
    }
  };

  return (
    <Helmet>
      <title>{titleText()}</title>
      {/* <meta name="description" content="수백가지 취향저격 키보드 테마" />
      <meta name="og:description" content="수백가지 취향저격 키보드 테마" /> */}
      <meta name="image" content={OGImage} />
      <meta name="og:image" content={OGImage} />
    </Helmet>
  );
}
