import styled from 'styled-components/macro';
import mixin from 'styles/mixin';
import { useTranslation } from 'react-i18next';
import { ISuggestThemeInfo } from 'scheme/main/suggestThemeInfo';
import SuggestTheme from './SuggestTheme';

interface ITodayThemeProps {
  todayThemeInfo: ISuggestThemeInfo[];
}

export default function TodayTheme({ todayThemeInfo }: ITodayThemeProps) {
  const { t } = useTranslation();

  return (
    <TodayThemesContainer>
      <TodayThemesTitle>{t('todayThemeTitle')}</TodayThemesTitle>
      <SuggestTheme suggestThemeInfo={todayThemeInfo} type="today" />
    </TodayThemesContainer>
  );
}
const TodayThemesContainer = styled.div`
  margin-top: 44px;
`;

const TodayThemesTitle = styled.div`
  ${mixin.h3}
  padding: 0 16px;
`;
