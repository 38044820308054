import SearchImage from 'assets/icons/ic_nav_store_search.png';
import GoBackImage from 'assets/icons/ic_toolbar_back.png';
import GoStoreImage from 'assets/icons/ic_toolbar_store.png';
import LogoImage from 'assets/images/img_playkeyboard_logo.png';
import { Fragment } from 'react';
import { event } from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { LanguageType } from 'scheme/theme/themeInfo';
import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
interface IFixedTopBar {
  languageType: LanguageType;
  type?: string;
}

export default function FixedTopBar({ languageType, type }: IFixedTopBar) {
  const navigate = useNavigate();

  const handleClickSearch = () => {
    event({
      category: 'user_store_search_click',
      action: 'Click user_store_search',
    });

    navigate('/search');
  };

  const goBack = () => {
    if (window.history.length < 2) {
      return navigate('/');
    }
    navigate(-1);
  };

  const goHome = () => {
    return navigate('/');
  };

  return (
    <FixedTopBarContainer>
      {type === 'main' ? (
        <>
          <LogoContainer href="https://plkey.app" target="_blank">
            <Logo src={LogoImage} alt="" />
          </LogoContainer>
          <SearchButton src={SearchImage} alt="" onClick={handleClickSearch} />
        </>
      ) : (
        <Fragment>
          <GoBackButton src={GoBackImage} alt="" onClick={goBack} />
          <GoBackButton src={GoStoreImage} alt="" onClick={goHome} />
        </Fragment>
      )}
    </FixedTopBarContainer>
  );
}

const FixedTopBarContainer = styled.div`
  background-color: ${theme.color.basicWhite};
  position: fixed;
  padding: 12px 16px;
  top: -1px;
  width: 100%;
  max-width: ${theme.layout.mainWidth};
  display: flex;
  height: ${theme.layout.fixedTopBarHeight};
  justify-content: space-between;
  z-index: 190;
`;

const LogoContainer = styled.a`
  margin: auto 0;
`;

const Logo = styled.img`
  margin-top: 8px;
  max-width: 136px;
  cursor: pointer;
`;
const GoBackButton = styled.img`
  width: 5%;
  margin: auto 0;
  cursor: pointer;
`;

const SearchButton = styled.img`
  width: 4%;
  min-width: 1.8rem;
  margin: auto 0;
  cursor: pointer;
`;
