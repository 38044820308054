// Local storage로부터 data 가져오는 함수
const getDataFromLocalStorage = (key: string) => {
  const storedData = localStorage.getItem(key);
  if (storedData) {
    return JSON.parse(storedData || '');
  }
  return [];
};

export default getDataFromLocalStorage;
