import styled from 'styled-components/macro';
import SearchImage from 'assets/icons/ic_nav_search_search.png';
import CloseImage from 'assets/icons/ic_nav_search_close.png';
import { theme } from 'styles/theme';

interface ISearchInput {
  name?: string;
  placeholder?: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  handleEnterPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleResetInput: () => void;
}
interface ICloseIcon {
  isVisible: string | undefined;
}

export default function SearchInput({
  name,
  placeholder,
  handleInputChange,
  value,
  handleResetInput,
  handleEnterPress,
}: ISearchInput) {
  return (
    <InputContainer>
      <SearchIcon src={SearchImage} />
      <InputBox
        name={name}
        placeholder={placeholder}
        onChange={handleInputChange}
        value={value}
        onKeyPress={handleEnterPress}
      />
      <CloseIcon src={CloseImage} isVisible={value} onClick={handleResetInput} />
    </InputContainer>
  );
}

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.3rem;
  background: ${theme.color.allBtnGray};
  border-radius: 50px;
  transition: all 0.3s ease 0s;
  height: 4rem;
  width: 100%;
`;

const SearchIcon = styled.img`
  display: flex;
  width: 27px;
  align-items: center;
  justify-content: flex-start;
`;

const CloseIcon = styled(SearchIcon)<ICloseIcon>`
  ${({ isVisible }) =>
    isVisible &&
    `visibility:visible !important;
  `}
  visibility:hidden;
  cursor: pointer;
`;

const InputBox = styled.input`
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  &::placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: ${theme.color.allSubDarkGray};
  }
`;
