import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
import mixin from 'styles/mixin';
import { useNavigate } from 'react-router-dom';

interface IHashtagsProps {
  hashtags: string[];
}

export default function Hashtags({ hashtags }: IHashtagsProps) {
  const navigate = useNavigate();
  const handleHashtagClick = (hashtag: string) => {
    navigate('/search', { state: hashtag });
  };
  return (
    <HashtagContainer>
      {/* <HashtagTitle>{t('tagTitle')}</HashtagTitle> */}
      <HashtagWrapper>
        {hashtags.map((item: string, index: number) => (
          <HashtagBox key={index} onClick={() => handleHashtagClick(item)}>
            {item}
          </HashtagBox>
        ))}
      </HashtagWrapper>
    </HashtagContainer>
  );
}

const HashtagContainer = styled.div`
  margin-top: 2rem;
`;

const HashtagTitle = styled.div`
  ${mixin.h3}
`;

const HashtagWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;
  width: 100%;
`;

const HashtagBox = styled.div`
  background-color: ${theme.color.allBtnGray};
  border-radius: 18px;
  padding: 4px 10px;
  margin: 0 0.6rem 0.9rem 0;
  font-size: 1.4rem;
  cursor: pointer;
  display: inline-block;
`;
