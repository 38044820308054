import { IFigure, IKeywordList } from 'scheme/theme/themeInfo';
import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
import { useSelector } from 'react-redux';
import { RootState } from 'modules/reducers';
import figureKeyword from 'data/figureKeyword.json';

interface IFigureBottomSheetProps {
  figure: IFigure;
  handleSwipeDownToZero: () => void;
  handleTouchLeave: () => void;
  handleTouchMove: (e: React.TouchEvent<HTMLDivElement>) => void;
  handleTouchDown: (e: React.TouchEvent<HTMLDivElement>) => void;
  swipeDownPercent: number;
  swipedRef: React.RefObject<HTMLDivElement>;
  // fetchedKeywordList: IKeywordList;
}

interface IModal {
  swipeDownPercent: number;
}

interface IDimmer {
  swipeDownPercent: number;
}

export default function FigureBottomSheet({
  figure,
  handleSwipeDownToZero,
  handleTouchLeave,
  handleTouchMove,
  handleTouchDown,
  swipeDownPercent,
  swipedRef,
}: IFigureBottomSheetProps) {
  const languageType = useSelector((state: RootState) => state.languageTypeReducer.languageType);
  const figureKeywordData = JSON.parse(JSON.stringify(figureKeyword.data[0][languageType]));

  return (
    <Dimmer onClick={handleSwipeDownToZero} swipeDownPercent={swipeDownPercent}>
      <Modal
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        ref={swipedRef}
        onTouchStart={handleTouchDown}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchLeave}
        swipeDownPercent={swipeDownPercent}
      >
        <FigureImageContainer>
          <FigureImage src={figure.imageUrl} />
        </FigureImageContainer>
        <FigureKeywordContainer>
          {figureKeywordData[figure.keyword] &&
            figureKeywordData[figure.keyword].map((keyword: string, index: number) => (
              <FigureKeyword key={index}>{keyword}</FigureKeyword>
            ))}
        </FigureKeywordContainer>
      </Modal>
    </Dimmer>
  );
}

const Dimmer = styled.div<IDimmer>`
  ${({ swipeDownPercent }) =>
    swipeDownPercent >= 100 &&
    `
    visibility: hidden;
  `}
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: rgba(60, 60, 60, 40%);
`;

const Modal = styled.div<IModal>`
  display: inline-block;
  padding: 20px 16px 65px;
  transform: translate(-50%, ${(props) => props.swipeDownPercent}%);
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  max-width: ${theme.layout.mainWidth};
  background-color: #fff;
  border-radius: 15px 15px 0 0;
  touch-action: none;
  transition: 0.15s ease-out;
`;

const FigureImage = styled.img`
  width: 45%;
`;
const FigureImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const FigureKeywordContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
`;

const FigureKeyword = styled.div`
  padding: 4px 10px;
  background-color: ${theme.color.allBtnGray};
  border-radius: 18px;
  margin: 4px;
  font-size: 1.4rem;
`;
