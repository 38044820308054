import * as type from 'modules/types';
import { AxiosError } from 'axios';
import { LanguageType } from 'scheme/theme/themeInfo';

export const getThemeListCategoryInfoStart = (languageType: LanguageType | undefined) => ({
  type: type.GET_THEME_LIST_CATEGORY_INFO_START,
  languageType,
});

export const getThemeListCategoryInfoSuccess = (themeListCategoryInfo: string[]) => ({
  type: type.GET_THEME_LIST_CATEGORY_INFO_SUCCESS,
  themeListCategoryInfo,
});

export const getThemeListCategoryInfoError = (error: AxiosError) => ({
  type: type.GET_THEME_LIST_CATEGORY_INFO_FAIL,
  payload: error,
});

export type GetThemeListCategoryInfoActionsType =
  | ReturnType<typeof getThemeListCategoryInfoStart>
  | ReturnType<typeof getThemeListCategoryInfoSuccess>
  | ReturnType<typeof getThemeListCategoryInfoError>;
