import * as type from 'modules/types';
import { AxiosError } from 'axios';
import { LanguageType } from 'scheme/theme/themeInfo';
import { IThemeListInfo } from 'scheme/main/themeListInfo';

export const getThemeListInfoStart = (
  languageType: LanguageType | undefined,
  category: string,
  start: number,
  count: number,
) => ({
  type: type.GET_THEME_LIST_INFO_START,
  languageType,
  category,
  start,
  count,
});

export const getThemeListInfoSuccess = (themeListInfo: IThemeListInfo[]) => ({
  type: type.GET_THEME_LIST_INFO_SUCCESS,
  themeListInfo,
});

export const getThemeListInfoError = (error: AxiosError) => ({
  type: type.GET_THEME_LIST_INFO_FAIL,
  payload: error,
});

export const getThemeListInfoMoreStart = (
  languageType: LanguageType,
  category: string,
  start: number,
  count: number,
) => ({
  type: type.GET_THEME_LIST_INFO_MORE_START,
  category,
  languageType,
  start,
  count,
});

export const getThemeListInfoMoreSuccess = (moreThemeListInfo: IThemeListInfo[]) => ({
  type: type.GET_THEME_LIST_INFO_MORE_SUCCESS,
  payload: moreThemeListInfo,
});

export const getThemeListInfoMoreError = (error: AxiosError) => ({
  type: type.GET_THEME_LIST_INFO_MORE_FAIL,
  payload: error,
});

export type GetThemeListInfoActionsType =
  | ReturnType<typeof getThemeListInfoStart>
  | ReturnType<typeof getThemeListInfoSuccess>
  | ReturnType<typeof getThemeListInfoError>
  | ReturnType<typeof getThemeListInfoMoreStart>
  | ReturnType<typeof getThemeListInfoMoreSuccess>
  | ReturnType<typeof getThemeListInfoMoreError>;
