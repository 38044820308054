import { call, put, takeEvery } from 'redux-saga/effects';
import axios, { AxiosResponse, AxiosError } from 'axios';
import {
  GET_POPULAR_THEME_INFO_START,
  GET_POPULAR_THEME_INFO_SUCCESS,
  GET_POPULAR_THEME_INFO_FAIL,
} from 'modules/types';
import { LanguageType } from 'scheme/theme/themeInfo';

interface IParams {
  languageType: LanguageType;
  type: string;
}

function fetchPopularThemeInfo(languageType: LanguageType) {
  const popularThemeInfo = axios.get(`/theme/popular`, {
    headers: { 'content-language': languageType },
  });
  return popularThemeInfo;
}

function* getPopularThemeInfo({ languageType }: IParams) {
  try {
    const popularThemeInfo: AxiosResponse = yield call(fetchPopularThemeInfo, languageType);

    yield put({
      type: GET_POPULAR_THEME_INFO_SUCCESS,
      popularThemeInfo: popularThemeInfo.data.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    yield put({
      type: GET_POPULAR_THEME_INFO_FAIL,
      payload: error,
    });
  }
}

export function* popularThemeInfoSaga() {
  yield takeEvery(GET_POPULAR_THEME_INFO_START, getPopularThemeInfo);
}

export default popularThemeInfoSaga;
