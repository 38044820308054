import { useEffect, useState } from 'react';

import EventIconImage from 'assets/icons/ic_all_event.png';
import CloseIconImage from 'assets/icons/ic_close.png';
import QRCodeImage from 'assets/images/img_playkeyboard_qrcode.png';

import { useModal } from 'hooks/useModal';
import { event } from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
import { isDesktop } from 'utils/isDesktop';
import CtaButton from './CtaButton';

interface IFixedBottomBar {
  paymentType: string;
  price: number;
  shareLink: string;
  themeId: string;
}

export default function FixedBottomBar({ paymentType, price, shareLink, themeId }: IFixedBottomBar) {
  const { t } = useTranslation();
  const { handleModalOpen, handleModalClose, isModalOpen } = useModal();
  const [textVisible, setTextVisible] = useState<boolean>(true);
  const { hash } = useLocation();

  const money = (currency: string, price: number) => {
    switch (currency) {
      case '$':
        if (price === 0) {
          return 0;
        } else {
          return price * 0.2 - 0.01;
        }
      default:
        return price * 220;
    }
  };

  useEffect(() => {
    if (hash !== '#QR') return;
    handleModalOpen();
  }, [hash]);

  const priceInformation = () => {
    switch (paymentType) {
      case 'brand':
        return (
          <PriceContainer>
            <EventPriceContainer>
              <EventIcon src={EventIconImage} alt="" />
              <EventPrice>{t('free')}</EventPrice>
            </EventPriceContainer>
            <EventMoneyPrice>{t('participateAndGetTheme')}</EventMoneyPrice>
          </PriceContainer>
        );
      case 'event':
        return;
      default:
        return <GoToAppGuideText>{textVisible && t('tryThisKeyboard')}</GoToAppGuideText>;
    }
  };

  const handleCtaButtonClick = () => {
    if (!isDesktop()) {
      window.open(shareLink);
    } else {
      handleModalOpen();
    }
    event({
      category: 'click_goto_app',
      action: 'Click goto_app',
      label: themeId,
    });
  };

  window.onresize = () => {
    if (380 < screen.width) {
      setTextVisible(true);
    } else {
      setTextVisible(false);
    }
  };

  let qr = QRCodeImage;

  const gt4Themes = ['KAKAOGT-K-0', 'KAKAOGT-K-1', 'KAKAOGT-L-1', 'KAKAOGT-L-2', 'KAKAOGT-L-3', 'KAKAOGT-L-4', 'KAKAOGT-L-5', 'KAKAOGT-M-1', 'KAKAOGT-M-2', 'KAKAOGT-M-3'];
  const index = gt4Themes.indexOf(themeId);
  if (index !== -1) {
    qr = require('assets/images/qr/'+gt4Themes[index]+'.png');
  }

  return (
    <>
      <FixedBottomBarContainer>
        {priceInformation()}
        <CtaButton handleCtaButtonClick={handleCtaButtonClick} paymentType={paymentType} shareLink={shareLink} />
      </FixedBottomBarContainer>
      {isModalOpen && (
        <Dimmer onClick={handleModalClose}>
          <ModalContainer onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
            <CloseButtonContainer>
              <CloseButton src={CloseIconImage} alt="" onClick={handleModalClose} />
            </CloseButtonContainer>
            <QRCodeContainer>
              <QRCode src={qr} alt="" />
            </QRCodeContainer>
            <ModalBodyContainer>
              <ModalBody>{t('qrCodeText1')}</ModalBody>
              <ModalBody>{t('qrCodeText2')}</ModalBody>
            </ModalBodyContainer>
          </ModalContainer>
        </Dimmer>
      )}
    </>
  );
}

const FixedBottomBarContainer = styled.div`
    background-color: ${theme.color.basicWhite};
    position: fixed;
    bottom: -1px;
    width: 100%;
    max-width: ${theme.layout.mainWidth};
    display: flex;
    height: ${theme.layout.fixedBottomBarHeight};
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    box-shadow: 0px -5px 8px -8px rgba(0, 0, 0, 0.25);
    z-index: 100;
`;

const PriceContainer = styled.div`
    margin: auto 0;
`;

const GoToAppGuideText = styled.div`
    font-size: 1.2rem;
`;

const GemPriceContainer = styled.div`
    display: flex;
    align-items: center;
`;

const GemPrice = styled.div`
    font-size: 1.4rem;
    color: ${theme.color.gemTxtColor};
`;

const GemIcon = styled.img`
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 5px;
`;

const CandyPriceContainer = styled(GemPriceContainer)``;
const EventPriceContainer = styled(GemPriceContainer)``;

const EventPrice = styled.div`
    font-size: 1.6rem;
    color: ${theme.color.eventTxtColor};
    font-weight: 700;
`;

const EventIcon = styled.img`
    width: 16px;
    height: 16px;
    margin: 1px 5px 0 0;
`;

const EventMoneyPrice = styled.div`
    font-size: 1.2rem;
    color: ${theme.color.allSubLightGray};
    margin-top: 2px;
`;

const CandyPrice = styled(GemPrice)`
    font-size: 1.5rem;
    font-weight: 700;
    color: ${theme.color.candyTxtColor};
`;

const CandyIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 5px;
`;

const MoneyPrice = styled.div`
    color: ${theme.color.allMainColor};
    @media screen and ${theme.device.mobile} {
        font-size: 1.8rem;
        font-weight: 700;
    }
    font-size: 15.5px;
    font-weight: 700;
`;

const Dimmer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    background-color: rgba(60, 60, 60, 40%);
`;

const ModalContainer = styled.div`
    display: inline-block;
    padding: 1.5rem 1.5rem 2.8rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    max-width: ${theme.layout.mainWidth};
    background-color: #fff;
    border-radius: 20px;
    touch-action: none;
    transition: 0.15s ease-out;
    max-width: 400px;
`;

const ModalBodyContainer = styled.div`
    margin-top: 1.5rem;
    font-weight: 500;
`;

const ModalBody = styled.div`
    font-size: 1.4rem;
    text-align: center;
`;

const QRCodeContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const CloseButtonContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
`;

const CloseButton = styled.img`
    width: 3.5%;
    margin: 1rem;
    cursor: pointer;
`;

const QRCode = styled.img`
    width: 50%;
    margin-top: 1.5rem;
`;
