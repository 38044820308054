import styled from 'styled-components/macro';
import ReactLoading from 'react-loading';

interface ILoading {
  color: string;
  height: string;
  width: string;
}
export default function Loading({ color, height, width }: ILoading) {
  return (
    <LoadingContainer>
      <ReactLoading type="spin" color={color} height={height} width={width} />
    </LoadingContainer>
  );
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
