import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
import { IPopularSearchKeywordListInfo } from 'scheme/search/popularSearchKeywordListInfo';
import { useTranslation } from 'react-i18next';

interface IPopularSearchKeywordList {
  popularSearchKeywordListInfo: IPopularSearchKeywordListInfo[];
  handleSearchKeywordClick: (keyword: string, type: string) => void;
  saveRecentSearchKeyword: (inputValue: string) => void;
}

export default function PopularSearchKeywordList({
  popularSearchKeywordListInfo,
  handleSearchKeywordClick,
  saveRecentSearchKeyword,
}: IPopularSearchKeywordList) {
  const { t } = useTranslation();

  const handlePopularSearchKeywordClick = (keyword: string) => {
    handleSearchKeywordClick(keyword, 'trending');
    saveRecentSearchKeyword(keyword);
  };
  return (
    <PopularSearchKeywordListContainer>
      <PopularSearchKeywordListTitle>{t('popularSearchKeyword')}</PopularSearchKeywordListTitle>
      <PopularSearchKeywordListWrapper>
        {popularSearchKeywordListInfo.map((data: IPopularSearchKeywordListInfo, index: number) => (
          <PopularSearchKeywordBox key={index} onClick={() => handlePopularSearchKeywordClick(data.keyword)}>
            {data.keyword}
          </PopularSearchKeywordBox>
        ))}
      </PopularSearchKeywordListWrapper>
    </PopularSearchKeywordListContainer>
  );
}

const PopularSearchKeywordListContainer = styled.div`
  margin-top: 1.5rem;
`;

const PopularSearchKeywordListTitle = styled.div`
  font-size: 1.4rem;
  color: ${theme.color.allSubLightGray};
  font-weight: 500;
`;

const PopularSearchKeywordListWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;
`;

const PopularSearchKeywordBox = styled.div`
  background-color: ${theme.color.allBtnGray};
  border-radius: 18px;
  padding: 0.5rem 1.3rem;
  margin: 0 0.6rem 1rem 0;
  font-size: 1.5rem;
  color: ${theme.color.allBodyGray};
  font-weight: 500;
  cursor: pointer;
`;
