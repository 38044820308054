import { useState, useEffect } from 'react';
// modal을 열고 닫기 위한 hook
export const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isModalOpen) return;
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, [isModalOpen]);

  const handleModalClose = () => {
    window.location.hash = '';
    setIsModalOpen(false);
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  return { handleModalOpen, handleModalClose, isModalOpen };
};
