import MainPage from 'components/pages/MainPage';
import ThemeInfoPage from 'components/pages/ThemeInfoPage';
import EventInfoPage from 'components/pages/EventInfoPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getLanguageType } from 'modules/actions/langaugeType';
import { LanguageType } from 'scheme/theme/themeInfo';
import ScrollToTop from 'components/ScrollToTop';
import { parseLanguageType } from 'utils/parseLanguageType';
import SearchPage from 'components/pages/SearchPage';
import axios from 'axios';

export default function App() {
  const dispatch = useDispatch();
  // 액션을 dispatch(발생)하는 함수
  // store에서 reducer 함수를 실행시켜 해당 액션에 따른 변화를 통해 새로운 state로 만들기 위함
  const languageTypeSet = (browserLanguageType: string) => {
    const parsedLanguageType = parseLanguageType(browserLanguageType);
    // getLanguageType()이라는 액션 함수에 parsedLanguageType를 payload로 전달하여
    // type이 GET_LANGUAGE_TYPE인 액션을 dispatch 시킴
    if (parsedLanguageType === 'ko') {
      dispatch(getLanguageType(LanguageType.KO));
    } else {
      dispatch(getLanguageType(LanguageType.EN));
    }
  };
  axios.defaults.headers.common['app-version-code'] = '506';

  useEffect(() => {
    // navigator.language를 languageTypeSet() 함수의 인자로 삽입
    languageTypeSet(navigator.language);
  }, []);

  window.addEventListener('contextmenu', (e) => e.preventDefault());

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/theme/:themeId" element={<ThemeInfoPage />} />
        <Route path="/request/:themeId" element={<ThemeInfoPage />} />
        <Route path="/event/:themeId" element={<EventInfoPage />} />
        <Route path="/search" element={<SearchPage />} />
      </Routes>
    </BrowserRouter>
  );
}
