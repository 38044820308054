import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { theme } from 'styles/theme';

interface IRecentSearchKeywordList {
  deleteRecentSearchKeywordList: () => void;
  recentSearchKeywordListInfo: string[];
  handleRecentSearchKeywordClick: (keyword: string, type: string) => void;
}

export default function RecentSearchKeywordList({
  deleteRecentSearchKeywordList,
  recentSearchKeywordListInfo,
  handleRecentSearchKeywordClick,
}: IRecentSearchKeywordList) {
  const { t } = useTranslation();

  return (
    <RecentSearchKeywordListContainer>
      <RecentSearchKeywordListTitleContainer>
        <RecentSearchKeywordListTitle>{t('recentSearchKeyword')}</RecentSearchKeywordListTitle>
        <RecentSearchKeywordListDeleteButton onClick={deleteRecentSearchKeywordList}>
          {t('delete').toUpperCase()}
        </RecentSearchKeywordListDeleteButton>
      </RecentSearchKeywordListTitleContainer>
      <RecentSearchKeywordListWrapper>
        {recentSearchKeywordListInfo.map((keyword: string, index: number) => (
          <RecentSearchKeywordBox key={index} onClick={() => handleRecentSearchKeywordClick(keyword, 'recent')}>
            {keyword}
          </RecentSearchKeywordBox>
        ))}
      </RecentSearchKeywordListWrapper>
    </RecentSearchKeywordListContainer>
  );
}

const RecentSearchKeywordListContainer = styled.div`
  margin: 1.5rem 0 5rem;
`;

const RecentSearchKeywordListTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RecentSearchKeywordListTitle = styled.div`
  font-size: 1.4rem;
  color: ${theme.color.allSubLightGray};
  font-weight: 500;
`;

const RecentSearchKeywordListDeleteButton = styled(RecentSearchKeywordListTitle)`
  cursor: pointer;
`;

const RecentSearchKeywordListWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;
`;

const RecentSearchKeywordBox = styled.div`
  background-color: ${theme.color.allBtnGray};
  border-radius: 18px;
  padding: 0.5rem 1.3rem;
  margin: 0 0.6rem 1rem 0;
  font-size: 1.5rem;
  color: ${theme.color.allBodyGray};
  font-weight: 500;
  cursor: pointer;
`;
