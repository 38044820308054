import { call, put, takeEvery } from 'redux-saga/effects';
import axios, { AxiosResponse, AxiosError } from 'axios';
import {
  GET_SEARCHED_THEME_LIST_INFO_START,
  GET_SEARCHED_THEME_LIST_INFO_SUCCESS,
  GET_SEARCHED_THEME_LIST_INFO_FAIL,
  GET_SEARCHED_THEME_LIST_INFO_MORE_START,
  GET_SEARCHED_THEME_LIST_INFO_MORE_SUCCESS,
  GET_SEARCHED_THEME_LIST_INFO_MORE_FAIL,
} from 'modules/types';
import { LanguageType } from 'scheme/theme/themeInfo';

interface IParams {
  languageType: LanguageType;
  searchKeyword: string;
  start: number;
  count: number;
  type: string;
}

function fetchSearchedThemeListInfo(languageType: LanguageType, searchKeyword: string, start: number, count: number) {
  const searchedThemeListInfo = axios.get(`/theme?search=${searchKeyword}&start=${start}&count=${count}`, {
    headers: { 'content-language': languageType },
  });
  return searchedThemeListInfo;
}

function* getSearchedThemeListInfo({ languageType, searchKeyword, start, count }: IParams) {
  try {
    const searchedThemeListInfo: AxiosResponse = yield call(
      fetchSearchedThemeListInfo,
      languageType,
      searchKeyword,
      start,
      count,
    );

    yield put({
      type: GET_SEARCHED_THEME_LIST_INFO_SUCCESS,
      searchedThemeListInfo: searchedThemeListInfo.data.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    yield put({
      type: GET_SEARCHED_THEME_LIST_INFO_FAIL,
      payload: error,
    });
  }
}

function* getSearchedThemeListInfoMore({ languageType, searchKeyword, start, count }: IParams) {
  try {
    const searchedThemeListInfo: AxiosResponse = yield call(
      fetchSearchedThemeListInfo,
      languageType,
      searchKeyword,
      start,
      count,
    );

    yield put({
      type: GET_SEARCHED_THEME_LIST_INFO_MORE_SUCCESS,
      payload: searchedThemeListInfo.data.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    yield put({
      type: GET_SEARCHED_THEME_LIST_INFO_MORE_FAIL,
      payload: error,
    });
  }
}

export function* searchedThemeListInfoSaga() {
  yield takeEvery(GET_SEARCHED_THEME_LIST_INFO_START, getSearchedThemeListInfo);
  yield takeEvery(GET_SEARCHED_THEME_LIST_INFO_MORE_START, getSearchedThemeListInfoMore);
}

export default searchedThemeListInfoSaga;
