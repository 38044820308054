import styled from 'styled-components/macro';
import { useState } from 'react';
import { IFigure } from 'scheme/theme/themeInfo';
import FigureBottomSheet from 'components/ThemeInfoPage/FigureBottomSheet';
import { useSwipe } from 'hooks/useSwipe';
import { parseLanguageType } from 'utils/parseLanguageType';
import i18n from 'i18n';
import LiveThemeInfoImage1 from 'assets/images/img_liveTheme_introduce_1.gif';
import LiveThemeInfoImage2 from 'assets/images/img_liveTheme_introduce_2.gif';
import LiveThemeInfoEnImage1 from 'assets/images/img_liveTheme_introduce_en_1.gif';
import LiveThemeInfoEnImage2 from 'assets/images/img_liveTheme_introduce_en_2.gif';

interface IFigureProps {
  figures: IFigure[];
  languageType: string;
  // fetchedKeywordList: IKeywordList;
}

export default function Figures({ figures, languageType }: IFigureProps) {
  const [clickedFigure, setClickedFigure] = useState<IFigure>({ keyword: '', text: '', imageUrl: '' });

  const {
    handleShowedUp,
    handleTouchDown,
    handleSwipeDownToZero,
    handleTouchLeave,
    handleTouchMove,
    swipedRef,
    swipeDownPercent,
  } = useSwipe({
    swipingLimit: 75,
  });

  const handleFigureClick = (figure: IFigure) => {
    setClickedFigure(figure);
    handleShowedUp();
  };

  return (
    <FigureContainer>
      <FigureWrapper>
        <FigureBox>
          {figures.map((figure: IFigure, index: number) => (
            <FigureImage alt="" key={index} onClick={() => handleFigureClick(figure)} src={figure.imageUrl} />
          ))}
        </FigureBox>
      </FigureWrapper>
      <FigureBottomSheet
        figure={clickedFigure}
        handleTouchDown={handleTouchDown}
        handleSwipeDownToZero={handleSwipeDownToZero}
        handleTouchLeave={handleTouchLeave}
        handleTouchMove={handleTouchMove}
        swipedRef={swipedRef}
        swipeDownPercent={swipeDownPercent}
      />
      <LiveThemeIntroduceImageContainer>
        {languageType === 'ko' ? (
          <>
            <img src={LiveThemeInfoImage1} alt="" />
            <img src={LiveThemeInfoImage2} alt="" />
          </>
        ) : (
          <>
            <img src={LiveThemeInfoEnImage1} alt="" />
            <img src={LiveThemeInfoEnImage2} alt="" />
          </>
        )}
      </LiveThemeIntroduceImageContainer>
    </FigureContainer>
  );
}

const FigureContainer = styled.div`
  margin-top: 52px;
`;

const FigureWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FigureBox = styled.div`
  padding: 0 1.6rem;
  display: flex;
  flex-wrap: wrap;
`;

const FigureImage = styled.img`
  border-radius: 10px;
  margin: 0 2.6% 3rem;
  width: 28%;
  cursor: pointer;
`;
const LiveThemeIntroduceImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
