import { IThemeInfo, LanguageType } from 'scheme/theme/themeInfo';
import * as type from 'modules/types';
import { AxiosError } from 'axios';

export const getThemeInfoStart = (themeId: string | undefined, languageType: LanguageType | undefined) => ({
  type: type.GET_THEME_INFO_START,
  themeId,
  languageType,
});

export const getThemeInfoSuccess = (themeInfo: IThemeInfo, webStoreShareLink: string, appStoreShareLink: string) => ({
  type: type.GET_THEME_INFO_SUCCESS,
  themeInfo: themeInfo,
  webStoreShareLink,
  appStoreShareLink,
});

export const getThemeInfoError = (error: AxiosError) => ({
  type: type.GET_THEME_INFO_FAIL,
  payload: error,
});

export type GetThemeInfoActionsType =
  | ReturnType<typeof getThemeInfoStart>
  | ReturnType<typeof getThemeInfoSuccess>
  | ReturnType<typeof getThemeInfoError>;
