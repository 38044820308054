export enum LanguageType {
  EN = 'en',
  KO = 'ko',
}

export interface IThemeInfo {
  bannerEvent: string;
  bannerImageUrl: string;
  brandAction: string;
  brandDescription: string;
  brandImageUrl: string;
  brandType: string;
  category: string[];
  createdAt: string;
  creator: string;
  designStoryContent: string;
  designStoryTitle: string;
  downloadKey: string;
  downloads: number;
  figure: IFigure[];
  hashtag: string[];
  hide: boolean;
  imageUrl: string;
  images: IImages[];
  isShowDownloads?: boolean;
  isBuy: boolean;
  isLiveTheme: boolean;
  isOnlyStore: boolean;
  lang: string;
  localDownloadBuffer: number;
  localDownloads: number;
  maximumIOSVersion: number;
  maximumVersion: number;
  minimulIOSVersion: number;
  minimulVersion: number;
  name: string;
  officialRecommendPriorities: null;
  officialRecommendPriority: number;
  paymentType: string;
  platform: string[];
  price: number;
  priority: number;
  randomPriority: number;
  recommend: boolean;
  recommendPriority: number;
  relatedKeyword: string[];
  relativeThemes: IRelativeThemes[];
  requireVersion: number;
  reviewNoticeContent: string;
  reviewNoticeTitle: string;
  sale: number;
  testImageUrls: string[];
  testcase: string[];
  themeId: string;
  updatedAt: string;
  usedPaymentBuffer: number;
  //   versionBranches: [];
  __v: number;
  _id: string;
}

export interface IFigure {
  keyword: string;
  text: string;
  imageUrl: string;
}

export interface IImages {
  imageUrl: string;
  event: string;
}

export interface IRelativeThemes {
  category: string[];
  createdAt: string;
  downloads: number;
  hashtag: string[];
  imageUrl: string;
  isLiveTheme: boolean;
  maximumIOSVersion: number;
  maximumVersion: number;
  minimulIOSVersion: number;
  minimulVersion: number;
  name: string;
  paymentType: string;
  price: number;
  priority: number;
  recommendPriority: number;
  requireVersion: number;
  sale: number;
  testcase: string[];
  themeId: string;
  updatedAt: string;
  versionBranches: string[];
  _id: string;
}

export interface IKeywordList {
  ko: IKeyword;
  ko_kr: IKeyword;
  en: IKeyword;
  en_us: IKeyword;
}

export interface IKeyword {
  '아침 🌞': string[];
  '안녕 👋': string[];
  '잘가 🙇 ♀️': string[];
  '잘자 🌙': string[];
  '긍정 ⭕️': string[];
  '부정 ❌': string[];
  '간절한 🥺': string[];
  '감동적인 🤧': string[];
  '걱정스러운 😞': string[];
  '고마운 🙏': string[];
  '공포스러운 😱': string[];
  '약올리는 😛': string[];
  '궁금한 ❓': string[];
  '기쁜 😝': string[];
  긴장: string[];
  '깨달음 😮': string[];
  '너무해 ☹️': string[];
  '놀라는 🤭': string[];
  '당황한 😳': string[];
  '동의, 합격 🙆 ♀️': string[];
  '똑똑한 🤓': string[];
  '멋진 😲': string[];
  '모호한 🙄': string[];
  '몰라 🤪': string[];
  '무덤덤한 😐': string[];
  '무시하는 😒': string[];
  '미소 ☺️': string[];
  '미안한 😓': string[];
  '미운 😠': string[];
  '반대 🙅': string[];
  '사랑스러운 👩 ❤️ 💋 👨': string[];
  '생각하는 🤔': string[];
  '서두르는, 빨리, 도망 🏃': string[];
  '수줍은, 부끄러운 🥴': string[];
  '슬픈 😔': string[];
  '신나 💃': string[];
  '실망스러운 😨': string[];
  '심심한 🥱': string[];
  '안심한 😌': string[];
  '어지러운 😵': string[];
  '예쁜 💅': string[];
  '외로운 🙎 ♀️': string[];
  '우울한 😢': string[];
  '우정 👭': string[];
  '뿌듯 🤷': string[];
  '웃긴 😄': string[];
  '잘생긴 😎': string[];
  '좋은 😍': string[];
  '지루한 😐': string[];
  '짜증 😬': string[];
  '최고 👍': string[];
  '축하 🥳': string[];
  '편안한 🛌': string[];
  '하트뿅 🥰': string[];
  '화난 😡': string[];
  '황당한 😰': string[];
  '흥미로운 🤩': string[];
  '희망찬 🤗': string[];
  '봄 🌸': string[];
  '여름 🏝': string[];
  '가을 🍁': string[];
  '겨울 ❄️': string[];
  '기차 🚃': string[];
  '배 🛳': string[];
  '비행기 ✈️': string[];
  '오토바이 🏍': string[];
  '자동차 🚗': string[];
  '발렌타인데이 🍫': string[];
  '부처님오신날 ☸️': string[];
  '생일 🎂': string[];
  '설날, 새해 🎆': string[];
  '어린이날 👶': string[];
  '어버이날 👨 👩 👦': string[];
  '졸업 🎓': string[];
  '추석 🏡': string[];
  '크리스마스 🎄': string[];
  '파티 🎉': string[];
  '한글날 🇰🇷': string[];
  '할로윈 🎃': string[];
  '화이트데이 🍭': string[];
  '눈 🌨': string[];
  '맑음 ☀️': string[];
  '비 🌧': string[];
  '일몰 🌇': string[];
  '일출 🌄': string[];
  '천둥번개 ⚡️': string[];
  '태풍 🌪': string[];
  '폭염 🔥': string[];
  '황사, 미세먼지 😷': string[];
  '흐림 ☁️': string[];
  '가난한 💸': string[];
  '고요한 🔇': string[];
  '기대 💗': string[];
  '더움 🥵': string[];
  '도움 🛠': string[];
  '따뜻 ♨️': string[];
  '못생긴 👽': string[];
  '바쁨 ⏩': string[];
  '배고파 🤤': string[];
  '식사 중 🍚': string[];
  '당 충전 🍰': string[];
  '배불러 🍽': string[];
  '부유한 💵': string[];
  '시원 🌬': string[];
  '아픈 🤕': string[];
  '약속 🤙': string[];
  '연인 👩 ❤️ 💋 👨': string[];
  '음료 🧃': string[];
  '일 💼': string[];
  '재미없는 😶': string[];
  '졸린 💤': string[];
  '집 🏠': string[];
  '청소 🧹': string[];
  '추움 ⛄️': string[];
  '취함 🌀': string[];
  '피곤 🧟 ♂️': string[];
  '행운 🍀': string[];
  '힘든 😵': string[];
  휴식: string[];
  '가다 🚶 ♂️': string[];
  '게임 🎮': string[];
  '경고 ⚠️': string[];
  '공부 📝': string[];
  기다림: string[];
  '놀이 🤡': string[];
  '대화 💬': string[];
  '댄스 👯 ♂️': string[];
  '떼 👿': string[];
  '목욕 🛁': string[];
  '박수 👏': string[];
  '빼꼼 👀': string[];
  '뽀뽀 💋': string[];
  '사진, 셀카 📸': string[];
  '산책 🚶 ♂️': string[];
  '샤워 🚿': string[];
  '쇼핑, 소비 🛍': string[];
  '쉿 🤫': string[];
  '애교 💕': string[];
  '여행 🗺': string[];
  '연락 📞': string[];
  '우쭈쭈 🤗': string[];
  '운동 💪': string[];
  위로: string[];
  '응원 👊': string[];
  '싸움 🗡': string[];
  '칭찬 🥕': string[];
  '화장 💄': string[];
  '화장실 🚻': string[];
}
