import styled from 'styled-components/macro';
import mixin from 'styles/mixin';
import { theme } from 'styles/theme';

interface IEventParticipationGuideProps {
  brandImageUrl: string;
  brandDescription: string;
}

export default function EventParticipationGuide({ brandImageUrl, brandDescription }: IEventParticipationGuideProps) {
  return (
    <EventParticipationContainer>
      <EventParticipationTitle>참여방법</EventParticipationTitle>
      <EventParticipationImage src={brandImageUrl} alt="" />
      <EventParticipationText>{brandDescription}</EventParticipationText>
    </EventParticipationContainer>
  );
}

const EventParticipationContainer = styled.div`
  margin-top: 40px;
`;
const EventParticipationImage = styled.img`
  max-width: 600px;
`;
const EventParticipationText = styled.div`
  white-space: pre-wrap;
  color: ${theme.color.allBodyGray};
`;

const EventParticipationTitle = styled.div`
  ${mixin.h3}
`;
