import { useState, useRef } from 'react';

interface IUseSwipeDownParams {
  swipingLimit: number;
}
// 특정 요소를 위아래로 swipe하기 위한 hook
export const useSwipe = ({ swipingLimit }: IUseSwipeDownParams) => {
  const swipedRef = useRef<HTMLDivElement>(null);
  const [isSwiping, setIsSwiping] = useState<boolean>(false);
  const [startY, setStartY] = useState<number>(0);
  const [swipeDownPercent, setSwipeDownPercent] = useState<number>(100);

  const handleTouchDown = (e: React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsSwiping(true);
    if (swipedRef.current) {
      // 터치 이벤트 발생하는 clientY를 startY로 설정
      setStartY(e.touches[0].clientY);
    }
  };

  const handleShowedUp = () => {
    setSwipeDownPercent(0);
  };

  const handleSwipeDownToZero = () => {
    setSwipeDownPercent(100);
    setIsSwiping(false);
  };

  const handleTouchLeave = () => {
    // 터치 이벤트 끝날 때 스와이핑 퍼센티지를 0으로 만듦
    if (isSwiping) {
      setSwipeDownPercent(0);
    }
    setIsSwiping(false);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (isSwiping && swipedRef.current) {
      // 스와이핑된 정도를 변수와 swipeDownPercent 상태에 할당
      // 스와이핑된 정도가 음수이면 시트가 분리되어 위로 올라가므로 방지
      if (e.touches[0].clientY - startY < 0) return;
      const swipingDegree = ((e.touches[0].clientY - startY) / swipedRef.current.clientHeight) * 100;

      setSwipeDownPercent(swipingDegree);
      // 스와이핑된 정도가 설정한 리밋을 초과하면
      if (swipingDegree > swipingLimit) {
        // 인자로 받은 이벤트 실행
        handleSwipeDownToZero();
      }
    }
  };

  return {
    handleShowedUp,
    handleTouchDown,
    handleSwipeDownToZero,
    handleTouchLeave,
    handleTouchMove,
    swipedRef,
    swipeDownPercent,
  };
};
