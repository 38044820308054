import { IKeywordList } from 'scheme/theme/themeInfo';
import { GetKeywordListActionsType } from 'modules/actions/theme/keywordList';
import { GET_KEYWORD_LIST_START, GET_KEYWORD_LIST_SUCCESS, GET_KEYWORD_LIST_FAIL } from 'modules/types';
import { AxiosError } from 'axios';

export type KeywordListState = {
  loading: boolean;
  keywordList: IKeywordList;
  error: AxiosError | null;
};

export default function keywordListReducer(
  state: KeywordListState = initialState,
  action: GetKeywordListActionsType,
): KeywordListState {
  switch (action.type) {
    case GET_KEYWORD_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case GET_KEYWORD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        keywordList: action.payload,
      };
    case GET_KEYWORD_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

const initialState: KeywordListState = {
  loading: false,
  error: null,
  keywordList: {
    ko: {
      '아침 🌞': [],
      '안녕 👋': [],
      '잘가 🙇 ♀️': [],
      '잘자 🌙': [],
      '긍정 ⭕️': [],
      '부정 ❌': [],
      '간절한 🥺': [],
      '감동적인 🤧': [],
      '걱정스러운 😞': [],
      '고마운 🙏': [],
      '공포스러운 😱': [],
      '약올리는 😛': [],
      '궁금한 ❓': [],
      '기쁜 😝': [],
      긴장: [],
      '깨달음 😮': [],
      '너무해 ☹️': [],
      '놀라는 🤭': [],
      '당황한 😳': [],
      '동의, 합격 🙆 ♀️': [],
      '똑똑한 🤓': [],
      '멋진 😲': [],
      '모호한 🙄': [],
      '몰라 🤪': [],
      '무덤덤한 😐': [],
      '무시하는 😒': [],
      '미소 ☺️': [],
      '미안한 😓': [],
      '미운 😠': [],
      '반대 🙅': [],
      '사랑스러운 👩 ❤️ 💋 👨': [],
      '생각하는 🤔': [],
      '서두르는, 빨리, 도망 🏃': [],
      '수줍은, 부끄러운 🥴': [],
      '슬픈 😔': [],
      '신나 💃': [],
      '실망스러운 😨': [],
      '심심한 🥱': [],
      '안심한 😌': [],
      '어지러운 😵': [],
      '예쁜 💅': [],
      '외로운 🙎 ♀️': [],
      '우울한 😢': [],
      '우정 👭': [],
      '뿌듯 🤷': [],
      '웃긴 😄': [],
      '잘생긴 😎': [],
      '좋은 😍': [],
      '지루한 😐': [],
      '짜증 😬': [],
      '최고 👍': [],
      '축하 🥳': [],
      '편안한 🛌': [],
      '하트뿅 🥰': [],
      '화난 😡': [],
      '황당한 😰': [],
      '흥미로운 🤩': [],
      '희망찬 🤗': [],
      '봄 🌸': [],
      '여름 🏝': [],
      '가을 🍁': [],
      '겨울 ❄️': [],
      '기차 🚃': [],
      '배 🛳': [],
      '비행기 ✈️': [],
      '오토바이 🏍': [],
      '자동차 🚗': [],
      '발렌타인데이 🍫': [],
      '부처님오신날 ☸️': [],
      '생일 🎂': [],
      '설날, 새해 🎆': [],
      '어린이날 👶': [],
      '어버이날 👨 👩 👦': [],
      '졸업 🎓': [],
      '추석 🏡': [],
      '크리스마스 🎄': [],
      '파티 🎉': [],
      '한글날 🇰🇷': [],
      '할로윈 🎃': [],
      '화이트데이 🍭': [],
      '눈 🌨': [],
      '맑음 ☀️': [],
      '비 🌧': [],
      '일몰 🌇': [],
      '일출 🌄': [],
      '천둥번개 ⚡️': [],
      '태풍 🌪': [],
      '폭염 🔥': [],
      '황사, 미세먼지 😷': [],
      '흐림 ☁️': [],
      '가난한 💸': [],
      '고요한 🔇': [],
      '기대 💗': [],
      '더움 🥵': [],
      '도움 🛠': [],
      '따뜻 ♨️': [],
      '못생긴 👽': [],
      '바쁨 ⏩': [],
      '배고파 🤤': [],
      '식사 중 🍚': [],
      '당 충전 🍰': [],
      '배불러 🍽': [],
      '부유한 💵': [],
      '시원 🌬': [],
      '아픈 🤕': [],
      '약속 🤙': [],
      '연인 👩 ❤️ 💋 👨': [],
      '음료 🧃': [],
      '일 💼': [],
      '재미없는 😶': [],
      '졸린 💤': [],
      '집 🏠': [],
      '청소 🧹': [],
      '추움 ⛄️': [],
      '취함 🌀': [],
      '피곤 🧟 ♂️': [],
      '행운 🍀': [],
      '힘든 😵': [],
      휴식: [],
      '가다 🚶 ♂️': [],
      '게임 🎮': [],
      '경고 ⚠️': [],
      '공부 📝': [],
      기다림: [],
      '놀이 🤡': [],
      '대화 💬': [],
      '댄스 👯 ♂️': [],
      '떼 👿': [],
      '목욕 🛁': [],
      '박수 👏': [],
      '빼꼼 👀': [],
      '뽀뽀 💋': [],
      '사진, 셀카 📸': [],
      '산책 🚶 ♂️': [],
      '샤워 🚿': [],
      '쇼핑, 소비 🛍': [],
      '쉿 🤫': [],
      '애교 💕': [],
      '여행 🗺': [],
      '연락 📞': [],
      '우쭈쭈 🤗': [],
      '운동 💪': [],
      위로: [],
      '응원 👊': [],
      '싸움 🗡': [],
      '칭찬 🥕': [],
      '화장 💄': [],
      '화장실 🚻': [],
    },
    ko_kr: {
      '아침 🌞': [],
      '안녕 👋': [],
      '잘가 🙇 ♀️': [],
      '잘자 🌙': [],
      '긍정 ⭕️': [],
      '부정 ❌': [],
      '간절한 🥺': [],
      '감동적인 🤧': [],
      '걱정스러운 😞': [],
      '고마운 🙏': [],
      '공포스러운 😱': [],
      '약올리는 😛': [],
      '궁금한 ❓': [],
      '기쁜 😝': [],
      긴장: [],
      '깨달음 😮': [],
      '너무해 ☹️': [],
      '놀라는 🤭': [],
      '당황한 😳': [],
      '동의, 합격 🙆 ♀️': [],
      '똑똑한 🤓': [],
      '멋진 😲': [],
      '모호한 🙄': [],
      '몰라 🤪': [],
      '무덤덤한 😐': [],
      '무시하는 😒': [],
      '미소 ☺️': [],
      '미안한 😓': [],
      '미운 😠': [],
      '반대 🙅': [],
      '사랑스러운 👩 ❤️ 💋 👨': [],
      '생각하는 🤔': [],
      '서두르는, 빨리, 도망 🏃': [],
      '수줍은, 부끄러운 🥴': [],
      '슬픈 😔': [],
      '신나 💃': [],
      '실망스러운 😨': [],
      '심심한 🥱': [],
      '안심한 😌': [],
      '어지러운 😵': [],
      '예쁜 💅': [],
      '외로운 🙎 ♀️': [],
      '우울한 😢': [],
      '우정 👭': [],
      '뿌듯 🤷': [],
      '웃긴 😄': [],
      '잘생긴 😎': [],
      '좋은 😍': [],
      '지루한 😐': [],
      '짜증 😬': [],
      '최고 👍': [],
      '축하 🥳': [],
      '편안한 🛌': [],
      '하트뿅 🥰': [],
      '화난 😡': [],
      '황당한 😰': [],
      '흥미로운 🤩': [],
      '희망찬 🤗': [],
      '봄 🌸': [],
      '여름 🏝': [],
      '가을 🍁': [],
      '겨울 ❄️': [],
      '기차 🚃': [],
      '배 🛳': [],
      '비행기 ✈️': [],
      '오토바이 🏍': [],
      '자동차 🚗': [],
      '발렌타인데이 🍫': [],
      '부처님오신날 ☸️': [],
      '생일 🎂': [],
      '설날, 새해 🎆': [],
      '어린이날 👶': [],
      '어버이날 👨 👩 👦': [],
      '졸업 🎓': [],
      '추석 🏡': [],
      '크리스마스 🎄': [],
      '파티 🎉': [],
      '한글날 🇰🇷': [],
      '할로윈 🎃': [],
      '화이트데이 🍭': [],
      '눈 🌨': [],
      '맑음 ☀️': [],
      '비 🌧': [],
      '일몰 🌇': [],
      '일출 🌄': [],
      '천둥번개 ⚡️': [],
      '태풍 🌪': [],
      '폭염 🔥': [],
      '황사, 미세먼지 😷': [],
      '흐림 ☁️': [],
      '가난한 💸': [],
      '고요한 🔇': [],
      '기대 💗': [],
      '더움 🥵': [],
      '도움 🛠': [],
      '따뜻 ♨️': [],
      '못생긴 👽': [],
      '바쁨 ⏩': [],
      '배고파 🤤': [],
      '식사 중 🍚': [],
      '당 충전 🍰': [],
      '배불러 🍽': [],
      '부유한 💵': [],
      '시원 🌬': [],
      '아픈 🤕': [],
      '약속 🤙': [],
      '연인 👩 ❤️ 💋 👨': [],
      '음료 🧃': [],
      '일 💼': [],
      '재미없는 😶': [],
      '졸린 💤': [],
      '집 🏠': [],
      '청소 🧹': [],
      '추움 ⛄️': [],
      '취함 🌀': [],
      '피곤 🧟 ♂️': [],
      '행운 🍀': [],
      '힘든 😵': [],
      휴식: [],
      '가다 🚶 ♂️': [],
      '게임 🎮': [],
      '경고 ⚠️': [],
      '공부 📝': [],
      기다림: [],
      '놀이 🤡': [],
      '대화 💬': [],
      '댄스 👯 ♂️': [],
      '떼 👿': [],
      '목욕 🛁': [],
      '박수 👏': [],
      '빼꼼 👀': [],
      '뽀뽀 💋': [],
      '사진, 셀카 📸': [],
      '산책 🚶 ♂️': [],
      '샤워 🚿': [],
      '쇼핑, 소비 🛍': [],
      '쉿 🤫': [],
      '애교 💕': [],
      '여행 🗺': [],
      '연락 📞': [],
      '우쭈쭈 🤗': [],
      '운동 💪': [],
      위로: [],
      '응원 👊': [],
      '싸움 🗡': [],
      '칭찬 🥕': [],
      '화장 💄': [],
      '화장실 🚻': [],
    },
    en: {
      '아침 🌞': [],
      '안녕 👋': [],
      '잘가 🙇 ♀️': [],
      '잘자 🌙': [],
      '긍정 ⭕️': [],
      '부정 ❌': [],
      '간절한 🥺': [],
      '감동적인 🤧': [],
      '걱정스러운 😞': [],
      '고마운 🙏': [],
      '공포스러운 😱': [],
      '약올리는 😛': [],
      '궁금한 ❓': [],
      '기쁜 😝': [],
      긴장: [],
      '깨달음 😮': [],
      '너무해 ☹️': [],
      '놀라는 🤭': [],
      '당황한 😳': [],
      '동의, 합격 🙆 ♀️': [],
      '똑똑한 🤓': [],
      '멋진 😲': [],
      '모호한 🙄': [],
      '몰라 🤪': [],
      '무덤덤한 😐': [],
      '무시하는 😒': [],
      '미소 ☺️': [],
      '미안한 😓': [],
      '미운 😠': [],
      '반대 🙅': [],
      '사랑스러운 👩 ❤️ 💋 👨': [],
      '생각하는 🤔': [],
      '서두르는, 빨리, 도망 🏃': [],
      '수줍은, 부끄러운 🥴': [],
      '슬픈 😔': [],
      '신나 💃': [],
      '실망스러운 😨': [],
      '심심한 🥱': [],
      '안심한 😌': [],
      '어지러운 😵': [],
      '예쁜 💅': [],
      '외로운 🙎 ♀️': [],
      '우울한 😢': [],
      '우정 👭': [],
      '뿌듯 🤷': [],
      '웃긴 😄': [],
      '잘생긴 😎': [],
      '좋은 😍': [],
      '지루한 😐': [],
      '짜증 😬': [],
      '최고 👍': [],
      '축하 🥳': [],
      '편안한 🛌': [],
      '하트뿅 🥰': [],
      '화난 😡': [],
      '황당한 😰': [],
      '흥미로운 🤩': [],
      '희망찬 🤗': [],
      '봄 🌸': [],
      '여름 🏝': [],
      '가을 🍁': [],
      '겨울 ❄️': [],
      '기차 🚃': [],
      '배 🛳': [],
      '비행기 ✈️': [],
      '오토바이 🏍': [],
      '자동차 🚗': [],
      '발렌타인데이 🍫': [],
      '부처님오신날 ☸️': [],
      '생일 🎂': [],
      '설날, 새해 🎆': [],
      '어린이날 👶': [],
      '어버이날 👨 👩 👦': [],
      '졸업 🎓': [],
      '추석 🏡': [],
      '크리스마스 🎄': [],
      '파티 🎉': [],
      '한글날 🇰🇷': [],
      '할로윈 🎃': [],
      '화이트데이 🍭': [],
      '눈 🌨': [],
      '맑음 ☀️': [],
      '비 🌧': [],
      '일몰 🌇': [],
      '일출 🌄': [],
      '천둥번개 ⚡️': [],
      '태풍 🌪': [],
      '폭염 🔥': [],
      '황사, 미세먼지 😷': [],
      '흐림 ☁️': [],
      '가난한 💸': [],
      '고요한 🔇': [],
      '기대 💗': [],
      '더움 🥵': [],
      '도움 🛠': [],
      '따뜻 ♨️': [],
      '못생긴 👽': [],
      '바쁨 ⏩': [],
      '배고파 🤤': [],
      '식사 중 🍚': [],
      '당 충전 🍰': [],
      '배불러 🍽': [],
      '부유한 💵': [],
      '시원 🌬': [],
      '아픈 🤕': [],
      '약속 🤙': [],
      '연인 👩 ❤️ 💋 👨': [],
      '음료 🧃': [],
      '일 💼': [],
      '재미없는 😶': [],
      '졸린 💤': [],
      '집 🏠': [],
      '청소 🧹': [],
      '추움 ⛄️': [],
      '취함 🌀': [],
      '피곤 🧟 ♂️': [],
      '행운 🍀': [],
      '힘든 😵': [],
      휴식: [],
      '가다 🚶 ♂️': [],
      '게임 🎮': [],
      '경고 ⚠️': [],
      '공부 📝': [],
      기다림: [],
      '놀이 🤡': [],
      '대화 💬': [],
      '댄스 👯 ♂️': [],
      '떼 👿': [],
      '목욕 🛁': [],
      '박수 👏': [],
      '빼꼼 👀': [],
      '뽀뽀 💋': [],
      '사진, 셀카 📸': [],
      '산책 🚶 ♂️': [],
      '샤워 🚿': [],
      '쇼핑, 소비 🛍': [],
      '쉿 🤫': [],
      '애교 💕': [],
      '여행 🗺': [],
      '연락 📞': [],
      '우쭈쭈 🤗': [],
      '운동 💪': [],
      위로: [],
      '응원 👊': [],
      '싸움 🗡': [],
      '칭찬 🥕': [],
      '화장 💄': [],
      '화장실 🚻': [],
    },
    en_us: {
      '아침 🌞': [],
      '안녕 👋': [],
      '잘가 🙇 ♀️': [],
      '잘자 🌙': [],
      '긍정 ⭕️': [],
      '부정 ❌': [],
      '간절한 🥺': [],
      '감동적인 🤧': [],
      '걱정스러운 😞': [],
      '고마운 🙏': [],
      '공포스러운 😱': [],
      '약올리는 😛': [],
      '궁금한 ❓': [],
      '기쁜 😝': [],
      긴장: [],
      '깨달음 😮': [],
      '너무해 ☹️': [],
      '놀라는 🤭': [],
      '당황한 😳': [],
      '동의, 합격 🙆 ♀️': [],
      '똑똑한 🤓': [],
      '멋진 😲': [],
      '모호한 🙄': [],
      '몰라 🤪': [],
      '무덤덤한 😐': [],
      '무시하는 😒': [],
      '미소 ☺️': [],
      '미안한 😓': [],
      '미운 😠': [],
      '반대 🙅': [],
      '사랑스러운 👩 ❤️ 💋 👨': [],
      '생각하는 🤔': [],
      '서두르는, 빨리, 도망 🏃': [],
      '수줍은, 부끄러운 🥴': [],
      '슬픈 😔': [],
      '신나 💃': [],
      '실망스러운 😨': [],
      '심심한 🥱': [],
      '안심한 😌': [],
      '어지러운 😵': [],
      '예쁜 💅': [],
      '외로운 🙎 ♀️': [],
      '우울한 😢': [],
      '우정 👭': [],
      '뿌듯 🤷': [],
      '웃긴 😄': [],
      '잘생긴 😎': [],
      '좋은 😍': [],
      '지루한 😐': [],
      '짜증 😬': [],
      '최고 👍': [],
      '축하 🥳': [],
      '편안한 🛌': [],
      '하트뿅 🥰': [],
      '화난 😡': [],
      '황당한 😰': [],
      '흥미로운 🤩': [],
      '희망찬 🤗': [],
      '봄 🌸': [],
      '여름 🏝': [],
      '가을 🍁': [],
      '겨울 ❄️': [],
      '기차 🚃': [],
      '배 🛳': [],
      '비행기 ✈️': [],
      '오토바이 🏍': [],
      '자동차 🚗': [],
      '발렌타인데이 🍫': [],
      '부처님오신날 ☸️': [],
      '생일 🎂': [],
      '설날, 새해 🎆': [],
      '어린이날 👶': [],
      '어버이날 👨 👩 👦': [],
      '졸업 🎓': [],
      '추석 🏡': [],
      '크리스마스 🎄': [],
      '파티 🎉': [],
      '한글날 🇰🇷': [],
      '할로윈 🎃': [],
      '화이트데이 🍭': [],
      '눈 🌨': [],
      '맑음 ☀️': [],
      '비 🌧': [],
      '일몰 🌇': [],
      '일출 🌄': [],
      '천둥번개 ⚡️': [],
      '태풍 🌪': [],
      '폭염 🔥': [],
      '황사, 미세먼지 😷': [],
      '흐림 ☁️': [],
      '가난한 💸': [],
      '고요한 🔇': [],
      '기대 💗': [],
      '더움 🥵': [],
      '도움 🛠': [],
      '따뜻 ♨️': [],
      '못생긴 👽': [],
      '바쁨 ⏩': [],
      '배고파 🤤': [],
      '식사 중 🍚': [],
      '당 충전 🍰': [],
      '배불러 🍽': [],
      '부유한 💵': [],
      '시원 🌬': [],
      '아픈 🤕': [],
      '약속 🤙': [],
      '연인 👩 ❤️ 💋 👨': [],
      '음료 🧃': [],
      '일 💼': [],
      '재미없는 😶': [],
      '졸린 💤': [],
      '집 🏠': [],
      '청소 🧹': [],
      '추움 ⛄️': [],
      '취함 🌀': [],
      '피곤 🧟 ♂️': [],
      '행운 🍀': [],
      '힘든 😵': [],
      휴식: [],
      '가다 🚶 ♂️': [],
      '게임 🎮': [],
      '경고 ⚠️': [],
      '공부 📝': [],
      기다림: [],
      '놀이 🤡': [],
      '대화 💬': [],
      '댄스 👯 ♂️': [],
      '떼 👿': [],
      '목욕 🛁': [],
      '박수 👏': [],
      '빼꼼 👀': [],
      '뽀뽀 💋': [],
      '사진, 셀카 📸': [],
      '산책 🚶 ♂️': [],
      '샤워 🚿': [],
      '쇼핑, 소비 🛍': [],
      '쉿 🤫': [],
      '애교 💕': [],
      '여행 🗺': [],
      '연락 📞': [],
      '우쭈쭈 🤗': [],
      '운동 💪': [],
      위로: [],
      '응원 👊': [],
      '싸움 🗡': [],
      '칭찬 🥕': [],
      '화장 💄': [],
      '화장실 🚻': [],
    },
  },
};
