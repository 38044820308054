import i18n from 'i18n';
import { parseLanguageType } from './parseLanguageType';
// 지금으로부터 인자로 들어온 시간이 얼마의 차이가 나는지 return하는 함수
export default function timeForNow(value: string) {
  const now = new Date();
  const time = new Date(value);

  // 현재 시간()에서 인자로 받은 시간을 뺀 값(밀리 초)를 1000으로 나누고(초) 60으로 나누어(분) 변수 betweenTime에 할당
  const betweenTime = Math.floor((now.getTime() - time.getTime()) / 1000 / 60);

  // betweenTime이 1보다 작으면 1분 이내이므로 '방금전' 반환

  if (betweenTime < 1) {
    switch (parseLanguageType(i18n.language)) {
      case 'ko':
        return '방금전';
      default:
        return 'now';
    }
  }

  // 1시간 이내이므로 'betweenTime분전' 반환
  if (betweenTime < 60) {
    switch (parseLanguageType(i18n.language)) {
      case 'ko':
        return `${betweenTime}분전`;
      default:
        return `${betweenTime}min`;
    }
  }

  // 60분 이상부터는 시간 단위로 변환해야 하므로 betweenTime을 60으로 나누어 변수 betweenTimeHour에 할당
  const betweenTimeHour = Math.floor(betweenTime / 60);

  // betweenTimeHour이 24시간, 즉 하루 이내이므로 'betweenTime시간전' 반환
  if (betweenTimeHour < 24) {
    switch (parseLanguageType(i18n.language)) {
      case 'ko':
        return `${betweenTimeHour}시간전`;
      default:
        return `${betweenTimeHour}hr`;
    }
  }

  // 24시간 이상부터는 일 단위로 변환해야 하므로 betweenTimeHour를 24로 나누어 변수 betweenTimeDay에 할당
  const betweenTimeDay = Math.floor(betweenTimeHour / 24);

  // betweenTimeDay가 7일, 즉 한주 이내이므로 'betweenTimeDay일전' 반환
  if (betweenTimeDay < 7) {
    switch (parseLanguageType(i18n.language)) {
      case 'ko':
        return `${betweenTimeDay}일전`;
      default:
        return `${betweenTimeDay}day`;
    }
  }

  // 7일 이상부터는 주 단위로 변환해야 하므로 betweenTimeDay 7로 나누어 변수 betweenTimeWeek에 할당
  const betweenTimeWeek = Math.floor(betweenTimeDay / 7);

  // betweenTimeDay가 30일, 즉 한달 이내이므로 'betweenTimeWeek주전' 반환
  if (betweenTimeWeek < 5) {
    switch (parseLanguageType(i18n.language)) {
      case 'ko':
        return `${betweenTimeWeek}주전`;
      default:
        return `${betweenTimeWeek}wk`;
    }
  }

  // 31일 이상부터는 월 단위로 변환해야 하므로 betweenTimeDay를 31로 나누어 변수 betweenTimeMonth에 할당
  const betweenTimeMonth = Math.floor(betweenTimeDay / 31);

  // betweenTimeMonth가 12달, 즉 1년 이내이므로 'betweenTimeMonth달전' 반환
  if (betweenTimeMonth < 12) {
    switch (parseLanguageType(i18n.language)) {
      case 'ko':
        return `${betweenTimeMonth}달전`;
      default:
        return `${betweenTimeMonth}mo`;
    }
  }

  // betweenTimeMonth가 12달을 넘어가면 1년이므로 betweenTimeMonth를 12로 나누어 'betweenTimeMonth / 12년전' 반환
  switch (parseLanguageType(i18n.language)) {
    case 'ko':
      return `${Math.floor(betweenTimeMonth / 12)}yr`;
    default:
      return `${Math.floor(betweenTimeMonth / 12)}년전`;
  }
}
