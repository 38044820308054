import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from 'locales/english.json';
import translationKo from 'locales/korean.json';

const resource = {
  en: {
    translation: translationEn,
  },
  ko: {
    translation: translationKo,
  },
};

use(initReactI18next).init({
  resources: resource,
  lng: navigator.language,
  fallbackLng: 'en',
  debug: true,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
