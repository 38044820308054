import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'modules/reducers';
import Layout from 'layout/Layout';
import PreviewImage from 'components/ThemeInfoPage/PreviewImage';
import ThemeName from 'components/ThemeInfoPage/ThemeName';
import Hashtags from 'components/ThemeInfoPage/Hashtags';
import { useState, useEffect } from 'react';
import { getThemeInfoStart } from 'modules/actions/theme/themeInfo';
import Figures from 'components/ThemeInfoPage/Figure';
import { getReviewListMoreStart, getReviewListStart } from 'modules/actions/theme/reviewList';
import ReviewList from 'components/ThemeInfoPage/ReviewList';
import FixedBottomBar from 'components/ThemeInfoPage/FixedBottomBar';
import EventParticipationGuide from 'components/ThemeInfoPage/EventParticipationGuide';
import FixedTopBar from 'components/ThemeInfoPage/FixedTopBar';
import PageHelmet from 'components/PageHelmet';
import Loading from 'components/Loading';
import { useModal } from 'hooks/useModal';
import PreUseKeyboard from 'components/ThemeInfoPage/PreUseKeyboard';
import { parseLanguageType } from 'utils/parseLanguageType';
import i18n from 'i18n';
import { getPopularThemeInfoStart } from 'modules/actions/main/popularThemeInfo';
import { ISuggestThemeInfo } from 'scheme/main/suggestThemeInfo';
import ErrorModal from 'components/ErrorModal';
import { useTranslation } from 'react-i18next';
import IntroduceVideo from 'components/IntroduceVideo';
import Ad from 'components/ThemeInfoPage/Adsense';
import TrendingThemeRank from 'components/ThemeInfoPage/TrendingThemeRank';
import DownloadCount from 'components/ThemeInfoPage/DownloadCount';
import { parseBannerUrl } from 'utils/parseBannerUrl';
import ImageSlider from 'components/EventInfoPage/ImageSlider';
import { IEventImages } from 'scheme/event/eventInfo';
import { CustomDot } from './EventInfoPage';
import PreUseBubble from 'components/ThemeInfoPage/PreUseBubble';

export default function ThemeInfoPageTest() {
  const dispatch = useDispatch();
  const themeId = useParams<{ themeId?: string }>();
  const [reviewStart, setReviewStart] = useState<number>(0);
  const navigate = useNavigate();
  // rootReducer의 state들 중 특정 reducer에 속하는 특정 state를 가져와서 변수에 할당
  const languageType = useSelector((state: RootState) => state.languageTypeReducer.languageType);
  const themeInfoData = useSelector((state: RootState) => state.themeInfoReducer.themeInfo);
  const themeInfoError = useSelector((state: RootState) => state.themeInfoReducer.error);
  const themeInfoLoading = useSelector((state: RootState) => state.themeInfoReducer.loading);
  const webStoreShareLinkData = useSelector((state: RootState) => state.themeInfoReducer.webStoreShareLink);
  const appStoreShareLinkData = useSelector((state: RootState) => state.themeInfoReducer.appStoreShareLink);
  const reviewListData = useSelector((state: RootState) => state.reviewListReducer.reviewList);
  const reviewListCountData = useSelector((state: RootState) => state.reviewListReducer.reviewListCount);
  // const fetchedKeywordList = useSelector((state: RootState) => state.keywordListReducer.keywordList);
  const popularThemeInfoData = useSelector((state: RootState) => state.popularThemeInfoReducer.popularThemeInfo);
  const { handleModalOpen, handleModalClose, isModalOpen } = useModal();
  const [isPopular, setIsPopular] = useState<boolean>();
  const { t } = useTranslation();

  useEffect(() => {
    // 언어 설정 쿼리스트링이 없고 브라우저 언어 설정과 languageType이 일치하지 않는 경우 return
    // 언어 설정 쿼리스트링이 있고 쿼리스트링과 languageType이 일치하지 않는 경우 return
    // if (location.search && location.search.split('=')[1] !== languageType) return;
    dispatch(getThemeInfoStart(themeId.themeId, languageType));
    dispatch(getReviewListStart(themeId.themeId, languageType, 0, 20));
    dispatch(getPopularThemeInfoStart(languageType));
    // dispatch(getKeywordListStart());
  }, [navigate, languageType]);
  // reviewStart(리뷰 목록 get api 호출 시 start parameter에 해당하는 수)가 바뀔 때마다 getReviewListMoreStart 액션 dispatch
  useEffect(() => {
    if (reviewStart > 0) {
      dispatch(getReviewListMoreStart(themeId.themeId, languageType, reviewStart, 20));
    }
  }, [reviewStart]);

  const handleCreatorNameClick = (creatorName: string) => {
    // 크리에이터 이름 클릭 시 실행할 함수
    navigate('/search', { state: creatorName });
  };

  // modal open된 상태일 때 뒤로가기 시 뒤로가기 막고 modal close하는 함수
  window.onpopstate = function (e: Event) {
    e.preventDefault();
    if (isModalOpen) {
      handleModalClose();
      navigate(1);
      return;
    }
  };

  useEffect(() => {
    // 해당 테마가 인급테인지 확인 후 isPopular 상태값 업데이트
    if (popularThemeInfoData && themeInfoData)
      setIsPopular(
        popularThemeInfoData.filter((theme: ISuggestThemeInfo) => theme._id === themeInfoData._id).length > 0,
      );
  }, [themeInfoData]);

  const sliderSettings = {
    customPaging: function () {
      return <CustomDot></CustomDot>;
    },
    dots: true,
    dotsClass: 'slick-dots custom-dots',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: true,
    // 자동 스와이프 옵션
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Layout>
      {themeInfoLoading && <Loading color={theme.color.allMainColor} height="10%" width="10%" />}
      <PageHelmet
        title={themeInfoData.name}
        description={themeInfoData.designStoryTitle}
        image={themeInfoData.imageUrl}
        languageType={languageType}
      />
      <ThemeInfoPageContainer>
        {!themeInfoLoading && <FixedTopBar languageType={languageType} />}
        {themeInfoError ? (
          <ErrorModal error={themeInfoError} />
        ) : (
          <>
            {!themeInfoLoading && (
              <>
                {themeInfoData.images.length ? (
                  <ImageSlider
                    images={themeInfoData.images}
                    sliderSettings={sliderSettings}
                    clickable={true}
                    type="themeInfoPage"
                    handleModalOpen={handleModalOpen}
                    isModalOpen={isModalOpen}
                  />
                ) : (
                  <UpperContainer>
                    <PreviewImage
                      imageUrl={themeInfoData.imageUrl}
                      handleModalOpen={handleModalOpen}
                      themeId={themeInfoData.themeId}
                    />
                  </UpperContainer>
                )}
                <UpperContainer>
                  <PreUseBubble content={t('preUseBubble')} />
                  <TrendingThemeRank
                    isPopular={isPopular}
                    popularThemeInfo={popularThemeInfoData}
                    themeInfo={themeInfoData}
                  />
                  <ThemeName themeName={themeInfoData.name} shareLink={webStoreShareLinkData} />
                  <CreatorName onClick={() => handleCreatorNameClick(themeInfoData.creator)}>
                    {themeInfoData.creator}
                  </CreatorName>
                  {(themeInfoData.paymentType === 'brand' || themeInfoData.isShowDownloads) && (
                    <>
                      <DownloadCount downloadCount={themeInfoData.downloads} paymentType={themeInfoData.paymentType} />
                      {themeInfoData.designStoryTitle && (
                        <DesignStoryTitle>{themeInfoData.designStoryTitle}</DesignStoryTitle>
                      )}
                      <DesignStoryContent>{themeInfoData.designStoryContent}</DesignStoryContent>
                      <EventParticipationGuide
                        brandImageUrl={themeInfoData.brandImageUrl}
                        brandDescription={themeInfoData.brandDescription}
                      />
                    </>
                  )}
                  {themeInfoData.paymentType !== 'brand' && <Hashtags hashtags={themeInfoData.hashtag} />}
                </UpperContainer>
              </>
            )}
            {themeInfoData.paymentType !== 'brand' && (
              <Ad
                layoutKey="-fb+5w+4e-db+86"
                client="ca-pub-3725138711460599"
                slot="8852524714"
                currentPath={location.pathname}
              />
            )}
            {themeInfoData.isLiveTheme && <Figures languageType={languageType} figures={themeInfoData.figure} />}
            {!themeInfoLoading && (
              <>
                {themeInfoData.bannerImageUrl && (
                  <a href={parseBannerUrl(themeInfoData.bannerEvent)} target="_blank">
                    <BannerImage src={themeInfoData.bannerImageUrl} />
                  </a>
                )}

                <IntroduceVideo languageType={languageType} isLiveTheme={themeInfoData.isLiveTheme} />
                <ReviewList
                  paymentType={themeInfoData.paymentType}
                  reviewList={reviewListData}
                  reviewListCount={reviewListCountData}
                  setReviewStart={setReviewStart}
                />
                <FixedBottomBar
                  themeId={themeInfoData.themeId}
                  paymentType={themeInfoData.paymentType}
                  price={themeInfoData.price}
                  shareLink={appStoreShareLinkData}
                />
              </>
            )}
          </>
        )}
        {isModalOpen && (
          <PreUseKeyboard
            shareLink={appStoreShareLinkData}
            themeId={themeInfoData.themeId}
            languageType={languageType}
            handleKeyboardModalClose={handleModalClose}
          />
        )}
      </ThemeInfoPageContainer>
    </Layout>
  );
}

const ThemeInfoPageContainer = styled.div`
  padding: ${theme.layout.fixedBottomBarHeight} 0;
`;

const UpperContainer = styled.div`
  padding: 0 16px;
  padding-top: 3px;
`;

const CreatorName = styled.div`
  font-size: 1.4rem;
  line-height: 20px;
  font-weight: 500;
  margin-top: 3px;
  padding: 0 1px;
  color: ${theme.color.allSubDarkGray};
  cursor: pointer;
  display: inline-block;
`;

const DesignStoryTitle = styled.div`
  font-size: 1.4rem;
  margin-top: 4rem;
  font-weight: 700;
  line-height: 24px;
  color: ${theme.color.allTitleGray};
`;

const DesignStoryContent = styled.div`
  font-size: 1.4rem;
  margin-top: 0.8rem;
  font-weight: 400;
  line-height: 24px;
  color: ${theme.color.allBodyGray};
  white-space: pre-wrap;
`;

const BannerImage = styled.img`
  margin-top: 4rem;
  width: 100%;
`;
