import { call, put, takeEvery } from 'redux-saga/effects';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { GET_TODAY_THEME_INFO_START, GET_TODAY_THEME_INFO_SUCCESS, GET_TODAY_THEME_INFO_FAIL } from 'modules/types';
import { LanguageType } from 'scheme/theme/themeInfo';

interface IParams {
  languageType: LanguageType;
  type: string;
}

function fetchTodayThemeInfo(languageType: LanguageType) {
  const todayThemeInfo = axios.get(`/theme/recommend`, {
    headers: { 'content-language': languageType },
  });
  return todayThemeInfo;
}

function* getTodayThemeInfo({ languageType }: IParams) {
  try {
    const todayThemeInfo: AxiosResponse = yield call(fetchTodayThemeInfo, languageType);

    yield put({
      type: GET_TODAY_THEME_INFO_SUCCESS,
      todayThemeInfo: todayThemeInfo.data.data,
    });
  } catch (err) {
    const error = err as unknown as AxiosError;
    yield put({
      type: GET_TODAY_THEME_INFO_FAIL,
      payload: error,
    });
  }
}

export function* todayThemeInfoSaga() {
  yield takeEvery(GET_TODAY_THEME_INFO_START, getTodayThemeInfo);
}

export default todayThemeInfoSaga;
