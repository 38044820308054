import { GET_EVENT_INFO_START, GET_EVENT_INFO_SUCCESS, GET_EVENT_INFO_FAIL } from 'modules/types';
import { AxiosError } from 'axios';
import { IEventImages, IEventInfo } from 'scheme/event/eventInfo';
import { GetEventInfoActionsType } from 'modules/actions/event/eventInfo';

export type EventInfoState = {
  loading: boolean;
  eventInfo: IEventInfo;
  error: AxiosError | null;
  webStoreShareLink: string;
  appStoreShareLink: string;
};

const initialState: EventInfoState = {
  loading: false,
  error: null,
  webStoreShareLink: '',
  appStoreShareLink: '',
  eventInfo: {
    bannerEvent: '',
    bannerImageUrl: '',
    platform: [''],
    lang: '',
    images: [] as IEventImages[],
    name: '',
    title: '',
    content: '',
    shareMessage: '',
    hide: false,
    _id: '',
    reviewNoticeTitle: '',
    reviewNoticeContent: '',
    reviewPlaceholder: '',
    eventId: '',
    createdAt: '',
    updatedAt: '',
    __v: 0,
  },
};

export default function eventInfoReducer(
  state: EventInfoState = initialState,
  action: GetEventInfoActionsType,
): EventInfoState {
  switch (action.type) {
    case GET_EVENT_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case GET_EVENT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        eventInfo: action.eventInfo,
        webStoreShareLink: action.webStoreShareLink,
        appStoreShareLink: action.appStoreShareLink,
      };
    case GET_EVENT_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
