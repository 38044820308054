import { GET_BANNER_INFO_START, GET_BANNER_INFO_SUCCESS, GET_BANNER_INFO_FAIL } from 'modules/types';
import { AxiosError } from 'axios';
import { IBannerInfo } from 'scheme/main/bannerInfo';
import { GetBannerInfoActionsType } from 'modules/actions/main/bannerInfo';

export type BannerInfoState = {
  loading: boolean;
  bannerInfo: IBannerInfo[];
  error: AxiosError | null;
};

const initialState: BannerInfoState = {
  loading: false,
  error: null,
  bannerInfo: [
    {
      description: '',
      event: '',
      hide: false,
      imageUrl: '',
      lang: '',
      maximumIOSVersion: 0,
      maximumVersion: 0,
      minimulIOSVersion: 0,
      minimulVersion: 0,
      platform: [''],
      priority: 0,
      recommendPriority: 0,
      __v: 0,
      _id: '',
    },
  ],
};

export default function bannerInfoReducer(
  state: BannerInfoState = initialState,
  action: GetBannerInfoActionsType,
): BannerInfoState {
  switch (action.type) {
    case GET_BANNER_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case GET_BANNER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        bannerInfo: action.bannerInfo,
      };
    case GET_BANNER_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
